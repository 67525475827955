const EventReservations = () => import('@/views/events/event/reservations/index.vue');

const Invitations = () => import('@/views/events/event/reservations/invitations/index.vue');
const InvitationsOverview = () => import('@/views/events/event/reservations/invitations/InvitationsOverview.vue');
const InvitationDetails = () => import('@/views/events/event/reservations/invitations/InvitationDetails.vue');

const ReservationsOverview = () => import('@/views/events/event/reservations/ReservationsOverview.vue');

const EventReservation = () => import('@/views/events/event/reservations/reservation/index.vue');
const EventReservationTickets = () => import('@/views/events/event/reservations/reservation/tickets/Tickets.vue');
const EventReservationUnclaimedTickets = () =>
  import('@/views/events/event/reservations/reservation/tickets/UnclaimedTickets.vue');
const EventReservationClaimedTickets = () =>
  import('@/views/events/event/reservations/reservation/tickets/ClaimedTickets.vue');

const EventReservationInvitations = () =>
  import('@/views/events/event/reservations/reservation/ReservationInvitations.vue');

const EventReservationDistributionConfig = () =>
  import('@/views/events/event/reservations/reservation/distribution/Distribution.vue');
const EventReservationDistributionSend = () =>
  import('@/views/events/event/reservations/reservation/distribution/Send.vue');
const EventReservationDistribute = () => import('@/views/events/event/reservations/reservation/distribution/index.vue');

function hasReadAccess({ state, getters }, _to) {
  const { current_event } = state.Events;
  return (
    current_event &&
    !current_event.is_template &&
    getters['Events/getPermission']('reservation.read') &&
    getters['Events/getPermission']('feature.flag')
  );
}

function hasWriteAccess({ state, getters }, _to) {
  const { current_event } = state.Events;
  return (
    current_event &&
    !current_event.is_template &&
    getters['Events/getPermission']('reservation.write') &&
    getters['Events/getPermission']('feature.flag')
  );
}

export default {
  path: 'reservations',
  component: EventReservations,
  children: [
    {
      path: '',
      name: 'event-reservations',
      component: ReservationsOverview,
      meta: {
        auth: true,
        org: true,
        permissions: {
          access: hasReadAccess,
          redirect: (_, to) => ({ name: 'notFound', params: { 0: to.fullPath } }),
        },
      },
    },
    {
      path: ':reservationId',
      name: 'event-reservation',
      component: EventReservation,
      redirect: { name: 'event-reservation-tickets' },
      children: [
        {
          path: 'tickets',
          name: 'event-reservation-tickets',
          component: EventReservationTickets,
          redirect: { name: 'event-reservation-tickets-unclaimed' },
          children: [
            {
              path: 'unclaimed',
              name: 'event-reservation-tickets-unclaimed',
              component: EventReservationUnclaimedTickets,
              meta: {
                auth: true,
                org: true,
                permissions: {
                  access: hasReadAccess,
                  redirect: (_, to) => ({ name: 'notFound', params: { 0: to.fullPath } }),
                },
              },
            },
            {
              path: 'claimed',
              name: 'event-reservation-tickets-claimed',
              component: EventReservationClaimedTickets,
              meta: {
                auth: true,
                org: true,
                permissions: {
                  access: hasReadAccess,
                  redirect: (_, to) => ({ name: 'notFound', params: { 0: to.fullPath } }),
                },
              },
            },
          ],
        },
        {
          path: 'invitations',
          name: 'event-reservation-invitations',
          component: EventReservationInvitations,
          meta: {
            auth: true,
            org: true,
            permissions: {
              access: hasReadAccess,
              redirect: (_, to) => ({ name: 'notFound', params: { 0: to.fullPath } }),
            },
          },
        },
        {
          path: 'distribute',
          component: EventReservationDistribute,
          children: [
            {
              path: '',
              name: 'event-reservation-distribution',
              component: EventReservationDistributionConfig,
              meta: {
                auth: true,
                org: true,
                permissions: {
                  access: hasWriteAccess,
                  redirect: (_, to) => ({ name: 'notFound', params: { 0: to.fullPath } }),
                },
              },
            },
            {
              path: 'send',
              name: 'event-reservation-distribution-send',
              component: EventReservationDistributionSend,
              meta: {
                auth: true,
                org: true,
                permissions: {
                  access: hasWriteAccess,
                  redirect: (_, to) => ({ name: 'notFound', params: { 0: to.fullPath } }),
                },
              },
              beforeEnter: (to, from, next) => {
                if (from.name === 'event-reservation-distribution') {
                  next();
                  return;
                }

                next({ name: 'event-reservation-distribution', params: to.params });
              },
            },
          ],
        },
      ],
    },
    {
      path: 'invitations',
      component: Invitations,
      meta: {
        auth: true,
        org: true,
        permissions: {
          access: hasReadAccess,
          redirect: (_, to) => ({ name: 'notFound', params: { 0: to.fullPath } }),
        },
      },
      children: [
        {
          path: '',
          name: 'event-reservations-invitations',
          component: InvitationsOverview,
          meta: {
            auth: true,
            org: true,
            permissions: {
              access: hasReadAccess,
              redirect: (_, to) => ({ name: 'notFound', params: { 0: to.fullPath } }),
            },
          },
        },
        {
          path: ':invitationUUID',
          name: 'event-invitation-details',
          component: InvitationDetails,
          auth: true,
          org: true,
          permissions: {
            access: hasReadAccess,
            redirect: (_, to) => ({ name: 'notFound', params: { 0: to.fullPath } }),
          },
        },
      ],
    },
  ],
};
