export const MAX_SIZE_CSV = 6000000;
export const pageLimit = 30;
export const RESIZE_PREFIX = '/_/';
export const EVENT_IMAGE_SIZE = {
  WIDTH: 640,
  HEIGHT: 480,
};
export const IMAGE_SIZES = [1, 42, 320, 640, 800, 1280, 1920];
export const REPORTS_MAX_EVENT_COUNT = 30;
export const IMMEDIATE_DATE = '2000-01-01T00:00:00Z';
export const NEVER_DATE = '2200-01-01T00:00:00Z';
export const MODAL_IDS = {
  CHECKIN_PRIVS: 'checkin-privs-modal',
  REMOVE_CAT_PRIVS: 'remove-cat-privs-modal',
  MOVE_PRIVS: 'move-privs-modal',
  MANAGE_BLOCK_CAT: 'block-category-modal',
  EDIT_TICKET_NOTE: 'edit-ticket-name-modal',
  ADD_PRIVS: 'add-privs-modal',
  INVALIDATE_TICKETS: 'invalidate-tickets',
  TICKET_PRICE_BUILDUP: 'ticket-price-buildup-modal',
  EVENT_CREATED: 'event-created',
  EVENT_PUBLISH: 'event-publish',
  TICKET_SHARE: 'ticket-share',
  POOL_MODAL: 'pool-modal',
  TICKET_CREATION: 'ticket-creation',
  DELETE_POOL: 'delete-pool',
  DELETE_SHOP: 'delete-shop',
  SHOP_ACCESS_MODAL: 'shop-access-modal',
  SHOP_CREATE_MODAL: 'shop-create-modal',
  SHOP_EDIT_MODAL: 'shop-edit-modal',
  TICKET_EDIT: 'ticket-edit',
  TICKET_DETAILS: 'ticket-details',
  TICKET_GROUP: 'ticket-group',
  EMAIL: 'email',
  SCANNING_PROFILE: 'scanning-profile',
  PAGES: 'pages',
  DELETE_PAGE: 'delete-page',
  EDIT_ATTENDEE: 'edit-attendee',
  CHECKIN: 'checkin',
  PRICE_MODAL: 'price-modal',
  CREATE_FROM_TEMPLATE: 'create-from-template-modal',
  SYNC_TEMPLATE: 'sync-template',
  UNLINK_EVENT: 'unlink-event',
  SCHEDULE_MODAL: 'schedule-modal',
  SCHEDULE_PATTERN_MODAL: 'schedule-pattern-modal',
  SCHEDULE_SEED_MODAL: 'seed-modal',
  COPY_EVENT: 'copy-event',
  GATE_MODAL: 'gate-modal',
  GATE_LINK_MODAL: 'gate-link-modal',
  DELETE_GATE_MODAL: 'delete-gate-modal',
  EVENT_PREVIEW_MODAL: 'event-preview-modal',
  ASSIGN_ATTENDEE: 'assign-attendee',
  FINALIZE_SEATING: 'finalize-seating',
  SOLVE_SEATING: 'solving-seating',
  EVENT_SELECT: 'event-select',
  ORGA_MODAL: 'orga-modal',
  EVENTS_SELECT: 'events-select',
  USER_MODAL: 'user_modal',
  USER_SWITCH_STATE_MODAL: 'activate_user_modal',
  SAVE_CONTENT: 'save_content',
  ACCOUNT_MODAL: 'account_modal',
  ANONYMIZE_USER_MODAL: 'anonymize_user_modal',
  SEND_PREVIEW_MODAL: 'send_preview_modal',
  SHOW_LANDING_PAGE_PREVIEW_MODAL: 'show_landing_page_preview_modal',
  CASHIER_SELECTED_TICKET_MODAL: 'cashier_selected_ticket_modal',
  REMOVE_TICKETS: 'remove-tickets',
  CASHIER_CONFIRMATION_MODAL: 'cashier_confirmation_modal',
  CONFIRM_TICKETS_REMOVAL: 'confirm-tickets-removal',
  CASHIER_ISSUE_NOW_RESERVATION_MODAL: 'cashier_issue_now_reservation_modal',
  ADD_CART_TO_RESERVATION_CONFIRM_MODAL: 'add_cart_to_reservation_confirm_modal',
  OLD_CASHIER: 'old_cashier',
  EDIT_RESERVATION: 'edit_reservation',
  CANCEL_RESERVATION: 'cancel_reservation',
  CONFIRM_RESERVATION: 'confirm_reservation',
  RELEASE_RESERVATION_TICKETS: 'release_reservation_tickets',
  DISTRIBUTE_RESERVATION_TICKETS: 'distribute_reservation_tickets',
  RESEND_INVITATION: 'resend_invitation',
  REVOKE_INVITATION: 'revoke_invitation',
};
export const TASK_STATES = {
  NOT_STARTED: 'not_started',
  PROCESSING: 'processing',
  COMPLETED: 'completed',
  FAILED: 'failed',
};
export const TASK_TYPES = {
  SHOP_COPY: 'shop_copy',
  COPY_EVENT: 'event_copy',
  TEMPLATE_GENERATE: 'template_generate',
  GROUP_SIZES_REPORT: 'group_sizes_report',
  REVENUE_REPORT: 'revenue_report',
  SUMMARY_REPORT: 'summary_report',
  ORDERS_REPORT: 'orders_report',
  ATTENDEES_REPORT: 'attendees_report',
  BLOCKS_REPORT: 'blocks_report',
  TEMPLATE_COPY: 'template_copy',
};
export const EVENT_TYPES = {
  GENERAL_ADMISSION: 'general-admission',
  SEATED: 'seated',
  LIVESTREAM: 'livestream',
};
export const LIVESTREAM_TYPES = {
  INTERNAL: 'internal',
  EXTERNAL: 'external',
};
export const SHARING_OPTIONS = {
  STRICT: 'strict',
  EASY: 'easy',
  DISABLED: 'not_allowed',
};
export const PUBLISH_STATES = {
  DRAFT: 'draft',
  PUBLISHED: 'published',
  TEMPLATE_NEEDS_SYNCING: 'template_needs_syncing',
  TEMPLATE_SYNCED: 'template_synced',
  TEMPLATE_SYNCING: 'template_being_synced',
};
export const MAX_PRICE = 9999999.99;
export const WEEKDAYS = [0, 1, 2, 3, 4, 5, 6];
export const TOTAL_AMOUNT_MAX_VALUE = 100000;
export const PSP_OPTIONS = {
  DUMMY: 'dummy',
  TICKETEER: 'ticketeer_payments',
  MOLLIE: 'mollie',
  STRIPE: 'stripe',
};
export const AUTH_APP = 'dashboard';
export const MIN_PASSWORD_LENGTH = 9;
export const API_ERRORS = {
  WEAK_PASSWORD: 'weak_password',
  WRONG_OLD_PASSWORD: 'wrong_old_password',
  USER_ALREADY_EXISTS: 'user_already_exists',
  USER_ALREADY_JOINED: 'user_already_joined',
  INVITATION_EXPIRED: 'invitation_expired',
  RESET_LINK_EXPIRED: 'reset_link_expired',
};
export const TICKET_STATES = {
  SOLD: 'sold',
  FORSALE: 'forsale',
  EXECUTED: 'executed',
  PARTIAL_CHECKIN: 'partialCheckedIn',
  BEING_RESOLD: 'being-resold',
  CREATED: 'created',
  RESOLD: 'resold',
  RETURNED: 'returned',
  RELEASED: 'released',
  INVALIDATED: 'invalidated',
  TRANSFERED: 'transfered',
};

export const ORDER_STATES = {
  CREATED: 'created',
  PENDING: 'pending',
  CANCELED: 'canceled',
  TIMEOUT: 'timeout',
  COMPLETED: 'completed',
  FAILED: 'failed',
};
export const RESERVATION_STATES = {
  DISTRIBUTION_STARTED: 'distribution_started',
  AWAITING_CONFIRMATION: 'awaiting_confirmation',
  AWAITING_DISTRIBUTION: 'awaiting_distribution',
  EXPIRED: 'expired',
  CANCELED: 'canceled',
  COMPLETED: 'completed',
};

export const RESERVATION_TICKET_STATES = {
  AWAITING_CONFIRMATION: 'awaiting_confirmation',
  CONFIRMED: 'confirmed',
  INVITED: 'invited',
  TRANSFERED: 'transfered',
  INVALIDATED: 'invalidated',
};

export const RESERVATION_INVITATION_STATES = {
  STATE_INVITED: 'invited',
  STATE_CLAIMED: 'claimed',
  STATE_REVOKED: 'revoked',
};

export const PAYMENT_STATES = {
  PENDING: 'pending',
  PAID: 'paid',
  CANCELED: 'canceled',
  EXPIRED: 'expired',
  FAILED: 'failed',
  DUPLICATED: 'duplicated',
};

export const REFUNDS_STATES = {
  PENDING: 'pending',
  SUCCESS: 'success',
  FAILED: 'failed',
};

export const REFUND = {
  MODE: {
    MANUAL: 'manual',
    AUTO: 'auto',
    NOREFUND: 'noRefund',
    CREDITS: 'credits',
  },
  TYPE: {
    FULL: 'full',
    PARTIAL: 'partial',
  },
};
export const PAYMENT_METHODS = {
  CASH: 'cash',
  PIN: 'pin',
  DIGITAL: 'digital',
  OTHER: 'other',
};
export const PAYMENT_METHODS_MONEY_FREE = {
  FREE: 'free',
  CREDITS: 'credits',
};

export const NFT_TEMPLATES_HREF = '/files/nft-templates.zip';
export const TICKET_ENGINE_VERSION = 2;

export const ORDER_TABLE_FIELDS = {
  ID: 'id',
  OWNER_FULLNAME: 'owner_last_name',
  OWNER_PHONENUMBER: 'owner_phonenumber',
  STATE: 'state',
  MARKET: 'market',
  TYPE: 'order_type',
  AMOUNT: 'ticket_total',
  DATE_CREATED: 'created',
  DATE_MODIFIED: 'modified',
};

export const RESERVATION_TABLE_FIELDS = {
  ID: 'id',
  NAME: 'name',
  CREATED: 'created',
  EXPIRY: 'expiry',
  TOTAL_VALUE: 'total_value',
  CLAIMED: 'claimed',
  ACTIONS: 'actions',
};

export const ORDER_TYPES = {
  NORMAL: 'normal',
  CASHIER: 'cashier',
  RESERVATION: 'reservation',
  CLAIMED: 'claimed',
};

export const EXECUTION_CHART_FILTER_OPTIONS = {
  ALL: 'all',
  ASSIGNED: 'assigned',
  BLOCKED: 'blocked',
};

export const CASHIER_AVAILABILITY_TYPES = {
  AVAILABLE: 'available',
  LOCKED: 'locked',
};

export const CASHIER_SEATING_MODES = {
  AUTO: 'auto',
  MANUAL: 'manual',
  NOT_APPLICABLE: 'not_applicable',
};

export const CASHIER_PRICE_MODES = {
  CUSTOM: 'custom',
  GLOBAL: 'global',
};

export const BATCH_ACTIONS = {
  CHECKIN: 'checkin',
  UNDO_CHECKIN: 'undo-checkin',
  PRINT: 'print',
  EDIT_NOTES: 'edit-notes',
  INVALIDATE: 'invalidate',
  MOVE_TO_CATEGORY: 'move-to-category',
  RELEASE: 'release-from-blocks',
  RESERVATION_RELEASE: 'release-from-reservation',
  RESERVATION_CONFIRM_TICKETS: 'reservation-confirm-tickets',
  RESERVATION_DISTRIBUTE_TICKETS: 'reservation-distribute-tickets',
};

export const BATCH_ACTIONS_CONTEXTS = {
  CUSTOMER_TICKETS: 'customer-tickets',
  PRIVILEGE_TABLE: 'privilege-table',
  BLOCKS_CATEGORY_TABLE: 'blocks-category-table',
  RESERVATION_TICKETS: 'reservation-tickets',
};

export const CART_UPDATE_ACTION = {
  ADD: 'add',
  REMOVE: 'remove',
  EDIT: 'edit',
};

export const ORDER_MARKET_TYPES = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
};

export const SEAT_STATES = {
  AVAILABLE: 'available',
  BLOCKED: 'blocked',
  ASSIGNED: 'assigned',
  IN_CART: 'in_cart',
};
export const PRIVILEGE_TYPES = {
  TICKET: 'ticket',
  UPSELL: 'upsell',
};
export const ABSOLUT_MAX_EMAILS = 500;

export const SHOP_ACCESS_TYPES = {
  OPEN: 'open',
  COLLECTIBLES: 'collectibles',
};

export const DATE_TIME_OPTIONS = {
  IMMEDIATE: 'immediate',
  ABSOLUTE: 'absolute',
  RELATIVE: 'relative',
  CUSTOM: 'custom',
  NEVER: 'never',
};
