{
  "countries": {
    "selectCountry": "Select country",
    "AF": "Afghanistan",
    "AX": "Åland Islands",
    "AL": "Albania",
    "DZ": "Algeria",
    "AS": "American Samoa",
    "AD": "Andorra",
    "AO": "Angola",
    "AI": "Anguilla",
    "AQ": "Antarctica",
    "AG": "Antigua and Barbuda",
    "AR": "Argentina",
    "AM": "Armenia",
    "AW": "Aruba",
    "AU": "Australia",
    "AT": "Austria",
    "AZ": "Azerbaijan",
    "BS": "Bahamas",
    "BH": "Bahrain",
    "BD": "Bangladesh",
    "BB": "Barbados",
    "BY": "Belarus",
    "BE": "Belgium",
    "BZ": "Belize",
    "BJ": "Benin",
    "BM": "Bermuda",
    "BT": "Bhutan",
    "BO": "Bolivia (Plurinational State of)",
    "BQ": "Bonaire, Sint Eustatius and Saba",
    "BA": "Bosnia and Herzegovina",
    "BW": "Botswana",
    "BV": "Bouvet Island",
    "BR": "Brazil",
    "IO": "British Indian Ocean Territory",
    "BN": "Brunei Darussalam",
    "BG": "Bulgaria",
    "BF": "Burkina Faso",
    "BI": "Burundi",
    "CV": "Cabo Verde",
    "KH": "Cambodia",
    "CM": "Cameroon",
    "CA": "Canada",
    "KY": "Cayman Islands",
    "CF": "Central African Republic",
    "TD": "Chad",
    "CL": "Chile",
    "CN": "China",
    "CX": "Christmas Island",
    "CC": "Cocos (Keeling) Islands",
    "CO": "Colombia",
    "KM": "Comoros",
    "CG": "Congo",
    "CD": "Congo (Democratic Republic of the)",
    "CK": "Cook Islands",
    "CR": "Costa Rica",
    "CI": "Côte d'Ivoire",
    "HR": "Croatia",
    "CU": "Cuba",
    "CW": "Curaçao",
    "CY": "Cyprus",
    "CZ": "Czechia",
    "DK": "Denmark",
    "DJ": "Djibouti",
    "DM": "Dominica",
    "DO": "Dominican Republic",
    "EC": "Ecuador",
    "EG": "Egypt",
    "SV": "El Salvador",
    "GQ": "Equatorial Guinea",
    "ER": "Eritrea",
    "EE": "Estonia",
    "ET": "Ethiopia",
    "FK": "Falkland Islands (Malvinas)",
    "FO": "Faroe Islands",
    "FJ": "Fiji",
    "FI": "Finland",
    "FR": "France",
    "GF": "French Guiana",
    "PF": "French Polynesia",
    "TF": "French Southern Territories",
    "GA": "Gabon",
    "GM": "Gambia",
    "GE": "Georgia",
    "DE": "Germany",
    "GH": "Ghana",
    "GI": "Gibraltar",
    "GR": "Greece",
    "GL": "Greenland",
    "GD": "Grenada",
    "GP": "Guadeloupe",
    "GU": "Guam",
    "GT": "Guatemala",
    "GG": "Guernsey",
    "GN": "Guinea",
    "GW": "Guinea-Bissau",
    "GY": "Guyana",
    "HT": "Haiti",
    "HM": "Heard Island and McDonald Islands",
    "VA": "Holy See",
    "HN": "Honduras",
    "HK": "Hong Kong",
    "HU": "Hungary",
    "IS": "Iceland",
    "IN": "India",
    "ID": "Indonesia",
    "IR": "Iran (Islamic Republic of)",
    "IQ": "Iraq",
    "IE": "Ireland",
    "IM": "Isle of Man",
    "IL": "Israel",
    "IT": "Italy",
    "JM": "Jamaica",
    "JP": "Japan",
    "JE": "Jersey",
    "JO": "Jordan",
    "KZ": "Kazakhstan",
    "KE": "Kenya",
    "KI": "Kiribati",
    "KP": "Korea (Democratic People's Republic of)",
    "KR": "Korea (Republic of)",
    "KW": "Kuwait",
    "KG": "Kyrgyzstan",
    "LA": "Lao People's Democratic Republic",
    "LV": "Latvia",
    "LB": "Lebanon",
    "LS": "Lesotho",
    "LR": "Liberia",
    "LY": "Libya",
    "LI": "Liechtenstein",
    "LT": "Lithuania",
    "LU": "Luxembourg",
    "MO": "Macao",
    "MK": "Macedonia (the former Yugoslav Republic of)",
    "MG": "Madagascar",
    "MW": "Malawi",
    "MY": "Malaysia",
    "MV": "Maldives",
    "ML": "Mali",
    "MT": "Malta",
    "MH": "Marshall Islands",
    "MQ": "Martinique",
    "MR": "Mauritania",
    "MU": "Mauritius",
    "YT": "Mayotte",
    "MX": "Mexico",
    "FM": "Micronesia (Federated States of)",
    "MD": "Moldova (Republic of)",
    "MC": "Monaco",
    "MN": "Mongolia",
    "ME": "Montenegro",
    "MS": "Montserrat",
    "MA": "Morocco",
    "MZ": "Mozambique",
    "MM": "Myanmar",
    "NA": "Namibia",
    "NR": "Nauru",
    "NP": "Nepal",
    "NL": "Netherlands",
    "NC": "New Caledonia",
    "NZ": "New Zealand",
    "NI": "Nicaragua",
    "NE": "Niger",
    "NG": "Nigeria",
    "NU": "Niue",
    "NF": "Norfolk Island",
    "MP": "Northern Mariana Islands",
    "NO": "Norway",
    "OM": "Oman",
    "PK": "Pakistan",
    "PW": "Palau",
    "PS": "Palestine, State of",
    "PA": "Panama",
    "PG": "Papua New Guinea",
    "PY": "Paraguay",
    "PE": "Peru",
    "PH": "Philippines",
    "PN": "Pitcairn",
    "PL": "Poland",
    "PT": "Portugal",
    "PR": "Puerto Rico",
    "QA": "Qatar",
    "RE": "Réunion",
    "RO": "Romania",
    "RU": "Russian Federation",
    "RW": "Rwanda",
    "BL": "Saint Barthélemy",
    "SH": "Saint Helena, Ascension and Tristan da Cunha",
    "KN": "Saint Kitts and Nevis",
    "LC": "Saint Lucia",
    "MF": "Saint Martin (French part)",
    "PM": "Saint Pierre and Miquelon",
    "VC": "Saint Vincent and the Grenadines",
    "WS": "Samoa",
    "SM": "San Marino",
    "ST": "Sao Tome and Principe",
    "SA": "Saudi Arabia",
    "SN": "Senegal",
    "RS": "Serbia",
    "SC": "Seychelles",
    "SL": "Sierra Leone",
    "SG": "Singapore",
    "SX": "Sint Maarten (Dutch part)",
    "SK": "Slovakia",
    "SI": "Slovenia",
    "SB": "Solomon Islands",
    "SO": "Somalia",
    "ZA": "South Africa",
    "GS": "South Georgia and the South Sandwich Islands",
    "SS": "South Sudan",
    "ES": "Spain",
    "LK": "Sri Lanka",
    "SD": "Sudan",
    "SR": "Suriname",
    "SJ": "Svalbard and Jan Mayen",
    "SZ": "Swaziland",
    "SE": "Sweden",
    "CH": "Switzerland",
    "SY": "Syrian Arab Republic",
    "TW": "Taiwan, Province of China",
    "TJ": "Tajikistan",
    "TZ": "Tanzania, United Republic of",
    "TH": "Thailand",
    "TL": "Timor-Leste",
    "TG": "Togo",
    "TK": "Tokelau",
    "TO": "Tonga",
    "TT": "Trinidad and Tobago",
    "TN": "Tunisia",
    "TR": "Turkey",
    "TM": "Turkmenistan",
    "TC": "Turks and Caicos Islands",
    "TV": "Tuvalu",
    "UG": "Uganda",
    "UA": "Ukraine",
    "AE": "United Arab Emirates",
    "GB": "United Kingdom of Great Britain and Northern Ireland",
    "US": "United States of America",
    "UM": "United States Minor Outlying Islands",
    "UY": "Uruguay",
    "UZ": "Uzbekistan",
    "VU": "Vanuatu",
    "VE": "Venezuela (Bolivarian Republic of)",
    "VN": "Viet Nam",
    "VG": "Virgin Islands (British)",
    "VI": "Virgin Islands (U.S.)",
    "WF": "Wallis and Futuna",
    "EH": "Western Sahara",
    "YE": "Yemen",
    "ZM": "Zambia",
    "ZW": "Zimbabwe"
  },
  "placeholders": {
    "dateTime": "dd-mm-yyyy hh:mm",
    "date": "dd-mm-yyyy",
    "time": "hh:mm",
    "search": "Search...",
    "searchCountryCode": "Search for country code",
    "loading": "Loading...",
    "email": "example{'@'}mail.org",
    "uploadImagePlaceholder": "Choose image or drop it here.",
    "dropImagePlaceholder": "Drop your image here.",
    "selectOrganisationMessage": "Select an organisation",
    "selectGate": "Search or select gate...",
    "selectRole": "Select role",
    "selectLocale": "Select language",
    "selectTicketeer": "Select ticketeer",
    "phoneNumber": "e.g. +141566573212"
  },
  "intervals": {
    "hour": "Per hour",
    "day": "Per day",
    "week": "Per week",
    "month": "Per month",
    "year": "Per year"
  },
  "permissions": {
    "noPermissions": "You do not have sufficient permissions",
    "contactAdministrator": "Contact your administrator for necessary permissions",
    "insufficientPermissions": "Insufficient permissions"
  },
  "notifications": {
    "saveSuccess": "Successfully saved",
    "savePublishSuccess": "Successfully saved and published",
    "removeSuccess": "Successfully removed",
    "saveFail": "Saving failed",
    "removeFail": "Removing failed",
    "cashierCartExpired": "Your cart has expired due to inactivity. Please reload the page to start over.",
    "cartUpdate": {
      "edit": {
        "success": "The cart has been updated successfully.",
        "fail": "Failed to update the cart."
      },
      "add": {
        "success": "The ticket(s) have been added successfully.",
        "fail": "Failed to add ticket(s)."
      },
      "remove": {
        "success": "The ticket(s) have been removed successfully.",
        "fail": "Failed to remove ticket(s)."
      }
    },
    "genericError": "Something went wrong",
    "successUpdate": "Successfully updated",
    "successEmail": "Email sent",
    "successShared": "Ticket shared",
    "successDownload": "File downloaded",
    "successPassword": "Password is successfully reset",
    "copiedToClipboard": "Copied to your clipboard!",
    "copyFailed": "Failed to copy to your clipboard",
    "invitationSent": "Invitation sent",
    "notEnoughPrivs": "Error: can't block this number of tickets, since there aren't that many available (anymore). You can try increasing the number of tickets.",
    "unSavedAlert": {
      "title": "You have unsaved changes",
      "description": "Are you sure you want to leave without saving your changes?"
    },
    "TicketKindExists": "You cannot remove this pool because it has tickets associated to it",
    "TicketKindExistsInShop": "You cannot remove this shop because it has tickets associated to it",
    "anonymizeUserSuccess": "The account data has been anonymized.",
    "anonymizeUserFail": "An error occurred while removing the account, the user is probably in the process of purchasing a ticket. Please try again later.",
    "feature_flag": "This feature is available for users with 'Tester' permissions only.",
    "tasks": {
      "taskLabel": "Task:",
      "stateLabel": "State:",
      "states": {
        "processing": "Processing",
        "not_started": "Not started",
        "failed": "Failed",
        "completed": "Completed"
      },
      "event_copy": {
        "title": "Copy event",
        "taskDescription": "Copying {title}",
        "link": "Go to the new event"
      },
      "shop_copy": {
        "title": "Copy Shop",
        "taskDescription": "Copying Shop {title}"
      },
      "template_generate": {
        "title": "Generating event",
        "taskDescription": "Generating from template:",
        "link": "Go to the new event"
      },
      "template_copy": {
        "title": "Copy template",
        "taskDescription": "Copying Template {title}"
      }
    }
  },
  "navigation": {
    "home": "Home",
    "events": "Events",
    "customers": "Customers",
    "upcoming": "Upcoming",
    "past": "Past",
    "draft": "Draft",
    "reports": "Reports",
    "checkIns": "Check-ins",
    "sales": "Sales",
    "exports": "Exports",
    "manage": "Manage",
    "blocks": "Blocks",
    "general": "General",
    "eventMarketing": "Marketing",
    "eventAdvanced": "Advanced",
    "eventBlockchain": "Blockchain",
    "resaleFees": "Resale fees",
    "scanning": "Scanning",
    "seating": "Seating",
    "external": "External",
    "demographics": "Demographics",
    "pools": "Pools",
    "shops": "Shops",
    "prices": "Prices",
    "gates": "Gates",
    "templates": "Templates",
    "schedules": "Scheduler",
    "sync": "Sync changes",
    "organizations": "Organisations",
    "checkin": "Check-in",
    "orders": "Orders",
    "reservations": "Reservations"
  },
  "actions": {
    "accept": "Accept",
    "seeAll": "See all",
    "ok": "OK",
    "close": "Close",
    "logout": "Logout",
    "login": "Login",
    "signup": "Sign up",
    "publish": "Publish",
    "savePublish": "Save and publish",
    "confirm": "Confirm",
    "confirmAll": "Confirm all",
    "saveChanges": "Save changes",
    "invalidate": "Invalidate",
    "reset": "Reset",
    "export": "Export",
    "loadMore": "Load more",
    "cancel": "Cancel",
    "search": "Search",
    "copy": "Copy",
    "apply": "Apply",
    "edit": "Edit",
    "back": "Back",
    "save": "Save",
    "delete": "Delete",
    "preview": "Preview",
    "next": "Next",
    "uploadImage": "Upload image",
    "checkin": "Check-in",
    "undoCheckin": "Undo check-in",
    "showAll": "Show all",
    "clear": "Clear",
    "solve": "Autoseat",
    "finalize": "Finalize",
    "assign": "Assign",
    "printTickets": "Print ticket | Print tickets",
    "printPrivileges": "Print privilege | Print privileges",
    "printAllPrivilegesforCustomer": "Print all tickets",
    "print": "Print",
    "block": "Block",
    "unblock": "Release",
    "addRow": "Add row",
    "email": "Email",
    "sendEmail": "Send Email",
    "resend": "Resend",
    "revoke": "Revoke",
    "editPhoneNumber": "Edit Phone Number",
    "deleteAccount": "Delete Account",
    "sellTickets": "Sell Tickets",
    "batchAction": "Batch action",
    "import": "Import",
    "browse": "Browse",
    "selectTimezone": "Please select a time zone",
    "chooseFile": "Choose a file or drop it here",
    "linkEmbed": "Link/embed",
    "view": "View",
    "click": "click here",
    "add": "Add",
    "remove": "Remove",
    "removeAll": "Remove all",
    "change": "Change",
    "activate": "Activate",
    "deactivate": "Deactivate",
    "toDashboard": "Continue to dashboard",
    "switchOrganization": "Switch organization",
    "allOrganizations": "Show from all my organisations",
    "goToEvents": "Go to my events",
    "clearFile": "Clear file",
    "download": "Download",
    "help": "Help",
    "multiNoteTicket": "Multiple notes. Click the ticket for details.",
    "multiSeatingTicket": "Multiple seatings. Click the ticket for details.",
    "createGroup": "Create group",
    "select": "Select",
    "issue": "Issue",
    "issueNow": "Issue now",
    "createReservation": "Create Reservation",
    "update": "Update",
    "distributeTickets": "Distribute tickets",
    "editNotes": "Edit notes",
    "addNotes": "Add notes"
  },
  "labels": {
    "en": "English",
    "nl": "Nederlands",
    "de": "German",
    "it": "Italiano",
    "ko": "한국어",
    "es": "Español",
    "locale": "Language",
    "yes": "Yes",
    "no": "No",
    "name": "Name",
    "shortName": "Short name",
    "date": "Date",
    "time": "Time",
    "id": "ID",
    "checkinOptions": {
      "all": "All",
      "blocked": "Blocked",
      "assigned": "Assigned"
    },
    "amountSpecifier": "of",
    "ticketeer": "Ticketeer",
    "currentOrg": "Current organisation",
    "events": "Event | Events",
    "eventTitle": "Event name | Events name",
    "order": "Order | Orders",
    "reservation": "Reservation",
    "created": "Created",
    "expiry": "Expiry",
    "expired": "Expired",
    "claimed": "Claimed",
    "open": "Open",
    "canceled": "Canceled",
    "totalValue": "Total Value",
    "paidValue": "Paid value",
    "completed": "Completed",
    "actions": "Actions",
    "payment": "Payment | Payments",
    "method": "Method | Methods",
    "orderId": "Order-ID {id}",
    "paymentId": "Payment-ID {id}",
    "template": "Template | Templates",
    "templateTitle": "Template name",
    "organization": "Organisation",
    "section": "Section",
    "enabled": "enabled",
    "disabled": "disabled",
    "state": "State",
    "success": "Success!",
    "danger": "Error",
    "credits": "Credits",
    "summary": "Summary",
    "paymentMethod": "Payment Method | Payment methods",
    "pspId": "Psp ID",
    "type": "Type",
    "left": "Left",
    "max": "Max",
    "label": "{options} and {length} more",
    "notAvailableShort": "N/A",
    "availability": "Availability",
    "privilege": "Privilege | Privileges",
    "category": "Category | Categories",
    "yourOrders": "Your orders",
    "yourCustomers": "Your customers",
    "yourShops": "Your shops",
    "contactName": "Contact name",
    "contactEmail": "Contact email",
    "contactPhone": "Contact phone",
    "email": "Email address",
    "createdOn": "Created on",
    "createdBy": "Created by",
    "yourReservations": "Your reservations",
    "reservationId": "Reservation-ID {id}",
    "invitations": "Invitation | Invitations",
    "recipient": "Recipient",
    "claimedBy": "Accepted by",
    "expirationDate": "Expiration date",
    "customMessageContent": "Custom message content",
    "dates": {
      "today": "Today",
      "yesterday": "Yesterday",
      "last7Days": "Last 7 days",
      "last4Weeks": "Last 4 weeks",
      "thisMonth": "This month",
      "thisYear": "This year",
      "custom": "Custom",
      "allTime": "All time",
      "endDate": "End date",
      "startDate": "Start date",
      "fromDate": "From date",
      "untilDate": "Until date (included)",
      "years": "year | years",
      "am": "AM",
      "pm": "PM",
      "timezone": "Time zone",
      "validFrom": "Valid from",
      "validUntil": "Valid until",
      "relativeTime": "Relative time",
      "dateOptions": {
        "dateAndTime": "Date & time",
        "specificDate": "on a specific day and time",
        "whenShopOpens": "when the shop opens",
        "relativeDateEnds": "on relative time before the event ends",
        "relativeDateStarts": " on relative time before the event starts",
        "whenEventEnds": "when the event ends",
        "whenDoorsOpen": "when the doors open",
        "doorsOpenWarning": "Your event starts on {eventDateTime}. The customers' tickets QR code will become available in their app 3 hours before the doors open.",
        "immediately": "Immediately",
        "absoluteTimeWarning": "Warning: Created events from a template, or copied events, will use this specific date, even when the newly created event's date is different.",
        "relativeTimeWarningStart": {
          "cta": "Enter the amount of days before the event starts and the time on that day to use this relative date.",
          "warning": "If your event starts on {eventStarts} the actual date would be the {selectedDateTime}"
        },
        "relativeTimeWarningEnd": {
          "cta": "Enter the amount of days before the event ends and the time on that day to use this relative date.",
          "warning": "If your event ends on {eventEnds} the actual date would be the {selectedDateTime}"
        },
        "relativeTimeInfo": "Enter the amount of days before the event ends and the time on that day to use this relative date.",
        "relativeTimeExample": "If your event ends on {eventEnds} the actual date would be the {datetime}.",
        "ticketRelativeTimeFromInfo": "Enter the amount of days before the event ends and the time on that day to set the moment when these tickets become available at the shop.",
        "ticketRelativeTimeToInfo": "Enter the amount of days before the event ends and the time on that day to set the moment when these tickets become unavailable at the shop.",
        "daysBeforeEventEnds": "Days before the event ends",
        "daysBeforeEventStarts": "Days before the event starts",
        "dayTime": "Time on that day",
        "never": "Never"
      }
    },
    "profile": {
      "anonymous": "Anonymous",
      "firstName": "First name",
      "lastName": "Last name",
      "phoneNumber": "Phone number",
      "mobileNumber": "Mobile number",
      "email": "Email",
      "language": "Language",
      "confirmEmail": "Confirm email",
      "zipcode": "Zipcode",
      "birthdate": "Birthdate",
      "gender": "Gender",
      "ageGroups": "Age groups",
      "role": "Role",
      "m": "Male",
      "f": "Female",
      "o": "Other",
      "lastPurchase": "Last purchase",
      "contact": "Contact information",
      "addressInformation": "Address information",
      "basicInformation": "Basic information",
      "address": "Street name & house number",
      "addressShort": "Address",
      "city": "City",
      "country": "Country",
      "state": "State",
      "nft_wallet_address": "NFT wallet address",
      "gusuuid": "GUS uuid",
      "publicTicketEngineUrl": "Wallet link"
    },
    "event": {
      "shortName": "Short name",
      "seated": "Seated",
      "nonSeated": "General admission",
      "livestream": "Livestream",
      "title": "Title",
      "subtitle": "Subtitle",
      "location": "Location",
      "sublocation": "Sub-location (hall)",
      "city": "City",
      "country": "Country",
      "address": "Address",
      "datesTitle": "Dates & Times",
      "doorsOpen": "Opening time",
      "startDate": "Date the event starts",
      "daysRunning": "Duration in days",
      "when": "Starting time",
      "ends": "Closing time on the final day",
      "endDateTime": "End date and time",
      "saleStart": "Sale start date",
      "saleEnds": "Sale ends date",
      "url": "URL of the event",
      "slug": "Slug",
      "draft": "Draft",
      "timezone": "Timezone where the event is taking place",
      "capacity": "Capacity"
    },
    "ticketStatus": {
      "sold": "Issued",
      "returned": "Returned",
      "resold": "Resold",
      "forsale": "For sale",
      "being-resold": "For sale & in basket",
      "created": "Created",
      "invalidated": "Invalidated",
      "executed": "Checked-in",
      "partialCheckedIn": "Checked-in",
      "inCart": "In cart",
      "notForSale": "Not for sale",
      "notExecuted": "Not checked-in",
      "unassigned": "Unassigned",
      "blocked": "Blocked",
      "blocks": "Blocks",
      "assigned": "Assigned",
      "external": "External",
      "free": "Available",
      "released": "Released",
      "transferred": "Reservation Claimed"
    },
    "orderStatus": {
      "all": "All",
      "created": "Created",
      "pending": "Pending",
      "canceled": "Canceled",
      "timeout": "Timeout",
      "completed": "Completed",
      "failed": "Failed"
    },
    "reservationStatus": {
      "label": "Status",
      "all": "All",
      "confirmed": "Confirmed",
      "distribution_started": "Distribution started",
      "awaiting_confirmation": "Awaiting confirmation",
      "awaiting_distribution": "Awaiting distribution",
      "invalidated": "Invalidated",
      "checked_in": "Checked-in",
      "partially_checked_in": "Checked-in",
      "invited": "Invited",
      "expired": "Expired",
      "canceled": "Canceled",
      "completed": "Completed"
    },
    "reservationInvitationStatus": {
      "label": "Status",
      "invited": "Invited",
      "claimed": "Accepted",
      "revoked": "Revoked",
      "created": "Created"
    },
    "paymentStatus": {
      "pending": "Pending",
      "paid": "Paid",
      "duplicated": "Duplicated",
      "expired": "Expired",
      "failed": "Failed",
      "canceled": "Canceled"
    },
    "refundStatus": {
      "pending": "Pending",
      "success": "Success",
      "failed": "Failed"
    },
    "orderType": {
      "normal": "Online",
      "cashier": "Cashier",
      "reservation": "Reservation",
      "claimed": "Claimed"
    },
    "seat": {
      "rows": "Row | Rows",
      "rowsAbbreviation": "Rw",
      "sections": "Section | Sections",
      "sectionsAbbreviation": "Sec",
      "subsections": "Subsection | Subsections",
      "subsectionsAbbreviation": "Sub-s",
      "entrances": "Entrance | Entrances",
      "entrancesAbbreviation": "Ent",
      "ranks": "Rank | Ranks",
      "tiers": "Tier | Tiers",
      "seats": "Seat | Seats",
      "seatsAbbreviation": "St",
      "category": "Category",
      "cashierSeating": {
        "auto": "Seated automatically",
        "manual": "Seated manually",
        "not_applicable": "Not applicable"
      }
    },
    "ticketTypes": {
      "upsell": "Upsell",
      "access": "Entrance",
      "checked_in": "Checked-in"
    },
    "ticketType": "Ticket",
    "eventOrganizer": "Event organizer",
    "tickets": "Ticket | Tickets",
    "types": "Type | Types",
    "totals": "Total | Totals",
    "total": "Total",
    "from": "From",
    "until": "Till",
    "description": "Description",
    "available": "Available",
    "forSale": "For sale",
    "locks": "Block | Blocks",
    "guests": "Guest | Guests",
    "upsells": "Upsell | Upsells",
    "variants": "Variant | Variants",
    "combiTicket": "Combi-ticket",
    "guestlist": "Guest list",
    "price": "Price",
    "free": "Free",
    "status": "Status",
    "lastStatus": "Last status",
    "onDate": "on {date}",
    "groups": "Group | Groups",
    "notes": "Note | Notes",
    "sales": "Sales",
    "checkIns": "Check-ins",
    "seating": "Seating",
    "privileges": "Privileges",
    "accessPrivileges": "Entrance Privilege | Entrance Privileges",
    "ownedBy": "Owned by",
    "noResults": {
      "default": "No results found...",
      "orders": "No orders found...",
      "customers": "No customers found..."
    },
    "results": "results",
    "step": "Step {count}",
    "amount": "Amount",
    "amountFinance": "Amount",
    "refundedAmount": "Refunded amount",
    "amountRefunded": "Amount refunded to {currency}",
    "pricePerTicket": "Price per ticket",
    "numberOfTickets": "Number of tickets",
    "subtotal": "Subtotal",
    "paidInFiat": "Paid in {currency}",
    "paidWithCredits": "Paid with Credits",
    "refundedToCredits": "Refunded to Credits",
    "dateCreated": "Date created",
    "dateUpdated": "Last updated",
    "totalDeducted": "Total deducted",
    "referral_id": "Referral ID",
    "primary": "Primary",
    "secondary": "Secondary",
    "unknown": "Unknown",
    "note": "Note",
    "privilegeNote": "Privilege note",
    "ticketNote": "Ticket note",
    "optional": "(optional)",
    "mandatory": "(mandatory)",
    "localTime": "Local time",
    "other": "Other",
    "pools": "Pool | Pools",
    "availableTicketsTitle": "Available tickets",
    "availableTickets": "Available",
    "allocated": "Allocated",
    "blocks": "Blocks",
    "refundType": {
      "name": "Refund method",
      "auto": "Automatic",
      "manual": "Manual",
      "credits": "Credits",
      "no_refund": "No refund"
    },
    "shops": "Shop | Shops",
    "details": "Details",
    "priceType": "Price type",
    "priceSelect": "Select a price",
    "customPrice": "Custom price",
    "globalPrice": "Global price",
    "totalPrice": "Total price",
    "example": "(example)",
    "users": "Users",
    "currency": "Currency",
    "paidEur": "Paid with {currency}",
    "paidCredits": "Paid with Credits",
    "refundedEur": "Refunded to {currency}",
    "refundedCredits": "Refunded to Credits",
    "grossRevenue": "Gross Revenue",
    "refunded": "Refunded",
    "inCurrency": "In {currency}",
    "toCredits": "To Credits",
    "market": "Market",
    "info": "Info",
    "paidWithCreditsPartially": "Partially paid with credits",
    "mandatoryField": "mandatory field",
    "collectibles": "Collectibles"
  },
  "validation": {
    "error": {
      "minFileSize": "The filesize must be bigger than {max}Kb.",
      "minValue": "Value should be higher than {min}",
      "maxValue": "Value should be {max} or lower",
      "maxDecimals": "You can use up to {precision} decimals",
      "validateImageDeminsons": "The resolution must be higher than {width}x{height}",
      "minDate": "The {field} must be after {minVal}",
      "maxDate": "The {field} time must be before {maxVal}",
      "minOffset": "The {field} must be before {maxVal}",
      "maxOffset": "The {field} must be after {minVal}",
      "required": "This field cannot be empty",
      "richTextMaxLength": "It cannot be more than {maxLength} characters",
      "cover": "This picture does not have the correct dimensions",
      "maxSize": "It should not exceed {maxSize} kb in size",
      "fileType": "It is not the correct file type",
      "maxLength": "Field can't be longer than {max}",
      "maxCharLength": "Field can't be longer than {max} characters",
      "combinedMaxLength": "Each field can't be longer than {max} characters",
      "decimal": "It should be a valid number",
      "alphaNum": "It should be a valid alphanumeric value",
      "alphaNumDashUnderscore": "It should contain only alphanumeric, underscore and dash",
      "numeric": "It should be a numeric value",
      "phoneNumber": "This is not a valid mobile number. (Country codes are required, i.e \"+31\")",
      "sameAsNumber": "The phone numbers do not match, please re-enter.",
      "sameAs": "The {field} entered do not match, please re-enter.",
      "url": "Please enter a valid url (https://example.com).",
      "email": "Please enter a valid email address.",
      "integer": "Please enter a whole number.",
      "minLength": "The {field} must be at least {length} characters long.",
      "nonNumeric": "The {field} cannot contain only numeric characters.",
      "wrongPass": "Please enter the correct password.",
      "phoneNumberValidation": "Always include plus sign (+) and country code!",
      "isNotUnicode": "Please make sure to use no unicode characters"
    }
  },
  "views": {
    "reports": {
      "title": "Your exports",
      "nav": {
        "exports": "Exports",
        "sales": "Sales"
      },
      "exports": {
        "tabs": {
          "revenue": "Revenue",
          "summary": "Summary",
          "orders": "Orders",
          "attendees": "Attendees",
          "blocks": "Blocks",
          "advanced": "Advanced",
          "groups": "Groups"
        },
        "type": {
          "group_sizes_report": "Groups",
          "revenue_report": "Revenue",
          "summary_report": "Summary",
          "orders_report": "Orders",
          "attendees_report": "Attendees",
          "blocks_report": "Blocks"
        },
        "exportHistory": {
          "title": "Your latest exports",
          "created": "Created {date}",
          "generating": "Generating export...",
          "exportGenerationSuccess": "Export successfully generated"
        },
        "selectEventsAction": "Add or remove events",
        "action": "Generate export",
        "reportsGenerationFail": "Generating export failed, please retry...",
        "configurationError": "No events belonging to your organisation in selected gate found...",
        "limitError": "You can generate a report for a maximum of {max} events! In case you want to create bigger exports, please contact our support.",
        "selectedEvents": "{selectedEvents} / {maxEvents} event selected | {selectedEvents} / {maxEvents} events selected",
        "gateInfo": "Note: only events belonging to your organisation in the selected gate will be included in the export.",
        "selectOrg": "Select the organisation to create export for",
        "options": {
          "tickets": "Options: tickets & upsells",
          "events": "Options: for events & shops"
        },
        "advanced": {
          "eventSales": "All Ledger Transactions",
          "secondaryMarket": "Resale Market Transactions",
          "billingSummary": "Billing Summary"
        },
        "attendees": {
          "privacy-policy": "Attendees opted-in on privacy policy (if applicable)",
          "user-privacy": "Only attendees that accepted your privacy-policy",
          "brand-privacy": "Only attendees that accepted the privacy-policy of {brand}",
          "all": "Include all attendees",
          "optional": "Include optional attendees",
          "non-owners": "Include attendees that don’t own tickets anymore (resold, invalidated)",
          "invited": "Include attendees invited by ticket-holders in a group",
          "warning": "Note: By European law you are allowed to use the data of attendees who have not accepted your privacy-policy for service necessities only. This excludes marketing."
        },
        "merge": {
          "pricetype": "Merge same price type",
          "hideItems": "Hide items with empty values"
        },
        "groupBy": {
          "events": "Group by event",
          "shop": "Group by shop"
        },
        "desiredFormat": "Desired format",
        "format": {
          "xlsx": "Excel (xlsx)",
          "csv": "Comma separated (csv)"
        },
        "includeEvents": "Include the following events",
        "eventOptions": {
          "all-events": "All events",
          "specific-gate": "Events in specific gate",
          "specific-events": "Select specific events"
        },
        "periodInclude": "Select period when revenue is received",
        "periodOptions": {
          "specific-period": "Specific period",
          "all-time": "All time"
        },
        "selectEventsModal": {
          "title": "Select events to include in export",
          "yourEvents": "Your events",
          "added": "Events added",
          "placeholder": "Search events",
          "addEvent": "Add {count} event | Add {count} events",
          "removeEvent": "Remove {count} event | Remove {count} events",
          "info": "To select multiple events, you can press shift or control (command on Mac) and select, or click and drag."
        }
      }
    },
    "organizations": {
      "organization": {
        "title": "Organisation",
        "name": "Organisation Identity",
        "legalName": "Organisation Name",
        "legalNameDescription": "The official name of the organisation as it will be shown to customers.",
        "privacyPolicy": "Privacy policy text and/or file (optional)",
        "privacyPolicyFileDescription": "Overwrite the default text for your privacy policy opt-in",
        "privacyPolicyPlaceholder": "I want to be kept informed of events and promotions and agree with the privacy statement of {orgName}.",
        "termsConditions": "Terms & conditions text and/or file (mandatory for attendee)",
        "termsConditionsFileDescription": "Overwite the default text for your terms & conditions opt-in",
        "termsConditionsPlaceHolder": "I agree with the terms and conditions of {orgName}.",
        "localeSelectPlaceholder": "Select default language for e-mails etc.",
        "pspSelectPlaceholder": "Select a payment service provider.",
        "demo": {
          "label": "Demo mode",
          "description": "Create organisation for demo purposes only",
          "info": "A demo organisation is limited to dummy payments, and cannot be used to sell real tickets.",
          "badge": "Demo",
          "alert": "This organisation is set to demo mode. Real payments are disabled. Events created under it are only to be used for demo or testing purposes."
        },
        "miscellaneous": {
          "title": "Miscellaneous",
          "refund": "Refund to credits",
          "expiration": "Expiration period",
          "expirationInfo": "This period can be overridden based on the role."
        },
        "paymentInfo": {
          "title": "Payments",
          "psp": {
            "label": "Payment Service Provider",
            "dummyInfo": "Note: Only use for demos or testing. Real payments are not possible.",
            "selectInfo": "Note: The selected currency determines which payment service providers are available.",
            "lockedInfo": "Note: You cannot switch PSP after your first ticket has been sold.",
            "options": {
              "mollie": "Mollie",
              "stripe": "Stripe",
              "dummy": "Dummy",
              "ticketeer_payments": "PSP of {brand}"
            }
          },
          "apiKey": {
            "test_key": "You don't have permission to submit TEST API credentials",
            "label_apiKey_mollie": "Live API key",
            "label_apiKey_stripe": "Secret Key",
            "label_publicKey_stripe": "Publishable key",
            "label": "API key",
            "publicKey": "Public API key",
            "addInfo": "Add the API key provided by your payment service provider. When you set the API key correctly, approval is done immediately.",
            "existsInfo": "Note: You cannot change this API key after your first ticket has been sold.",
            "error": "This API key does not seem to be valid",
            "apiErrors": {
              "invalid_api_key": "Error: Invalid API key"
            }
          },
          "methods": {
            "title": "Payment methods",
            "overrideLabel": "Override payment methods",
            "overrideInfo": "The following payment methods are set according to your PSP account you selected. If you chose to override the payment methods, changes in your PSP account won’t reflect in these settings.",
            "overrideInfoStripe": "Important: The accepted payment methods need to exactly match your Stripe enabled payment methods, otherwise payments will not work! By default everything is enabled."
          }
        },
        "fileDescription": "PDF's only with a maximum filesize of 20mb",
        "previewFileLink": "View file",
        "usersAndRoles": {
          "navTitle": "Users & roles",
          "create": "Invite user",
          "active": "Active",
          "deactivated": "Deactivated",
          "search": "Search users",
          "activateUser": "Activate",
          "pendingInvitation": "{role} pending invitation",
          "expiredInvitation": "Invitation expired",
          "inviteUserModal": {
            "edit": "Edit user",
            "pending": "Pending invitation",
            "expired": "Expired invitation",
            "invite": "Invite user",
            "info": "An email will be sent to this email address to create an account or log-in and link the organisation to this user.",
            "emailAlreadyUsed": "This email address has already been invited to the dashboard. Please use a different email address.",
            "send": "Send invitation",
            "resend": "Resend invitation",
            "removeInvitation": "Remove invitation",
            "deactivate": "Deactivate"
          },
          "switchUserStateModal": {
            "activateTitle": "Activate user",
            "deactivateTitle": "Deactivate user",
            "description": "Are you sure you want to {action} {email}?"
          }
        },
        "attendeeInfo": {
          "title": "Attendee information",
          "description": "The following is the information attendees will have to fill in when buying tickets for your events. You can select if you want the information below to be mandatory, optional or hidden. The fields marked as always mandatory cannot be edited.",
          "states": {
            "always": "Always mandatory",
            "mandatory": "Mandatory",
            "optional": "Optional",
            "hide": "Hide"
          },
          "anonymize": {
            "button": "Remove account",
            "modal": {
              "title": "Are you sure?",
              "description": "Removing the account will result in the deletion of all data and tickets. It CANNOT be rolled back."
            }
          }
        },
        "floorplans": "Floorplans"
      },
      "placeholder": "Search organisations",
      "create": "Create organisation",
      "modal": {
        "title": "Create organisation",
        "selectCurrency": "Select currency"
      },
      "table": {
        "psp": "PSP",
        "edit": "Edit"
      }
    },
    "account": {
      "editTitle": "Edit account",
      "passwords": {
        "password": "Password | Passwords",
        "changePass": "Change password",
        "resetPass": "Reset password",
        "forgotPass": "Forgot password?",
        "currentPass": "Current password",
        "newPass": "New password",
        "confirmPass": "Confirm password",
        "apiErrors": {
          "weak_password": "Password can't be similar to your email, name or a weak passwords like \"admin1234\""
        },
        "passwordInfo": {
          "title": "We expect a strong password:",
          "minLength": "Minimum length of 9 characters and not just numbers",
          "similar": "Not similar to your email, or weak passwords like \"admin123\"."
        }
      },
      "login": {
        "title": "Login",
        "invalidCredentials": "Invalid credentials",
        "noAccount": "Don't have an account?",
        "username": "Username"
      },
      "signup": {
        "title": "Sign up",
        "hasAccount": "Already have an account?",
        "apiErrors": {
          "user_already_exists": "An account with this email already exists."
        }
      },
      "reset": {
        "description": "Enter your email address and we’ll send you a link to reset your password.",
        "requestedDescription": "If there is an account associated with this email address, you will receive a link to reset your password.",
        "expiredAlert": "This link has expired. You can request another password-reset.",
        "toLogin": "Go back to login"
      }
    },
    "invite": {
      "title": "Accept invitation",
      "expired": {
        "alert": "Your invitation is not valid anymore.",
        "info": "For more information or help, please contact {0}."
      },
      "noInvite": "This invite has already been accepted or doesn't exist. You can continue to go to dashboard.",
      "accept": {
        "title": "You have been invited to join: {org}",
        "inviteAccepted": "You now have access to: {org}",
        "info": "The invitation will be accepted under your current account: {email}. If you wish to accept using a different account, log in to this account first and then click the invitation link again."
      }
    },
    "attendees": {
      "attendee": {
        "edit": {
          "changeNumber": {
            "title": "Change number",
            "description": "You are about to change the account with mobile number {number}",
            "inputLabel": "New mobile number"
          },
          "confirmNumber": {
            "attendees": "attendees",
            "migrateConfirm": "If you want to migrate, please confirm the new mobile number.",
            "existsDescription": "There is already an account tied to this new mobile number. Are you sure this is correct?",
            "existsWithTickets": "This account has upcoming events, therefore you cannot migrate. Go back if you want to use a different mobile number.",
            "inputLabel": "Confirm the new mobile number"
          },
          "success": "Successfully updated mobile number",
          "error": "Error: Could not update mobile number"
        }
      }
    },
    "customers": {
      "title": "Your customers",
      "startSearch": "Search for customers across all your events by entering a (partial) name, email address or phone number. Easy peasy!",
      "loading": "Customer loading...",
      "groupsLoading": "Loading groups...",
      "tooltips": {
        "privilegesDescription": "The total number of entrance privileges allocated to a customer."
      },
      "tickets": {
        "ticketsTable": {
          "emptyText": "No tickets found, check orders for more information",
          "emptyTextShort": "No tickets found",
          "resoldBy": "by {customer}"
        },
        "checkin": {
          "title": "Check-in",
          "description": "Are you sure that you want to check-in this ticket? | Are you sure that you want to check-in these {count} tickets?"
        },
        "undoCheckin": {
          "title": "Undo Check-in",
          "description": "Are you sure that you want to undo check-in this ticket? | Are you sure that you want to undo check-in these {count} tickets?"
        },
        "invalidate": {
          "steps": {
            "options": "Refund options",
            "details": "Refund details"
          },
          "refundOptions": {
            "blockTickets": "Block the ticket that will be available again | Block the tickets that will be available again",
            "tooltipText": "If you don't select this option, this ticket will be up for sale again. | If you don't select this option, the tickets will be up for sale again.",
            "title": "Refund ticket | Refund tickets",
            "description": "Select if and how you want refund the ticket you are about to invalidate. | Select if and how you want refund the tickets you are about to invalidate.",
            "options": {
              "automatic": {
                "title": "Automatic refund",
                "description": "Automatically refund the amount paid for the tickets to the attendee. Select in the next step if the attendee or the organizer will be charged the service fee."
              },
              "manual": {
                "title": "Manual refund",
                "description": "Refund manually using a external payment method and adjust the reports accordingly. Select in the next step if the attendee or the organizer will be charged the service fee."
              },
              "credits": {
                "title": "Credits",
                "description": "Refund the amount and add these funds to account of the user to be redeemed later."
              },
              "noRefund": {
                "title": "No refund",
                "description": "Only invalidate the tickets, keep the original paid amount in the reports."
              }
            }
          },
          "refundDetails": {
            "warning": "This action cannot be undone. If you wish to continue, press confirm.",
            "title": {
              "auto": "Automatic refund",
              "manual": "Manual refund",
              "credits": "Credits",
              "noRefund": "No refund"
            },
            "howToRefund": "How would you like to refund the following tickets?",
            "fullRefund": "Full refund",
            "partialRefund": "Partial refund",
            "amountToDeduct": "Amount you wish to deduct per ticket",
            "creditsExpiration": "Credits expiration date",
            "summary": {
              "title": "Summary",
              "description": "The following ticket will be invalidated. | The following tickets will be invalidated.",
              "totalRefund": "Total refund: {formatedAmount}",
              "ticketBlockedOnCategory": "The ticket will be block on category: | The tickets will be block on category:"
            }
          }
        },
        "editNotes": {
          "title": "Edit note | Edit notes",
          "multiNotesDescription": "You're editing multiple notes, this change will reflect on all the items you selected.",
          "placeholder": "Note"
        }
      },
      "block_categories": {
        "uncategorized": "Uncategorized",
        "uncategorized_short": "UNC",
        "search": "Search a category..."
      },
      "privileges": {
        "privilegesTable": {
          "emptyText": "No privileges found"
        }
      },
      "orders": {
        "ordersOverviewTitle": "Your orders"
      },
      "refunds": {
        "refundsTable": {
          "emptyText": "No refunds found"
        }
      },
      "customer": {
        "noName": "Unnamed customer",
        "noAddress": "No address",
        "userQr": {
          "action": "User Qr",
          "title": "{name} user QR",
          "description": "Scan the following QR code to check-in the customer.",
          "error": "Could not load the QR code for this customer"
        },
        "edit": {
          "changeNumber": {
            "title": "Change number",
            "description": "You are about to change the account with mobile number {number}",
            "inputLabel": "New mobile number"
          },
          "confirmNumber": {
            "migrateInfo": "This mobile number can be changed. You can optionally check if this account already exists, by going to {customers}.",
            "customers": "customers",
            "migrateConfirm": "If you want to migrate, please confirm the new mobile number.",
            "existsDescription": "There is already an account tied to this new mobile number. Are you sure this is correct?",
            "existsWithTickets": "This account has upcoming events, therefore you cannot migrate. Go back if you want to use a different mobile number.",
            "inputLabel": "Confirm the new mobile number"
          },
          "success": "Successfully updated mobile number",
          "error": "Error: Could not update mobile number"
        }
      },
      "filters": {
        "pool": "Pool",
        "shop": "Shop",
        "checkin": {
          "name": "Checked-in status",
          "fully": "Fully",
          "partially": "Partially",
          "not": "Not"
        }
      }
    },
    "events": {
      "create": {
        "title": "Create event",
        "new": "New event",
        "fromTemplate": "Choose template"
      },
      "title": "Your events",
      "titleWithOrganization": "Your events for {org}",
      "reports": {
        "title": "Your sales",
        "titleWithOrganization": "Your sales for {org}",
        "revenue": "Revenue",
        "revenueBoxTitle": "NET revenue this period",
        "ticketSoldBoxTitle": "Tickets sold this period",
        "grossRevenue": "GROSS revenue",
        "invalidated": "Invalidated",
        "refunded": "Refunded",
        "upsellSoldBoxTitle": "Upsells sold this period",
        "credits": {
          "title": "Credits",
          "circulating": "Circulating",
          "info": "The amount of credits you refunded which haven't expired or been used yet"
        }
      },
      "eventCreate": {
        "title": "Create new {type}",
        "eventDescription": "Create a brand new event from scratch! These steps will help you create the event that matches your needs perfectly. First, you choose the core type of event. You can edit all other settings afterwards without a problem, except for this core event type. Of course, you can always start over, this will be saved as a draft until you decide it's good to go. No pressure, you've got this.",
        "templateDescription": "Create a new template from scratch! These steps will guide you to set up the minimal requirements for an event that you can use as your own template. You can edit all settings afterwards, except for the initial type of event chosen in this first step.",
        "templateInfo": "Once you have your template, you can automatically generate multiple events with the same set-up. All settings in the template will be copied and updated for all events. ",
        "setEventType": "Type of event",
        "selectType": {
          "label": "Select the type of event you want to create"
        },
        "selectVenue": "Select venue",
        "eventDetails": "Event details",
        "ticketManagement": "Ticket management",
        "modal": {
          "title": "Preview of created event",
          "content": "Your new event have been created successfully and saved under your Draft Events.<br><br>You can publish your event right now, or go back to the dashboard to make further modifications before that.",
          "dashboardButton": "Go back to Dashboard",
          "publishButton": "Publish event"
        }
      },
      "eventCopy": {
        "action": "Copy Event",
        "description": "All configurations of the event will be copied, along with the holds.",
        "startDate": "Start Date"
      },
      "templateCopy": {
        "action": "Copy Template",
        "description": "All configurations of the template will be copied, along with the holds."
      },
      "event": {
        "savedAsDraft": {
          "title": "Draft event was saved",
          "draftInfo": "Your event was successfully created and saved as a draft. You can access and edit your event in the dashboard under your Draft Events.",
          "previewInfo": "You can preview your event now and publish later, or publish right now. Press OK to go back to the dashboard."
        },
        "demo": {
          "description": "Demo event: This is event has been created under a demo organisation. Real payments are disabled. Only use this event for demo or testing purposes."
        },
        "unlink": {
          "title": "This event is managed by a template.",
          "description": "Changes to shops, tickets, pools and other event settings can only be made and synced to all events at once in this event’s {0}.",
          "eventTemplate": "template",
          "shop": {
            "title": "This shop is managed by a template.",
            "description": "Changes to this shop, its tickets and groups can only be made and synced to all events at once in {0}.",
            "link": "this event's template shop"
          },
          "action": {
            "description": "If you want to only make changes in this event {0}",
            "unlink": "unlink from template."
          },
          "modal": {
            "title": "Are you sure you want to unlink this event?",
            "description": "When you unlink this event from its template, it will not be updated anymore when changes are made in this template and synced. You are able to manage it completely as a single event. This action cannot be undone!",
            "action": {
              "unlink": "unlink",
              "description": "Type {0} in the field below to confirm"
            },
            "unlinkEvent": "Unlink event"
          }
        },
        "manage": {
          "placeholders": {
            "title": "e.g. Maximum Music Festival",
            "subtitle": "e.g. 20th Aniversary",
            "shortName": "e.g. MaxMusic"
          },
          "shortNameExplanation": "Will be used for SMS notifications when issuing tickets through the dashboard",
          "generalTitle": "Manage general event details",
          "additionalTitle": "Manage additional event details",
          "advancedTitle": "Manage advanced event details",
          "saveValidateAlert": "Some required fields are missing or have invalid data.<br>Please check for errors above.",
          "marketing": "Marketing",
          "scannerPassword": "Scanner password",
          "scannerQr": "QR code",
          "about": {
            "label": "About your event",
            "description": "Describe what your event is all about, communicate extra guidelines to your visitors and provide general information and external links.",
            "help": "Select the text to make it bold, italic or add a link."
          },
          "endDateSwitch": "This event ends after midnight on the next day",
          "endDateDescription": "If an event ends after midnight (e.g. 01:00) it technically ends a day later - early in the morning. Toggle this to communicate that this event actually ends on the previous day.",
          "templateStartDateInfo": "Uses the specified date of an event when it is generated.",
          "dateErrors": {
            "required": "All date and time fields are required. Please make sure to fill in all information.",
            "minOpen": "The opening time cannot be after the start time of the event.",
            "minOpenLivestream": "The opening time for livestream should be at least 15 min before the event start."
          },
          "coverImage": {
            "label": "Cover photo",
            "description": "This will be shown in different crops, depending on where it's used in the app. Try to avoid text or logos in the image. Make your event look good!",
            "help": "PNG or JPG file type. Minimum 640x480 resolution - but 10MB max!"
          },
          "floorplanImage": {
            "label": "Map or floorplan",
            "description": "Will be provided by the ticketeer in eps format.",
            "help": "SVG or PNG file type. Best resolution is 375 pixel wide."
          },
          "orderSuccessLink": {
            "label": "Call-to-action when tickets are purchased",
            "description": "Send buyers a short message or call-to-action. Let them know you're thinking of them!"
          },
          "orderSuccessEmail": {
            "label": "Email marketing",
            "description": "Add a short, extra message to the ticket confirmation email, if you want. Show you're happy they're coming."
          },
          "saveAlert": {
            "title": "Save changes",
            "description": "Are you sure you want to save the changes you made for this published event? Visitors will be able to see the changes directly."
          },
          "publishAlert": {
            "title": "Make event public",
            "description": "Are you sure you want to publish this event and make it publicly available? <br> Once an event is published you <b>won't</b> be able to set it back to draft. Tickets will only be available on the provided start date of sale, though.",
            "shopsTitle": "Your event and the following shops will become public",
            "cancel": "Not now",
            "confirm": "Publish now",
            "warning": "Publishing cannot be undone",
            "shop": "Shop",
            "defaultGate": "Default Gate",
            "opens": "Opens on",
            "immediately": "Immediately",
            "shopsFailed": "Failed to load shops, please try again",
            "tryAgain": "Try again",
            "publishError": "The event could not be published. Please make sure that the payment service provider configuration of your organisation is fully setup.",
            "orgSettings": "Go to settings for: {name}"
          },
          "livestream": {
            "typeSelect": {
              "label": "Select how you would like to configure your livestream (optional)",
              "internal": "Internal: Use your own embed codes",
              "external": "External: Use an external link to redirect to"
            },
            "videoEmbed": {
              "label": "Livestream video embed code (optional)",
              "placeholder": "<iframe src='https://player.vimeo.com/video/123456789/' frameborder=0 allow=autoplay; fullscreen allowfullscreen></iframe>"
            },
            "chatEmbed": {
              "label": "Livestream chat embed code (optional)",
              "placeholder": "<iframe src='https://vimeo.com/live-chat/123456789/' frameborder='0'></iframe>"
            },
            "externalUrl": {
              "label": "Livestream external URL (optional)",
              "placeholder": "https://livestream.com"
            }
          },
          "advanced": {
            "sharing": {
              "title": "Sharing",
              "label": "Ticket sharing between attendees (optional)",
              "strict": "Share via QR code",
              "strictInfo": "Attendees need to meet up in person and scan a QR code to share their tickets with each other",
              "easy": "Share via Link",
              "easyInfo": "Attendees can send a link to invite others to join",
              "disabled": "Not allowed",
              "disabledInfo": "Attendees can't share their tickets for this event"
            },
            "private": {
              "title": "Blockchain Visibility",
              "label": "It's possible to hide your event details on the blockchain. This includes event name, location, image and the shop URL",
              "checkbox": "Do not show event details on the blockchain"
            },
            "orderConfirmEmail": {
              "title": "Order confirmation email",
              "label": "Email address (optional)",
              "placeholder": "example{'@'}organiser.com",
              "info": "We'll send out an e-mail to this address with details on every order made."
            },
            "scanLeeway": {
              "title": "Offline scanning leeway",
              "label": "Set amount of time in minutes",
              "info": "Attendees can enter with a QR code that doesn't require an internet connection. This setting defines how long these offline QR codes are valid."
            }
          },
          "blockchain": {
            "publish": {
              "title": "Visibility",
              "label": "When you publish, your event & shop information will also be visible on the GET Protocol blockchain. You can (optionally) specify when that should happen:",
              "never": "Do not show event details on the blockchain",
              "public": "This event's information has already been made public on the blockchain. It is not reversible.",
              "defaultInfo": "By default this is set to the opening time of your default shop."
            },
            "contractAddress": {
              "title": "Event contract address",
              "placeholderDraft": "Your event contract address will be generated once the event is published.",
              "label": "Below is the event contract address for you to view and verify all transactions.",
              "placeholder": "Generating (refresh the page to update)"
            },
            "nft": {
              "title": "Default Collectible Artwork",
              "label": "Upload your default Collectible artwork here. This design will be used for all ticket's collectibles (unless specifically overwritten within each ticket's settings).",
              "guidelines_title": "General guide lines:",
              "line_1": "PNG, GIF or JPG file type",
              "line_2": "Portrait mode with aspect ratio of 3:4",
              "line_3": "10 MB max file size",
              "line_4": "You can use <a href='{href}' download>our template</a> as a base",
              "note": "Note: If no artwork is provided, a default artwork is used by GET Protocol."
            }
          },
          "resale": {
            "title": "Resale Fees"
          },
          "previewModal": {
            "title": "Send preview email",
            "success": "Email has been sent successfully",
            "error": "An error occured sending the email"
          }
        },
        "reports": {
          "sales": {
            "turnover": "Turnover",
            "capacity": "Capacity filled",
            "ticketsSold": "Tickets sold",
            "capacityFilled": "Capacity filled",
            "capacitySold": "Capacity sold",
            "tooltipTicketsSold": "Sold",
            "availabilityTooltip": "Tickets can share pools",
            "percentageSold": "Percentage sold",
            "revenue": "Revenue",
            "grossRevenue": "Gross revenue",
            "graph": {
              "range": {
                "placeholder": "Select a period",
                "last_day": "Last 24 hours",
                "last_month": "Last 31 days",
                "all_time": "All time"
              },
              "title": {
                "last_day": "Tickets sold last 24 hours",
                "last_month": "Tickets sold last 31 days",
                "all_time": "Tickets sold all time per week"
              }
            }
          },
          "demographics": {
            "avgOrderSize": "Average number of tickets per order",
            "totalBuyers": "Total number of buyers",
            "locations": "Locations"
          },
          "execution": {
            "checkedInPercentage": "Checked-in percentage"
          }
        },
        "attendees": {
          "filters": {
            "checkin": {
              "label": "Filter by check-in",
              "partialExecuted": "Partial check-in",
              "allExecuted": "Complete check-in",
              "notExecuted": "Not checked-in",
              "all": "Show all"
            },
            "pools": "Filter by pool"
          },
          "guestlist": {
            "action": "Add guests",
            "addRow": "Add row",
            "guestListName": "Guest list name",
            "createVariant": "Create new...",
            "selectTicket": "Select ticket",
            "selectVariant": "Select guest list",
            "optionsTitle": "Add to free guest list",
            "seating": "Select blocked seats to use",
            "selectPool": "Select which availability you want to use",
            "confirmTitle": "Confirm guest list"
          },
          "cashier": {
            "selectTickets": "Select tickets",
            "blockCategory": "Block category",
            "ticketsLeft": "Tickets left",
            "specifyAmount": "Please specify how many {ticketName} tickets you would like to add",
            "cart": "Cart ({count})",
            "emailField": "Send confirmation to (optional)",
            "notifyBySms": "Notify via SMS",
            "notifyByEmail": "Notify via email",
            "addTicket": "Add {ticketName} Ticket",
            "addTicketDescription": "From which <strong>availability</strong> do you want to select your tickets from?",
            "addTicketAssignment": "How would you like to assign the seats?",
            "addTicketHowMany": "How many <strong>{ticketName}</strong> tickets from <strong>{shopName}</strong> would you like to add?",
            "addToCart": "Add to cart",
            "emailTooltip": "If no email address is specified, no confirmation email will be send.",
            "ticketsLoading": "Retrieving available tickets, please wait...",
            "tickets": "Tickets",
            "issueDigitallyCheck": "Send tickets to phone?",
            "yourOrder": "Your order {orderId} for {customerName}",
            "orderCreated": "Order {orderId} created",
            "issueMore": "Issue more tickets",
            "reserveMore": "Reserve more tickets",
            "bookPayment": "Book payment",
            "distributeTickets": "Distribute tickets",
            "checkinAll": "Check all tickets in now",
            "noTicketsSelected": "No tickets selected",
            "confirmGuestInfo": {
              "confirmTitle": "Confirm Tickets",
              "confirmMainMessage": "Tickets will be linked to the provided phone number(s) and can be accessed by the attendee via the ticket wallet.<br/>Attendees will receive an e-mail confirmation with the event & ticket info.",
              "total": "Total",
              "hasEmail": "Confirmation sent to: {email}",
              "noEmail": "No confirmation sent (no email address provided)"
            },
            "actionTooltip": "Tickets can only be issued when the event has been published",
            "action": "Issue tickets",
            "optionsTitle": "Sell tickets",
            "categories": {
              "title": "Select a block category",
              "description": "The following lists all unseated tickets. Please select the category you wish your ticket to be sold from. If a category does not have enough blocks available, you can edit them {0}",
              "categoryLink": "here."
            },
            "payment": {
              "label": "Payment received by",
              "placeholder": "Payment method"
            },
            "seatingMode": {
              "auto": "Assign seats automatically",
              "manual": "Pick seat(s) now",
              "autoseatInfo": "After event organiser finalises the seating"
            },
            "issue_now_modal": {
              "title": "What do you want to do next?",
              "choices": {
                "issue": "Issue tickets",
                "reservation": "Create reservation"
              }
            },
            "add_to_reservation_modal": {
              "title": "Add selection to reservation",
              "description": "You are about to add your selection to the reservation. Are you sure?"
            },
            "reservationForm": {
              "expiryDate": "Expiry Date",
              "details": "Details",
              "reservationName": "Reservation Name",
              "note": "Note",
              "customMessageContent": "Custom message content",
              "contactDetails": "Contact information",
              "firstName": "First Name",
              "lastName": "Last Name",
              "mobileNumber": "Mobile Number",
              "email": "Email",
              "notePlaceholder": "E.g. Team Building Event",
              "customMessageContentPlaceholder": "E.g. Please bring your ID"
            }
          },
          "blockCategories": {
            "notfound": "Category not found...",
            "createCategory": "Create category",
            "createCategoryTitle": "Create category",
            "deleteTooltip": "Only empty categories can be removed",
            "editCategory": "Edit category",
            "categoryName": "Category name",
            "categoryNamePlaceholder": "e.g. Technicians",
            "categoryShortNamePlaceholder": "ABC (max. 3)",
            "categoryShortName": "Shortcode",
            "uniqueShortNameError": "Shortcodes must be unique",
            "uniqueNameError": "A category with this name already exists",
            "selected": "{selected} out of {total} selected",
            "unblocked": "Blocks successfully removed",
            "moved": "Blocks successfully moved to category",
            "printTickets": "Print / PDF",
            "movePrivs": "Move to category",
            "noCategory": "No category",
            "editNote": "Edit note | Edit notes",
            "addNote": "Add note | Add notes",
            "floorplanLabel": "Block categories",
            "addPrivs": "Add blocks",
            "addPrivsModal": {
              "title": "Add new blocks to category \"{category}\"",
              "selectPool": "Select pool",
              "addSeated": "Want to block specific seats? {0}",
              "addSeatedLink": "Go to floorplan"
            },
            "categorySearch": "Select a category...",
            "details": {
              "ticket": "Tier / ticket",
              "filterClear": "Clear filter",
              "filterTicket": {
                "empty": "Filter on tier / ticket",
                "selected": "Filtered by {name}"
              },
              "filterSection": {
                "empty": "Filter on section",
                "selected": "Filtered by {name}"
              },
              "filterClaimed": {
                "empty": "Filter on status",
                "selected": "Filtered by checked-in"
              }
            },
            "list": {
              "fields": {
                "name": "Category"
              }
            },
            "editTicketNote": {
              "titleEdit": "Edit note(s)",
              "titleAdd": "Add note(s)",
              "multipleNotes": "You're editing multiple notes, this change will reflect on all the items you selected.",
              "placeholder": "Note"
            },
            "unblockModal": {
              "title": "Release block(s)",
              "description": "The selected blocks will be released and put up for sale.",
              "descriptionCheckedIn": "Please note: checked-in blocks can't be released, they will just be uncategorised."
            }
          },
          "externalCodes": {
            "title": "External tickets",
            "ticketInfo": {
              "ticketsImported": "Total tickets imported",
              "lastImport": "Last import",
              "ticketsCheckedIn": "Total tickets checked-in"
            },
            "importCSV": {
              "title": "Import external tickets using csv",
              "description": "Accept and check-in external tickets in the scanner by importing their barcodes via a csv file. The structure of the <strong>csv</strong> file should either be a single column containing the barcode or contain the column named 'barcode' (without quotes).",
              "placeholder": "Choose csv file or drop it here...",
              "deleteAndReplace": "Replace existing tickets. Not checked-in tickets will be deleted",
              "parsingError": "No barcodes found. Use a single column or name the column 'barcode' (without quotes)",
              "uploadFailInvalidCSV": "The provided file is not a valid csv",
              "uploadFailNoCodes": "The provided file has no codes inside",
              "uploadFail": "The csv file could not be imported",
              "uploadSuccess": "{nbTickets} tickets imported. Example of one imported barcode: {barcodeExample}",
              "fileTooBig": "The csv file you're trying to import is too large. The maximum size is 6MB.",
              "emptyFile": "The csv file you're trying to import is empty"
            }
          },
          "attendee": {
            "invalidateSuccess": "The tickets have been successfully invalidated",
            "invalidationFail": "The invalidation has failed, please try again",
            "invalidateTicketConfirm": {
              "manual": "Manual refund",
              "auto": "Automatic refund",
              "credits": "Refund as credits",
              "noRefund": "Invalidate without refund",
              "creditsExpiration": "Expiration date",
              "creditsConfirm": "These credits will expire on {0} after which they can not be used anymore",
              "titleWithoutRefund": "Confirm invalidation",
              "refundType": "How would you like to refund the following tickets?",
              "partialRefundInfos": "Fill in the amount you wish to deduct per ticket",
              "deduct": "To deduct",
              "fullRefund": "Full refund",
              "partialRefund": "Partial refund",
              "deducted": "Deducted",
              "subtitle": "You are about to invalidate and refund the following ticket | You are about to invalidate and refund the following tickets:",
              "subtitleWithoutRefund": "You are about to invalidate the following ticket | You are about to invalidate the following tickets:",
              "totalAmount": "Total amount",
              "refundAmount": "Refund",
              "partialRefundErrorMessage": "The amount is too high",
              "toolTipText": "If you don't select this option, tickets will be up for sale again",
              "selectBlock": "Select block category",
              "blockCheckbox": "Block the tickets that are available again",
              "feeMessage": "The service fee will be charged to:",
              "summaryMessage": "The following ticket(s) will be invalidated.",
              "category": "The tickets are blocked on category {0}",
              "actionUndoneInfo": "This action cannot be undone. If you wish to continue, press confirm.",
              "extraConfirmModal": {
                "extraConfirmMessage": "The refund amount is {0}. This action cannot be undone. Are you sure you wish to invalidate and refund the selected tickets?",
                "extraConfirmTitle": "Finalize invalidation and refund",
                "extraFinalizeMessage": {
                  "text": "Type {0} in the field below to confirm",
                  "finalize": "FINALIZE"
                }
              }
            },
            "invalidateTicketOptions": {
              "title": "Invalidate and refund tickets",
              "missingPaymentMethod": "Please select a payment method",
              "subtitle": "Select if and how you want to process refunds of the tickets you are about to invalidate:",
              "tooltip": "The refundable amount is less than the purchased amount. This may be due to partial refunds done in the past or multiple payment methods used.",
              "automatic": {
                "title": "Invalidate with automatic refund",
                "description": "Automatically refund the amount paid for the tickets to the attendee. Select in the next step if the attendee or the organizer will be charged the service fee."
              },
              "credits": {
                "title": "Refund as credits",
                "description": "Refund the amount and add these funds to account of the user to be redeemed later."
              },
              "manual": {
                "title": "Invalidate with manual refund",
                "description": "Refund manually using a external payment method and adjust the reports accordingly. Select in the next step if the attendee or the organizer will be charged the service fee."
              },
              "noRefund": {
                "title": "Invalidate without refund",
                "description": "Only invalidate the tickets, keep the original paid amount in the reports."
              }
            },
            "ticketBuyer": "buyer",
            "noGender": "No gender",
            "noAge": "No age",
            "noZipcode": "No zipcode",
            "noCountry": "No country",
            "noPhoneNumber": "No phone number",
            "noEmail": "No email",
            "noWalletLink": "No wallet link",
            "ticketOwner": "Owns tickets",
            "activeGroupMember": "Group member",
            "inactiveGroupMember": "Has left group",
            "customerTickets": "Tickets",
            "customerOrders": "Orders",
            "customerPayments": "Payments",
            "customerRefunds": "Refunds",
            "groupTickets": "Group tickets",
            "share": {
              "action": "Ticketgroup: Share tickets",
              "label": "Share all tickets with:",
              "description": "Existing or new account",
              "newUser": "New account",
              "anonymousUser": "Anonymous account",
              "alert": {
                "title": "Ticketgroup: Share tickets",
                "description": "Are you sure you want to share all tickets with <strong>{from} and <strong>{to}</strong>?",
                "newUserNotice": "A new account will be created for the new member.",
                "userNotice": "Tickets will be shared with existing account of: <strong>{name}</strong>."
              }
            },
            "remove": {
              "title": "Delete Ticket | Delete Tickets",
              "description": "Are you sure that you want to delete this ticket? | Are you sure that you want to delete these tickets?"
            },
            "checkin": {
              "title": "Check-in",
              "description": "Are you sure that you want to check-in these privileges?",
              "combiTickets": "Confirm which privilege(s) you want to check-in:"
            },
            "undoCheckin": {
              "title": "Undo check-in",
              "description": "Are you sure that you want to undo check-ins for these privileges?",
              "combiTickets": "Confirm which privilege(s) you want to undo check-in for:"
            },
            "email": {
              "title": "Email Attendee",
              "error": "This email is not valid",
              "sendEmails": "Send Emails",
              "orderEmail": "Payment confirmation",
              "seatingEmail": "Seating details"
            },
            "convert": {
              "selectOption": "Select conversion options",
              "convertTo": "Convert to",
              "selectPool": "Select which availability you want to use",
              "selectKind": "Select ticket",
              "selectShop": "Select shop",
              "left": "left: {amount}",
              "paymentMethod": "Money received by",
              "paymentOptions": "{method} or {free}",
              "sendEmail": "Send change confirmation email to customer",
              "selectPayment": "Payment method",
              "selectPaymentError": "Select payment method",
              "total": "Total price difference:",
              "new": "Create new",
              "confirmConversion": "Confirm conversion"
            },
            "ticketDetails": {
              "id": "ID",
              "price": "Price",
              "order": "Order",
              "orderDate": "Order date",
              "shop": "Shop",
              "ownedBy": "Owned by",
              "status": "Status",
              "resoldBy": "Resold by",
              "on": "On",
              "onDate": "on {date}",
              "sellerPrice": "Seller Price",
              "buyerPrice": "Buyer Price",
              "buyerFees": "Buyer Fees",
              "sellerFee": "Seller Fees",
              "invalidatedBy": "Invalidated by",
              "refundMethod": {
                "cash": "Cash",
                "pin": "Pin",
                "digital": "Digital",
                "other": "Other"
              },
              "fees": "Fees",
              "payOut": "Payout",
              "resoldTo": "Resold to",
              "ticketId": "Ticket ID",
              "putForSaleBy": "Put for sale by",
              "privileges": "Privileges",
              "boughtBy": "Bought by",
              "boughtFrom": "Bought from",
              "note": "Note"
            }
          }
        },
        "seating": {
          "legend": "Legend",
          "sectionList": "Sections",
          "editNote": "Edit notes",
          "standingSection": "Standing section",
          "capacity": "Capacity",
          "overview": "Overview",
          "notePlaceholder": "Add note...",
          "notEnoughFree": "Not enough free",
          "changes": "Changes",
          "seatClaimed": "Checked-in tickets cannot be edited",
          "categoryInfo": "First select a category from the list below, if you wish to assign seats to a specific block category. If you don't, your selected seats will be uncategorised.",
          "assign": {
            "title": "Assign attendees to seats",
            "showAssigned": "Show assigned",
            "allTicketTypes": "All pools",
            "onlyMultipleTicketTypes": "Combination multiple pools"
          },
          "solve": {
            "title": "Autoseat all attendees",
            "description": "With autoseat, all attendees will be automatically placed on the best seats available for that group-size within each rank. Want to group certain tickets-holders together? You can optionally enter a group of up to 3 account phonenumbers.",
            "groupPlaceholder": "Phonenumber",
            "addGroup": "Add group",
            "success": "Successfully solved!"
          },
          "finalize": {
            "title": "Finalize current seating",
            "description": "Are you sure? Finalizing can not be undone, all attendees will receive their seating information through email and sms.",
            "instructions": "Type {0} in the field below to confirm:",
            "success": "Successfully finalized!"
          },
          "clearSuccess": "Successfully cleared!"
        },
        "scanning": {
          "actions": {
            "create": "Create profile",
            "edit": "Edit profile",
            "cannotEdit": "This profile cannot be edited"
          },
          "creation": {
            "description": "Create a profile which, when loaded in the scanner app, will filter and only show tickets that use the specified pools."
          },
          "description": "{poolAmount} pool can be scanned with this scanning profile. Scan the following QR code to log in. | {poolAmount} pools can be scanned with this scanning profile. Scan the following QR code to log in.",
          "mainProfile": {
            "title": "Main",
            "description": "This is the main profile which allows scanning of all pools. Scan the following QR code to log in.",
            "warning": "Attention! This QR code only allows scanning of this specific event. If you want to be able to scan all your (future) events {0}."
          }
        },
        "shop": {
          "deleteShopModalTitle": "Delete shop",
          "deleteShopMessage": "Are you sure you want to delete this shop",
          "deleteShopTooltip": "If you wish to delete the shop, first remove all the tickets",
          "copyInfo": "All tickets and shop layout will be copied to a new shop. Click Confirm if you wish to continue.",
          "notfound": "Shop not found...",
          "title": "Manage tickets",
          "description": "Add tickets or combi tickets to your event",
          "ticketModalTitle": "Create ticket",
          "vat": "% Tax/VAT",
          "baseTicket": "Base ticket",
          "deleteHelper": "Tickets can only be removed if none has been sold yet.<br>You can always choose to hide a ticket.",
          "newTicket": "New ticket",
          "price": "Price in total",
          "tax": "% Tax/VAT (incl. in price)",
          "breakdown": "Price breakdown",
          "artwork": {
            "toggle": "Collectible Artwork",
            "error": "Your image could not be uploaded. Please try again later.",
            "title": "Upload your Collectible artwork for this ticket which will override any default Collectible artwork set under the event's Blockchain tab.<br><br>You can update this artwork at any point in the event lifecycle.",
            "template": "download template",
            "update": "Update art for NFT's already minted",
            "guidelines": {
              "title": "General guide lines",
              "line_1": "PNG, GIF or JPG filetype",
              "line_2": "Portrait mode with aspect ratio of 3:4",
              "line_3": "10 MB max file size",
              "line_4": "You can use <a href='{href}' download>our template</a> as a base"
            },
            "note": "Note: Unless you upload an image, your event NFT artwork will be used by default."
          },
          "availabilitySettings": "Availability settings",
          "advanced": "Advanced settings",
          "maxTicketsLabel": "Ticket limit",
          "putForSale": "Put ticket up for sale",
          "from": "Available from",
          "till": "Available until",
          "defaultShopTooltip": "Use this shop by default when a user shares a link to this event.",
          "nameInfo": "Used in dashboard only",
          "ticketLimitInfo": "Enter the maximum number of tickets that can be purchased by one person in the shop",
          "advancedEditPoolsLabel": "Set the number of privileges the user receives with this ticket (max 20)",
          "maxAmount": "Max amount",
          "pages": {
            "deletePageModalTitle": "Delete page",
            "deletePageMessage": "All the tickets in this page will be moved to the first page. Are you sure you want to delete this page?",
            "actions": {
              "moveToPage": "Move to page",
              "create": "Create page",
              "edit": "Edit page"
            }
          },
          "access": {
            "title": "Links & embed codes for shop - {name}",
            "linksTitle": "This shop is accessible through the following gates & links:",
            "description": "Gates can hold multiple shops across different events for the user to pick from. By default each shop has it's own single gate (link).",
            "draftAlert": "This event is not published yet. The gate(s) won't show this event until it's published.",
            "singleGate": "Single shop gate"
          },
          "availability": {
            "title": "Availability",
            "description": "By default tickets are available <strong>{from}</strong> until <strong>{ends}</strong>. The maximum number that can be ordered in this shop is <strong>{amount}</strong> tickets per account.",
            "immediately": "immediately",
            "eventEnds": "the event ends",
            "relative": "{days} days before the event ends, at {time}",
            "absoluteStart": "from {date}",
            "startLabel": "Shop opens",
            "endLabel": "Shop closes"
          },
          "accessControl": {
            "title": "Access to Shop",
            "description": "Access to the shop will be provided once a collectible out of the selected collection(s) is found in the customer's wallet. Select the OpenSea collection(s) below that can be used to grant access to the shop.",
            "linkLabel": "Link to OpenSea collection",
            "urlPlaceholder":"opensea.io/...",
            "collectionsListLabel":"Collections selected",
            "errors":{
              "collectionNotFound":"The collection was not found.",
              "invalidUrl": "This is not a valid OpenSea collection URL.",
              "duplicateUrl": "This collection is already added.",
              "wrongChain": "The chain where this collection is deployed is not supported. Currently, we only support Ethereum Mainnet, Polygon Mainnet and Polygon Mumbai Testnet.",
              "openseaError": "An error occurred while fetching the collection. Please try again later.",
              "invalidCollection": "This collection doesn't have an NFT contract.",
              "noCollections": "No collections selected, please add at least one collection."
            }
          },
          "empty": {
            "action": "Create first ticket",
            "title": "Create tickets"
          },
          "actions": {
            "createShop": "Create shop",
            "copyShop": "Copy shop",
            "newShop": "New shop",
            "editShop": "Edit shop",
            "createGroup": "Create group",
            "editGroup": "Edit group",
            "createNewPool": "Create pool",
            "addTicket": "Create ticket",
            "addRegular": "Ticket",
            "addCombi": "Combi-ticket",
            "addUpsell": "Upsell",
            "addGuestVariant": "Guestlist",
            "addGroup": "Create group",
            "saveLayout": "Save layout",
            "saveDraft": "Save as draft",
            "addField": "Add new line",
            "saveTemplate": "Save template"
          },
          "group": {
            "ticketPlaceholder": "Drag a ticket here to create group",
            "collapseCheckbox": "Collapse by default",
            "messagePlaceholder": "Group message to show on ticket list...",
            "defaultTitle": "New group"
          },
          "blocksHelper": {
            "title": "Want to edit the number of blocks? {0}",
            "link": "Go to block categories"
          },
          "gates": {
            "title": "This shop is accessible through the following gates (links)",
            "description": "Gates can hold multiple shops across different events for the user to pick from. By default, each has its own (single) gate.",
            "localGate": "This shop",
            "defaultGateError": "Each shop is required to have a default gate. Please set a default gate by clicking on one of the stars.",
            "defaultTooltip": "Use this gate by default when linking to this shop"
          }
        },
        "checkin": {
          "title": "Self check-in",
          "description": "Scan the QR code to check-in attendees",
          "waiting": "Waiting for QR code...",
          "AllClaimed": "All claimed",
          "checkin": "Check-in {amount} ticket(s)",
          "success": "{amount} ticket(s) checked in succesfully!",
          "errors": {
            "noPrivileges": "This QR code does not contains tickets",
            "wrongEvent": "The QR code belongs to a different event"
          }
        },
        "pools": {
          "title": "Pools",
          "addNew": "Create pool",
          "notFound": "Pool not found...",
          "poolSelection": "Select a pool or multiple pools to create a ticket. For upsell products, use the upsell pools. If you don't find the pool you are looking for, you can",
          "createNewPool": "create a new one.",
          "guestTicket": "This is a guest ticket",
          "toolTipEdititon": "This pool can't be edited",
          "poolModal": {
            "ticketPrivilege": "ticket-privileges",
            "privilege": "privilege",
            "entrance": "Entrance",
            "upsell-text": "Upsell",
            "create": "Create privilege pool",
            "description": "A privilege pool is used to determine the total number of so called {ticketPrivileges} (or {privilege} in short), that can be issued. A privilege is of the type {Entrance} or {Upsell}. 1x capacity equals 1 privilege.",
            "update": "Edit pool",
            "name": "Pool Name",
            "validFrom": "Valid to be scanned from",
            "validTo": "Valid to be scanned until",
            "blocks": "Blocks (subtracted from total)",
            "totalAmoutOfTickets": "Total capacity",
            "blockHelperText": "You can always edit the number of blocks and categories later.",
            "poolNameHelperText": "For instance 'VIP' or 'T-Shirt'",
            "totalCapacityHelperText": "Maximum capacity to be issued",
            "upsell": "Use this for upsell products. Ticket availability is not affected by upsell products.",
            "deleteMessage": "Are you sure you want to delete this pool?",
            "deleteTitle": "Delete",
            "privilegeType": "Privilege type",
            "tellMeMore": "Tell me more",
            "listItem1": "Use to grant access to an event (or livestream)",
            "listItem2": "Total amount of tickets a user can get of this type can be limited per shop ",
            "listItem3": "Use for additional services/products (like drinks/merchandise)",
            "listItem4": "Does not count towards the users' amount of tickets limited per shop"
          },
          "details": {
            "sold": "Sold",
            "tickets": "Tickets",
            "shop": "Shop",
            "price": "Price"
          }
        }
      }
    },
    "reservations": {
      "addTickets": "Add tickets",
      "ticketsCount": "Ticket ({count}) | Tickets ({count})",
      "unclaimedTicketCount": "Unclaimed ({count})",
      "claimedTicketCount": "Claimed ({count})",
      "reservationsOverviewTitle": "Your reservations",
      "draftConfirmInfo": "Publish the event to enable confirming",
      "details": {
        "editTitle": "Edit reservation details"
      },
      "cancel": {
        "cancelTitle": "Cancel reservation",
        "cancelDescription": "Are you sure you want to cancel the reservation? This cannot be undone.",
        "yesCancelReservation": "Yes, cancel reservation",
        "blockTickets": "Block tickets that are available again",
        "blockTicketsDescription": "If you don't select this option, tickets will be up for sale again"
      },
      "confirm": {
        "title": "Confirm tickets",
        "descriptions": {
          "paidConfirmAll": "Are you sure you want to confirm the sale of all tickets in this reservation? Once confirmed, you'll be able to distribute the tickets. Please specify the payment method ({amount}):",
          "freeConfirmAll": "Are you sure you want to confirm the sale of all tickets in this reservation? Once confirmed, you'll be able to distribute the tickets.",
          "paidConfirmPartial": "Are you sure you want to confirm the sale of the selected tickets in this reservation? Once confirmed, you'll be able to distribute the tickets. Please specify the payment method ({amount}):",
          "freeConfirmPartial": "Are you sure you want to confirm the sale of the selected in this reservation? Once confirmed, you'll be able to distribute the tickets."
        }
      },
      "release": {
        "action": "Release",
        "title": "Release tickets",
        "description": "Are you sure you want to release the selected tickets from the reservation?"
      },
      "distributionModal": {
        "title": "Distribute tickets ({count})",
        "description": "Fill the email addresses of people to whom you want to distribute the selected tickets.",
        "allToOne": "All tickets to one person",
        "invitationEmail": "Invitation email address",
        "confirmText": "Send invitations"
      },
      "distribution": {
        "title": "Distribute Tickets",
        "emails": {
          "title": "Distribute tickets to the following email addresses",
          "info": "You can also enter multiple entries separated by a space, comma, or semi-colon.",
          "duplicates": {
            "title": "Duplicates",
            "message": "The following duplicate emails were not added to the list: <strong>{list}</strong>"
          },
          "errors": {
            "exceedsMax":"Too many recipients. Please select fewer recipients or reduce the number of tickets per person",
            "validation": "One or more added email addresses are not valid, please correct them!"
          },
          "entriesLeft": "{count} entries left"
        },
        "tickets": {
          "title": "Each entry will receive the following tickets",
          "ticketLeft": "You have {count} ticket | You have {count} tickets"
        },
        "send": {
          "title": "Send invitations",
          "summary": {
            "title": "Summary",
            "description": "Each invitation contains the following ticket(s):"
          },
          "extraMessage": {
            "title": "Personal message",
            "description": "If you want you can add a short extra message to the ticket invitation email."
          },
          "sendAction": "Send invitations",
          "success": "Successfully created and sent out invitations!",
          "errors": {
            "create": "Failed to create invitations!",
            "send": "Failed to send invitations"
          }
        },
        "resend": {
          "title": "Resend invitation",
          "areYouSure": "Are you sure want to resend this invite?",
          "description": "We'll send another invitation to \"{email}\"",
          "error": "Failed to send invitation!",
          "success": "Succesfully sent out invitation!"
        },
        "revoke": {
          "title": "Revoke invitation",
          "areYouSure": "Are you sure want to revoke this invite?",
          "description": "After revoking, the tickets will be available again in the reservation to distribute.",
          "error": "Failed to revoke invitation!",
          "success": "Succesfully revoked invitation!"
        }
      }
    },
    "scanning": {
      "selectOrganisation": "Select an organisation to be able to see the scanning login information",
      "selectOrganisationMessage": "Select an organisation",
      "organisations": "Organisations",
      "scannerInfo": "Use the information below to login to the scanner. Keep in mind, this login information is shared across the organisation and it is not specific to an event.",
      "downloadInfo": "Download the GET In app on your smartphone:",
      "passwordInfo": "Use the password or scan the QR code to login",
      "noOrganisationSelected": "You haven't selected an organisation yet."
    },
    "navigation": {
      "eventPreview": {
        "label": "Preview draft event",
        "title": "Preview of your event",
        "content": "Take a test drive! View your event and test your shop to make sure everything is set up correctly.<br><br>This preview is exactly how your visitors will experience the flow (with just one exception: there is no payment step in the preview mode, so you can experience the buying process without making actual purchases).<br><br>Buying tickets in preview modus won’t affect any real sales and statistics."
      },
      "eventUrlInfo": "Your event will be available on this url after being published",
      "back": "Back to all my events"
    },
    "prices": {
      "title": "Your prices",
      "addPrice": "Create price type",
      "editPrice": "Edit price",
      "editOverviewDescription": "The following events and tickets share this same price type. Editing this price type, will affect all of them. If you wish to continue click confirm.",
      "table": {
        "name": "Price type name",
        "amount": "Amount",
        "usedBy": "Used by"
      },
      "priceSelect": {
        "noAvailable": "There are no global price types available for this organisation."
      }
    },
    "gates": {
      "title": "Your gates",
      "open": "Open date",
      "close": "Close date",
      "slug": "Slug",
      "create": "Create gate",
      "gate": {
        "description": "This gate is accessible when the first shop opens <strong>{start}</strong> until the last event in this gate ends<strong>{ends}</strong>.",
        "descriptionPrequeue": "Prequeue is <strong>enabled</strong>.",
        "descriptionAccess": "Access links are <strong>enabled</strong>.",
        "gateLinks": {
          "title": "Link & embed codes of gate",
          "linkInfo": "Always use this link when sharing the URL - Do not copy it from the browser bar.",
          "link": "Direct link to this gate",
          "embed": "Embed code of this gate"
        },
        "accessLinks": {
          "nav": "Access links",
          "title": "Generate access links",
          "description": "Fill in the amount of access links you want and click Export as csv. The links below are <strong>example</strong> links.",
          "action": "Export as csv"
        }
      },
      "gateSelect": {
        "noAvailable": "There are no gates available for this organisation. You can create a new one."
      },
      "shops": {
        "pastLabel": "Show past events",
        "searchPlaceholder": "Search for events or shops...",
        "nonGateShopsTitle": "All events & shops",
        "gateShopsTitle": "Shops in this gate ({count})",
        "addToGate": "Add ({count}) shop | Add ({count}) shops",
        "removeFromGate": "Remove ({count}) shop | Remove ({count}) shops",
        "selectInfo": "To select multiple shops, you can press shift or control (command on Mac) and select, or click and drag."
      },
      "modal": {
        "create": "Create gate",
        "edit": "Edit gate",
        "optionalSlug": "Slug (optional)",
        "nameDescription": "This name is only used in dashboard as an identifier",
        "delete": {
          "title": "Delete gate",
          "description": "Are you sure you want to delete this gate? If yes, click confirm."
        },
        "display": {
          "title": "Display",
          "description": "Select how you want to display your events:",
          "eventList": "Show events in list",
          "calendar": "Show events in calendar",
          "override": "Override event information",
          "overrideDescription": "By default, this information is set automatically based on the events added to this gate."
        },
        "access": {
          "title": "Access",
          "accessLinks": "Requires access links",
          "accessLinksDescription": "This will make this gate accessible only for people with an access-link",
          "prequeue": "Enable prequeue",
          "prequeueDescription": "This allows users to register in the queue before the sale starts"
        }
      }
    },
    "templates": {
      "title": "Your templates",
      "createTemplate": "Create template",
      "selectTemplate": "Select template",
      "noAvailable": "No template available",
      "templateNotFound": "Template not found...",
      "status": {
        "syncing": "Syncing...",
        "unsynced": "Out of sync",
        "synced": "All synced"
      },
      "sync": {
        "title": "Sync template changes to upcoming events",
        "description": "You made changes to this template. In order to update all events using this template, you can sync your changes here. Keep in mind <b>all changes</b> made will be applied to <b>all upcoming events</b> created using this template.",
        "action": "Sync changes",
        "syncNow": "Sync now",
        "changesApplied": "Your changes will be applied to these {0} upcoming events",
        "changesApplying": "All {0} upcoming events are in sync!",
        "eventsSyncing": "Syncing of events in progress...",
        "SyncStarted": "Sync started on {date}",
        "lastSync": "Last synced on {date}",
        "errors": {
          "nothingToSync": "This template is already all synced",
          "beingSynced": "This template is already being synced"
        },
        "warningModal": {
          "description": "All changes in this template with be synced to all upcoming events managed by this template. There are a few rules that might be applied to avoid any errors:",
          "lowerCapacity": "If you have lowered the capacity of pools but event(s) already sold more tickets than the lowered capacity then the pool size for that specific event will be set to what is currently sold. For example: capacity changed from 100 to 50 - event already sold 60 - pool size for that specific event will be set to 60.",
          "removeTickets": "When you have removed tickets that are already sold for specific events, those tickets will not be removed, but hidden (in shops) instead.",
          "removeShop": "When you have removed a shop but specific events still have tickets in that specific shop then that shop will not be removed from that event but closed instead."
        }
      },
      "templateAlert": "Keep in mind! If you wish to make changes, all events related to this template will be updated, too.",
      "createFromTemplate": {
        "title": "Create event from template",
        "createInfo": "All template settings will be copied to this event.",
        "templateInfo": {
          "text": "You can create a new template {link}.",
          "link": "here"
        },
        "timeInfo": "Local time: {timezone}, {time}. The time of the event is configured from the template.",
        "errors": {
          "templateBeingSynced": "You cannot create an event from this template because it's currently being synced.",
          "templateNotSynced": "You cannot create an event from this template because it' not synced."
        }
      }
    },
    "schedules": {
      "title": "Your schedules",
      "create": {
        "title": "Create schedule"
      },
      "schedulePatterns": {
        "description": "The following templates will be used to generate multiple events for the period you specify.",
        "action": "Add template",
        "days": "Days",
        "exceptions": "Exceptions",
        "modal": {
          "addTemplate": "Select the template to add to the schedule",
          "edit": "Edit schedule pattern",
          "pattern": "On which days of the week do the events occur?",
          "exception": "Add exception date (optional)",
          "addException": "Add exception"
        }
      },
      "entries": {
        "startDate": "Startdate",
        "endDate": "Enddate",
        "description": "Generate new events from the templates by specifying the first and last day. Exception dates will be skipped.",
        "generate": "Generate events",
        "syncWarning": "Events can't be generated because one or more templates are out of sync! Please sync your templates first before generating new events.",
        "newItemStatus": "<strong>{patternName}</strong> events will be generated",
        "confirmDescription": "The following events will be generated and published:",
        "confirmContinue": "If you wish to continue click confirm.",
        "rangeAlert": "You can only generate maximum 1 year ahead at once. The enddate should be before {maxDate}.",
        "countAlert": "You are about to create {count} new events at once. Are you sure?",
        "tooltip": {
          "existing": "Existing",
          "generate": "To generate"
        }
      }
    },
    "cashier": {
      "inDraft": "This event is not published yet",
      "inDraftInfo": "You can only create reservations in draft events. Issuing tickets directly through the cashier or confirming/distributing reserved tickets can only be done once the event is published. If you want to get full access to the features, {0}.",
      "inDraftInfoCallToAction": "publish this event",
      "filters": {
        "available": "Available",
        "blocks": "Blocks",
        "fromBlocks": "From blocks",
        "price": "Price",
        "type": "Type",
        "ticket": "Ticket",
        "upsell": "Upsell",
        "shop": "Shop",
        "pool": "Pool",
        "lockedShop": "You can only select tickets from {shopName} shop. To select a different shop you will have to empty your cart first.",
        "lockedShopReservation": "You can only select tickets from {shopName} shop. to select a different shop you will have to create additional reservation."
      },
      "abortAction": {
        "title": "Cancel order",
        "description": "Are you sure you want to cancel this order? All tickets in the cart will be removed and you will not be able to undo this action.",
        "confirm": "Yes, cancel this order"
      },
      "removeTickets": {
        "title": "Remove {ticketName} tickets",
        "description": "How many {ticketName} tickets do you want to remove?",
        "max": "max"
      },
      "removeSeatedTickets": "Which tickets do you want to remove?",
      "removeAll": {
        "title": "Remove all",
        "description": "Do you want to remove all tickets from the cart?",
        "confirm": "Remove all"
      },
      "removeOne": {
        "title": "Remove ticket",
        "description": "Are you sure you want to remove this ticket?",
        "confirm": "Remove ticket"
      },
      "confirmationModal": {
        "titleOrder": "Create order",
        "titleReservation": "Create reservation",
        "description": {
          "detailsOnly": "Tickets will be linked to the provided phone number and can be accessed by the customer via their ticket wallet. The customer will not receive any confirmation.",
          "detailsAndSMS": "Tickets will be linked to the provided phone number and can be accessed by the customer via their ticket wallet. The customer will receive a confirmation of the purchase via SMS.",
          "detailsAndEmail": "Tickets will be linked to the provided phone number and can be accessed by the customer via their ticket wallet. The customer will receive an email confirmation with the event & ticket information.",
          "detailsSMSAndEmail": "Tickets will be linked to the provided phone number and can be accessed by the customer via their ticket wallet. The customer will receive a confirmation of the purchase via SMS and an email confirmation with the event & ticket information.",
          "onlyEmail": "Tickets are not linked to the customers account because no phone number was provided. If you want to give the customer the tickets, you need to print the order. The customer will not receive any confirmation.",
          "onlySMS": "Tickets will be linked to the provided phone number and can be accessed by the customer via their ticket wallet. The customer will receive a confirmation of the purchase via SMS.",
          "onlyNumber": "Tickets will be linked to the provided phone number and can be accessed by the customer via their ticket wallet. The customer will not receive any confirmation."
        },
        "notifySMS": "Notify via SMS",
        "notifyEmail": "Notify via email",
        "reservation": {
          "name": "Reservation name",
          "note": "Note",
          "expiry": "Expiry"
        }
      },
      "complete": "Complete order"
    }
  },
  "errors": {
    "not-found": {
      "title": "Can’t access this page",
      "description": "You either do not have permission to view this page or it simply does not exist.",
      "contactSupport": "If you believe this to be an error, contact the organisation you trying to access, or {0} for help.",
      "link": "contact support"
    }
  },
  "filter": {
    "filterBy": "Filter by {name}",
    "operators": {
      "e": "Equals to",
      "lte": "Less than or equal to",
      "gte": "Greater than or equal to",
      "lt": "Less than",
      "gt": "Greater than"
    },
    "timeOperators": {
      "last": "Is in the last",
      "equal": "Is equal to",
      "between": "Is between",
      "after": "Is after",
      "onafter": "Is on or after",
      "before": "Is before",
      "onbefore": "Is before or on"
    },
    "times": {
      "days": "Days",
      "weeks": "Weeks",
      "months": "Months",
      "years": "Years"
    },
    "aria": {
      "open": "Open filter menu",
      "close": "Close filter menu",
      "remove": "Remove filter",
      "add": "Add {name} filter",
      "openAdd": "Open add filter menu",
      "clearAll": "Clear all filters"
    },
    "addFilter": "Add filter",
    "clearAll": "Clear all",
    "select": "Select",
    "selectAll": "Select all",
    "between": "And"
  },
  "resaleFeeConfig": {
    "sellerTitle": "Seller fees",
    "buyerTitle": "Buyer fees",
    "fixedTitle": "Fixed fees",
    "fixedLabel": "Fixed fee per ticket",
    "percTitle": "Percent fees",
    "percLabel": "Percent fee per ticket",
    "totalLabel": "Total fee",
    "getFeeLabel": "Base - GET International",
    "partnerFeeLabel": "Ticketeer fee",
    "override": {
      "label": "Ticketeer fee override",
      "confirm": {
        "title": "Override Ticketeer default fees",
        "description": "Are you sure you want to override the resale market fees for this organization? Any changes to the resale market fee plans will only apply for newly published events. Events that are currently on sale will retain the current fee configuration."
      }
    }
  }
}
