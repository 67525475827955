{
  "countries": {
    "selectCountry": "Selecteer land",
    "AF": "Afghanistan",
    "AX": "Åland",
    "AL": "Albanië",
    "DZ": "Algerije",
    "AS": "Amerikaans-Samoa",
    "AD": "Andorra",
    "AO": "Angola",
    "AI": "Anguilla",
    "AQ": "Antarctica",
    "AG": "Antigua en Barbuda",
    "AR": "Argentinië",
    "AM": "Armenië",
    "AW": "Aruba",
    "AU": "Australië",
    "AT": "Oostenrijk",
    "AZ": "Azerbeidzjan",
    "BS": "Bahama's",
    "BH": "Bahrein",
    "BD": "Bangladesh",
    "BB": "Barbados",
    "BY": "Wit-Rusland",
    "BE": "België",
    "BZ": "Belize",
    "BJ": "Benin",
    "BM": "Bermuda",
    "BT": "Bhutan",
    "BO": "Bolivia",
    "BQ": "Caribisch Nederland",
    "BA": "Bosnië en Herzegovina",
    "BW": "Botswana",
    "BV": "Bouveteiland",
    "BR": "Brazilië",
    "IO": "Brits Indische Oceaanterritorium",
    "BN": "Brunei",
    "BG": "Bulgarije",
    "BF": "Burkina Faso",
    "BI": "Burundi",
    "CV": "Kaapverdië",
    "KH": "Cambodja",
    "CM": "Kameroen",
    "CA": "Canada",
    "KY": "Kaaimaneilanden",
    "CF": "Centraal-Afrikaanse Republiek",
    "TD": "Tsjaad",
    "CL": "Chili",
    "CN": "China",
    "CX": "Christmaseiland",
    "CC": "Cocoseilanden",
    "CO": "Colombia",
    "KM": "Comoren",
    "CG": "Congo-Brazzaville",
    "CD": "Congo-Kinshasa",
    "CK": "Cookeilanden",
    "CR": "Costa Rica",
    "CI": "Ivoorkust",
    "HR": "Kroatië",
    "CU": "Cuba",
    "CW": "Curaçao",
    "CY": "Cyprus",
    "CZ": "Tsjechië",
    "DK": "Denemarken",
    "DJ": "Djibouti",
    "DM": "Dominica",
    "DO": "Dominicaanse Republiek",
    "EC": "Ecuador",
    "EG": "Egypte",
    "SV": "El Salvador",
    "GQ": "Equatoriaal-Guinea",
    "ER": "Eritrea",
    "EE": "Estland",
    "ET": "Ethiopië",
    "FK": "Falklandeilanden",
    "FO": "Faeröer",
    "FJ": "Fiji",
    "FI": "Finland",
    "FR": "Frankrijk",
    "GF": "Frans-Guyana",
    "PF": "Frans-Polynesië",
    "TF": "Franse Zuidelijke en Antarctische Gebieden",
    "GA": "Gabon",
    "GM": "Gambia",
    "GE": "Georgië",
    "DE": "Duitsland",
    "GH": "Ghana",
    "GI": "Gibraltar",
    "GR": "Griekenland",
    "GL": "Groenland",
    "GD": "Grenada",
    "GP": "Guadeloupe",
    "GU": "Guam",
    "GT": "Guatemala",
    "GG": "Guernsey",
    "GN": "Guinee",
    "GW": "Guinee-Bissau",
    "GY": "Guyana",
    "HT": "Haïti",
    "HM": "Heard en McDonaldeilanden",
    "VA": "Vaticaanstad",
    "HN": "Honduras",
    "HK": "Hongkong",
    "HU": "Hongarije",
    "IS": "IJsland",
    "IN": "Indië",
    "ID": "Indonesië",
    "IR": "Iran",
    "IQ": "Irak",
    "IE": "Ierland",
    "IM": "Man",
    "IL": "Israël",
    "IT": "Italië",
    "JM": "Jamaica",
    "JP": "Japan",
    "JE": "Jersey",
    "JO": "Jordanië",
    "KZ": "Kazachstan",
    "KE": "Kenia",
    "KI": "Kiribati",
    "KP": "Noord-Korea",
    "KR": "Zuid-Korea",
    "KW": "Koeweit",
    "KG": "Kirgizië",
    "LA": "Laos",
    "LV": "Letland",
    "LB": "Libanon",
    "LS": "Lesotho",
    "LR": "Liberia",
    "LY": "Libië",
    "LI": "Liechtenstein",
    "LT": "Litouwen",
    "LU": "Luxemburg",
    "MO": "Macau",
    "MK": "Macedonië",
    "MG": "Madagaskar",
    "MW": "Malawi",
    "MY": "Maleisië",
    "MV": "Maldiven",
    "ML": "Mali",
    "MT": "Malta",
    "MH": "Marshalleilanden",
    "MQ": "Martinique",
    "MR": "Mauritanië",
    "MU": "Mauritius",
    "YT": "Mayotte",
    "MX": "Mexico",
    "FM": "Micronesia",
    "MD": "Moldavië",
    "MC": "Monaco",
    "MN": "Mongolië",
    "ME": "Montenegro",
    "MS": "Montserrat",
    "MA": "Marokko",
    "MZ": "Mozambique",
    "MM": "Myanmar",
    "NA": "Namibië",
    "NR": "Nauru",
    "NP": "Nepal",
    "NL": "Nederland",
    "NC": "Nieuw-Caledonië",
    "NZ": "Nieuw-Zeeland",
    "NI": "Nicaragua",
    "NE": "Niger",
    "NG": "Nigeria",
    "NU": "Niue",
    "NF": "Norfolk",
    "MP": "Noordelijke Marianen",
    "NO": "Noorwegen",
    "OM": "Oman",
    "PK": "Pakistan",
    "PW": "Palau",
    "PS": "Palestina",
    "PA": "Panama",
    "PG": "Papoea-Nieuw-Guinea",
    "PY": "Paraguay",
    "PE": "Peru",
    "PH": "Filipijnen",
    "PN": "Pitcairneilanden",
    "PL": "Polen",
    "PT": "Portugal",
    "PR": "Puerto Rico",
    "QA": "Qatar",
    "RE": "Réunion",
    "RO": "Roemenië",
    "RU": "Rusland",
    "RW": "Rwanda",
    "BL": "Saint-Barthélemy",
    "SH": "Sint-Helena, Ascension en Tristan da Cunha",
    "KN": "Saint Kitts en Nevis",
    "LC": "Saint Lucia",
    "MF": "Sint-Maarten",
    "PM": "Saint-Pierre en Miquelon",
    "VC": "Saint Vincent en de Grenadines",
    "WS": "Samoa",
    "SM": "San Marino",
    "ST": "Sao Tomé en Principe",
    "SA": "Saoedi-Arabië",
    "SN": "Senegal",
    "RS": "Servië",
    "SC": "Seychellen",
    "SL": "Sierra Leone",
    "SG": "Singapore",
    "SX": "Sint Maarten",
    "SK": "Slowakije",
    "SI": "Slovenië",
    "SB": "Salomonseilanden",
    "SO": "Somalië",
    "ZA": "Zuid-Afrika",
    "GS": "Zuid-Georgia en de Zuidelijke Sandwicheilanden",
    "SS": "Zuid-Soedan",
    "ES": "Spanje",
    "LK": "Sri Lanka",
    "SD": "Soedan",
    "SR": "Suriname",
    "SJ": "Spitsbergen en Jan Mayen",
    "SZ": "Swaziland",
    "SE": "Zweden",
    "CH": "Zwitserland",
    "SY": "Syrië",
    "TW": "Taiwan",
    "TJ": "Tadzjikistan",
    "TZ": "Tanzania",
    "TH": "Thailand",
    "TL": "Oost-Timor",
    "TG": "Togo",
    "TK": "Tokelau",
    "TO": "Tonga",
    "TT": "Trinidad en Tobago",
    "TN": "Tunesië",
    "TR": "Turkije",
    "TM": "Turkmenistan",
    "TC": "Turks- en Caicoseilanden",
    "TV": "Tuvalu",
    "UG": "Oeganda",
    "UA": "Oekraïne",
    "AE": "Verenigde Arabische Emiraten",
    "GB": "Verenigd Koninkrijk",
    "US": "Verenigde Staten",
    "UM": "Kleine afgelegen eilanden van de Verenigde Staten",
    "UY": "Uruguay",
    "UZ": "Oezbekistan",
    "VU": "Vanuatu",
    "VE": "Venezuela",
    "VN": "Vietnam",
    "VG": "Britse Maagdeneilanden",
    "VI": "Amerikaanse Maagdeneilanden",
    "WF": "Wallis en Futuna",
    "EH": "Westelijke Sahara",
    "YE": "Jemen",
    "ZM": "Zambia",
    "ZW": "Zimbabwe"
  },
  "placeholders": {
    "dateTime": "dd-mm-jjjj hh:mm",
    "date": "dd-mm-jjjj",
    "time": "hh:mm",
    "search": "Zoeken...",
    "searchCountryCode": "Zoek jouw landcode",
    "loading": "Laden...",
    "email": "voorbeeld{'@'}email.nl",
    "uploadImagePlaceholder": "Selecteer je afbeelding of sleep deze hierheen.",
    "dropImagePlaceholder": "Sleep je afbeelding hierheen",
    "selectOrganisationMessage": "Selecteer een organisatie",
    "selectGate": "Zoek of selecteer gate...",
    "selectRole": "Selecteer rol",
    "selectLocale": "Selecteer taal",
    "selectTicketeer": "Selecteer ticketeer",
    "phoneNumber": "bijv. +31646662271"
  },
  "intervals": {
    "hour": "Per uur",
    "day": "Per dag",
    "week": "Per week",
    "month": "Per maand",
    "year": "Per jaar"
  },
  "permissions": {
    "noPermissions": "Je hebt niet de juiste permissies",
    "contactAdministrator": "Contacteer een administrator voor de benodigde permissies",
    "insufficientPermissions": "Onjuiste permissies"
  },
  "notifications": {
    "saveSuccess": "Successvol opgeslagen",
    "savePublishSuccess": "Sucessvol opgeslagen en gepubliceerd",
    "removeSuccess": "succesvol verwijderd",
    "saveFail": "Opslaan mislukt",
    "removeFail": "Verwijderen mislukt",
    "cashierCartExpired": "Jouw winkelwagentje is verlopen door inactiviteit. Laad de pagina opnieuw om opnieuw te beginnen.",
    "cartUpdate": {
      "edit": {
        "success": "The cart has been updated successfully.",
        "fail": "Failed to update the cart."
      },
      "add": {
        "success": "De ticket(s) zijn succesvol toegevoegd.",
        "fail": "Fout bij het toevoegen van ticket(s)."
      },
      "remove": {
        "success": "De ticket(s) zijn succesvol verwijderd.",
        "fail": "Kan ticket(s) niet verwijderen."
      }
    },
    "genericError": "Error",
    "successUpdate": "Succesvol geüpdate",
    "successEmail": "E-mail verzonden",
    "successShared": "Ticket gedeeld",
    "successDownload": "Bestand gedownload",
    "successPassword": "Wachtwoord succesvol opnieuw ingesteld",
    "copiedToClipboard": "Gekopieerd naar je klembord!",
    "copyFailed": "Failed to copy to your clipboard",
    "invitationSent": "Uitnodiging verzonden",
    "notEnoughPrivs": "Fout: kan dit aantal tickets niet blokkeren, omdat er niet zoveel beschikbaar zijn. Je kunt proberen het aantal tickets te verhogen",
    "unSavedAlert": {
      "title": "Niet opgeslagen veranderingen",
      "description": "Weet je zeker dat je weg wilt gaan zonder deze veranderingen op te slaan?"
    },
    "TicketKindExists": "Je kunt deze pool niet verwijderen omdat er tickets aan zijn gekoppeld",
    "TicketKindExistsInShop": "Je kunt deze shop niet verwijderen omdat er tickets aan zijn gekoppeld",
    "anonymizeUserSuccess": "Account data is geanonimiseerd.",
    "anonymizeUserFail": "Er is een fout opgetreden tijdens het verwijderen van het account. Waarschijnlijk is de gebruiker bezig met het kopen van een ticket. Probeer het later opnieuw.",
    "feature_flag": "Deze functie is alleen beschikbaar voor gebruikers met de 'Tester' rechten.",
    "tasks": {
      "taskLabel": "Taak: ",
      "stateLabel": "Status:",
      "states": {
        "processing": "Wordt verwerkt",
        "not_started": "Nog niet gestart",
        "failed": "Mislukt",
        "completed": "Voltooid"
      },
      "event_copy": {
        "title": "Kopieer evenement",
        "taskDescription": "Kopiëren {title}",
        "link": "Ga naar nieuwe evenement"
      },
      "shop_copy": {
        "title": "Kopieer shop",
        "taskDescription": "Kopiëren shop {title}"
      },
      "template_generate": {
        "title": "Bezig met genereren evenement",
        "taskDescription": "Genereren vanuit template:",
        "link": "Ga naar het nieuwe evenement"
      },
      "template_copy": {
        "title": "Kopieer template",
        "taskDescription": "Kopiëren template {title}"
      }
    }
  },
  "navigation": {
    "home": "Home",
    "events": "Evenementen",
    "customers": "Klanten",
    "upcoming": "Aanstaande",
    "past": "Archief",
    "draft": "Concepten",
    "reports": "Rapporten",
    "checkIns": "Check-ins",
    "sales": "Verkoop",
    "exports": "Exports",
    "manage": "Beheer",
    "blocks": "Blokkades",
    "general": "Algemeen",
    "eventMarketing": "Marketing",
    "eventAdvanced": "Geavanceerd",
    "eventBlockchain": "Blockchain",
    "resaleFees": "Ticketmarkt servicekosten",
    "scanning": "Scannen",
    "seating": "Zitplaatsen",
    "external": "Extern",
    "demographics": "Demografie",
    "pools": "Pools",
    "shops": "Shops",
    "prices": "Prijzen",
    "gates": "Gates",
    "templates": "Templates",
    "schedules": "Scheduler",
    "sync": "Synchroniseer",
    "organizations": "Organisaties",
    "checkin": "Check-in",
    "orders": "Bestellingen",
    "reservations": "Reservations"
  },
  "actions": {
    "accept": "Accepteer",
    "seeAll": "Toon alle",
    "ok": "OK",
    "close": "Sluiten",
    "logout": "Uitloggen",
    "login": "Inloggen",
    "signup": "Aanmelden",
    "publish": "Publiceren",
    "savePublish": "Opslaan en publiceren",
    "confirm": "Bevestig",
    "confirmAll": "Confirm all",
    "saveChanges": "Wijzigingen opslaan",
    "invalidate": "Invalideren",
    "reset": "Reset",
    "export": "Exporteer",
    "loadMore": "Toon meer",
    "cancel": "Annuleer",
    "search": "Zoeken",
    "copy": "Kopieer",
    "apply": "Opslaan",
    "edit": "Aanpassen",
    "back": "Terug",
    "save": "Opslaan",
    "delete": "Verwijder",
    "preview": "Preview",
    "next": "Volgende",
    "uploadImage": "Upload afbeelding",
    "checkin": "Check-in",
    "undoCheckin": "Undo check-in",
    "showAll": "Toon alles",
    "clear": "Reset",
    "solve": "Autoplaceer",
    "finalize": "Finaliseer",
    "assign": "Toewijzen",
    "printTickets": "Print ticket | Print tickets",
    "printPrivileges": "Print privilege | Print privileges",
    "printAllPrivilegesforCustomer": "Print alle tickets",
    "print": "Print",
    "block": "Blokkeren",
    "unblock": "Vrijgeven",
    "addRow": "Rij toevoegen",
    "email": "E-mailen",
    "sendEmail": "E-mailen",
    "resend": "Resend",
    "revoke": "Revoke",
    "editPhoneNumber": "Wijzig telefoonnummer",
    "deleteAccount": "Account verwijderen",
    "sellTickets": "Verkoop tickets",
    "batchAction": "Batch actie",
    "import": "Importeren",
    "browse": "Blader",
    "selectTimezone": "Selecteer een tijdzone",
    "chooseFile": "Kies een bestand of sleep deze hierheen",
    "linkEmbed": "Link/embed",
    "view": "Bekijk",
    "click": "klik hier",
    "add": "Toevoegen",
    "remove": "Verwijderen",
    "removeAll": "Verwijder alles",
    "change": "Wijzigen",
    "activate": "Activeer",
    "deactivate": "Deactiveren",
    "toDashboard": "Ga naar dashboard",
    "switchOrganization": "Wissel van organisatie",
    "allOrganizations": "Toon alles van al mijn organisaties",
    "goToEvents": "Ga naar mijn evenementen",
    "clearFile": "Verwijder",
    "download": "Downloaden",
    "help": "Hulp",
    "multiNoteTicket": "Meerdere notities. Klik op het ticket voor details.",
    "multiSeatingTicket": "Multiple seatings. Click the ticket for details.",
    "createGroup": "Creëer groep",
    "select": "Selecteer",
    "issue": "Uitgeven",
    "issueNow": "Nu uitgeven",
    "createReservation": "Reservering aanmaken",
    "update": "Update",
    "distributeTickets": "Distribute tickets",
    "editNotes": "Edit notes",
    "addNotes": "Add notes"
  },
  "labels": {
    "en": "Engels",
    "nl": "Nederlands",
    "de": "German",
    "it": "Italiaans",
    "ko": "Koreaans",
    "es": "Spaans",
    "locale": "Taal",
    "yes": "Ja",
    "no": "Nee",
    "name": "Naam",
    "shortName": "Korte naam",
    "date": "Datum",
    "time": "Tijd",
    "id": "ID",
    "checkinOptions": {
      "all": "Alle",
      "blocked": "Geblokkeerd",
      "assigned": "Toegekend"
    },
    "amountSpecifier": "van",
    "ticketeer": "Ticketeer",
    "currentOrg": "Huidige organisatie",
    "events": "Evenement | Evenementen",
    "eventTitle": "Evenement naam | Evenementen naam",
    "order": "Bestelling | Bestellingen",
    "reservation": "Reservation",
    "created": "Aangemaakt",
    "expiry": "Vervaldatum",
    "expired": "Verlopen",
    "claimed": "Claimed",
    "open": "Open",
    "canceled": "Geannuleerd",
    "totalValue": "Total Value",
    "paidValue": "Paid value",
    "completed": "Completed",
    "actions": "Actions",
    "payment": "Betaling | Betalingen",
    "method": "Methode | Methodes",
    "orderId": "Order-ID {id}",
    "paymentId": "Payment-ID {id}",
    "template": "Template | Templates",
    "templateTitle": "Template naam",
    "organization": "Organisatie",
    "section": "Section",
    "enabled": "ingeschakeld",
    "disabled": "uitgeschakeld",
    "state": "Staat",
    "success": "Succesvol!",
    "danger": "Foutmelding",
    "credits": "Credits",
    "summary": "Samenvatting",
    "paymentMethod": "Betaalwijze | Betaalwijzen",
    "pspId": "Psp ID",
    "type": "Type",
    "left": "Over",
    "max": "Max",
    "label": "{options} en {length} meer",
    "notAvailableShort": "N.v.t.",
    "availability": "Beschikbaarheid",
    "privilege": "Privilege | Privileges",
    "category": "Categorie | Categorieën",
    "yourOrders": "Jouw bestellingen",
    "yourCustomers": "Jouw klanten",
    "yourShops": "Jouw shops",
    "contactName": "Contact name",
    "contactEmail": "Contact email",
    "contactPhone": "Contact phone",
    "email": "Email address",
    "createdOn": "Created on",
    "createdBy": "Created by",
    "yourReservations": "Your reservations",
    "reservationId": "Reservation-ID {id}",
    "invitations": "Invitation | Invitations",
    "recipient": "Recipient",
    "claimedBy": "Claimed by",
    "expirationDate": "Expiration date",
    "customMessageContent": "Custom message content",
    "dates": {
      "today": "Vandaag",
      "yesterday": "Gisteren",
      "last7Days": "Laatste 7 dagen",
      "last4Weeks": "Laatste 4 weken",
      "thisMonth": "Deze maand",
      "thisYear": "Dit jaar",
      "custom": "Periode",
      "allTime": "Alle tijden",
      "endDate": "Einddatum",
      "startDate": "Startdatum",
      "fromDate": "Vanaf datum",
      "untilDate": "Tot en met datum",
      "years": "jaar | jaren",
      "am": "AM",
      "pm": "PM",
      "timezone": "Tijdzone",
      "validFrom": "Geldig vanaf",
      "validUntil": "Geldig tot",
      "relativeTime": "Relative time",
      "dateOptions": {
        "dateAndTime": "Datum & tijd",
        "specificDate": "specifieke dag en tijd",
        "whenShopOpens": "wanneer de shop opent",
        "relativeDateEnds": "relatieve tijd voordat het evenement eindigt",
        "relativeDateStarts": " relatieve tijd voordat het evenement start",
        "whenEventEnds": "wanneer het evenement eindigt",
        "whenDoorsOpen": "wanneer de deuren openen",
        "doorsOpenWarning": "Je event begint op {eventDateTime}. De QR code van de tickets worden pas 3 uur voor het scannen beschikbaar voor de klanten in de app.",
        "immediately": "Direct",
        "absoluteTimeWarning": "Let op: aangemaakte evenementen uit een template of gekopieerde evenementen gebruiken deze specifieke datum, zelfs als de nieuw aangemaakte evenementdatum anders is.",
        "relativeTimeWarningStart": {
          "cta": "Voer het aantal dagen in voorafgaand aan de start van evenement en de tijd op die dag om deze relatieve datum te gebruiken.",
          "warning": "Als het evenement op {eventStarts} afloopt, is de werkelijke datum {selectedDateTime}"
        },
        "relativeTimeWarningEnd": {
          "cta": "Voer het aantal dagen in voorafgaand aan het einde van evenement en de tijd op die dag om deze relatieve datum te gebruiken.",
          "warning": "Als het evenement op {eventEnds} afloopt, is de werkelijke datum {selectedDateTime}"
        },
        "relativeTimeInfo": "Voer het aantal dagen in voorafgaand aan het einde van evenement en de tijd op die dag om deze relatieve datum te gebruiken.",
        "relativeTimeExample": "Als het evenement op {eventEnds} afloopt, is de werkelijke datum {datetime}.",
        "ticketRelativeTimeFromInfo": "Stel het aantal dagen voor het einde van het evenement en het tijdstip op die dag in, om het moment in te stellen waarop deze tickets beschikbaar zijn in de shop.",
        "ticketRelativeTimeToInfo": "Stel het aantal dagen voor het einde van het evenement en het tijdstip op die dag in, om het moment in te stellen waarop deze tickets niet meer beschikbaar zijn in de shop.",
        "daysBeforeEventEnds": "Dagen voordat het evenement eindigt",
        "daysBeforeEventStarts": "Dagen voordat het evenement start",
        "dayTime": "Tijd op die dag",
        "never": "Nooit"
      }
    },
    "profile": {
      "anonymous": "Anoniem",
      "firstName": "Voornaam",
      "lastName": "Achternaam",
      "phoneNumber": "Telefoonnummer",
      "mobileNumber": "Mobiel nummer",
      "email": "E-mailadres",
      "language": "Taal",
      "confirmEmail": "Bevestig e-mailadres",
      "zipcode": "Postcode",
      "birthdate": "Geboortedatum",
      "gender": "Geslacht",
      "ageGroups": "Leeftijdsgroepen",
      "role": "Rol",
      "m": "Man",
      "f": "Vrouw",
      "o": "Anders",
      "lastPurchase": "Laatste aankoop",
      "contact": "Contactgegevens",
      "addressInformation": "Adresgegevens",
      "basicInformation": "Basisgegevens",
      "address": "Straatnaam & huisnummer",
      "addressShort": "Adres",
      "city": "Woonplaats",
      "country": "Land",
      "state": "Staat",
      "nft_wallet_address": "NFT wallet adres",
      "gusuuid": "GUS uuid",
      "publicTicketEngineUrl": "Wallet link"
    },
    "event": {
      "shortName": "Korte naam",
      "seated": "Geplaceerd",
      "nonSeated": "Niet geplaceerd",
      "livestream": "Livestream",
      "title": "Titel",
      "subtitle": "Subtitel",
      "location": "Locatie",
      "sublocation": "Sublocatie (zaal)",
      "city": "Stad",
      "country": "Land",
      "address": "Adres",
      "datesTitle": "Datum & Tijd",
      "doorsOpen": "Openingstijd",
      "startDate": "Datum waarop het evenement start",
      "daysRunning": "Duur in dagen",
      "when": "Starttijd",
      "ends": "Eindtijd op de laatste dag",
      "endDateTime": "Einddatum en -tijd",
      "saleStart": "Startdatum verkoop",
      "saleEnds": "Einddatum verkoop",
      "url": "URL van het evenement",
      "slug": "Slug",
      "draft": "Concept",
      "timezone": "Tijdzone waar dit evenement plaatsvindt",
      "capacity": "Capaciteit"
    },
    "ticketStatus": {
      "sold": "Toegewezen",
      "returned": "Teruggegeven",
      "resold": "Doorverkocht",
      "forsale": "Te koop",
      "being-resold": "Te koop en in winkelmand",
      "created": "Aangemaakt",
      "invalidated": "Geïnvalideerd",
      "executed": "Gebruikt",
      "partialCheckedIn": "Gebruikt",
      "inCart": "Winkelmand",
      "notForSale": "Niet te koop",
      "notExecuted": "Niet ingecheckt",
      "unassigned": "Niet toegekend",
      "blocked": "Geblokkeerd",
      "blocks": "Blokkades",
      "assigned": "Toegekend",
      "external": "Extern",
      "free": "Beschikbaar",
      "released": "Vrijgegeven",
      "transferred": "Reservation Claimed"
    },
    "orderStatus": {
      "all": "Alle",
      "created": "Aangemaakt",
      "pending": "Openstaand",
      "canceled": "Geannuleerd",
      "timeout": "Time-out",
      "completed": "Geslaagd",
      "failed": "Mislukt"
    },
    "reservationStatus": {
      "label": "Status",
      "all": "All",
      "confirmed": "Confirmed",
      "distribution_started": "Distribution started",
      "awaiting_confirmation": "Awaiting confirmation",
      "awaiting_distribution": "Awaiting distribution",
      "invalidated": "Invalidated",
      "checked_in": "Checked-in",
      "partially_checked_in": "Checked-in",
      "invited": "Invited",
      "expired": "Expired",
      "canceled": "Canceled",
      "completed": "Completed"
    },
    "reservationInvitationStatus": {
      "label": "Status",
      "invited": "Invited",
      "claimed": "Accepted",
      "revoked": "Revoked",
      "created": "Created"
    },
    "paymentStatus": {
      "pending": "Openstaand",
      "paid": "Betaald",
      "duplicated": "Gedupliceerd",
      "expired": "Verlopen",
      "failed": "Mislukt",
      "canceled": "Geannuleerd"
    },
    "refundStatus": {
      "pending": "Openstaand",
      "success": "Succes",
      "failed": "Mislukt"
    },
    "orderType": {
      "normal": "Online",
      "cashier": "Kassa",
      "reservation": "Reservation",
      "claimed": "Claimed"
    },
    "seat": {
      "rows": "Rij | Rijen",
      "rowsAbbreviation": "Rij",
      "sections": "Sectie | Secties",
      "sectionsAbbreviation": "Sec",
      "subsections": "Subsectie | Subsecties",
      "subsectionsAbbreviation": "Sub-s",
      "entrances": "Ingang | Ingang",
      "entrancesAbbreviation": "Ing",
      "ranks": "Rang | Rang",
      "tiers": "Rang | Rangen",
      "seats": "Stoel | Stoelen",
      "seatsAbbreviation": "St",
      "category": "Categorie",
      "cashierSeating": {
        "auto": "Automatisch geplaceerd",
        "manual": "Handmatig geplaceerd",
        "not_applicable": "Not applicable"
      }
    },
    "ticketTypes": {
      "upsell": "Upsell",
      "access": "Entree",
      "checked_in": "Checked-in"
    },
    "ticketType": "Ticket",
    "eventOrganizer": "Organisator",
    "tickets": "Ticket | Tickets",
    "types": "Type | Types",
    "totals": "Totaal | Totaal",
    "total": "Totaal",
    "from": "Van",
    "until": "Tot",
    "description": "Omschrijving",
    "available": "Beschikbaar",
    "forSale": "Te koop",
    "locks": "Blokkade | Blokkade",
    "guests": "Gast | Gasten",
    "upsells": "Upsell | Upsells",
    "variants": "Variant | Varianten",
    "combiTicket": "Combi-ticket",
    "guestlist": "Gastenlijst",
    "price": "Prijs",
    "free": "Gratis",
    "status": "Status",
    "lastStatus": "Laatste status",
    "onDate": "op {date}",
    "groups": "Groep | Groepen",
    "notes": "Notitie | Notitie",
    "sales": "Verkoop",
    "checkIns": "Check-ins",
    "seating": "Zitplaatsen",
    "privileges": "Privileges",
    "accessPrivileges": "Entree privileges | Entree privileges",
    "ownedBy": "Eigendom van",
    "noResults": {
      "default": "Geen resultaten gevonden...",
      "orders": "Geen bestellingen gevonden...",
      "customers": "Geen klanten gevonden..."
    },
    "results": "resultaten",
    "step": "Stap {count}",
    "amount": "Aantal",
    "amountFinance": "Bedrag",
    "refundedAmount": "Refunded amount",
    "amountRefunded": "Terugbetaald bedrag",
    "pricePerTicket": "Prijs per ticket",
    "numberOfTickets": "Aantal tickets",
    "subtotal": "Subtotaal",
    "paidInFiat": "Betaald met {currency}",
    "paidWithCredits": "Betaald met credits",
    "refundedToCredits": "Terugbetaald in credits",
    "dateCreated": "Datum aangemaakt",
    "dateUpdated": "Laatst bijgewerkt",
    "totalDeducted": "Totaal ingehouden",
    "referral_id": "Referral ID",
    "primary": "Primaire",
    "secondary": "Secundaire",
    "unknown": "Onbekend",
    "note": "Notitie",
    "privilegeNote": "Privilege note",
    "ticketNote": "Ticket note",
    "optional": "(optioneel)",
    "mandatory": "(verplicht)",
    "localTime": "Lokale tijd",
    "other": "Anders",
    "pools": "Pools",
    "availableTicketsTitle": "Beschikbare tickets",
    "availableTickets": "Beschikbaar",
    "allocated": "Toegewezen",
    "blocks": "Blokkades",
    "refundType": {
      "name": "Terugbetaalmethode",
      "auto": "Automatisch",
      "manual": "Manueel",
      "credits": "Credits",
      "no_refund": "Geen terugbetaling"
    },
    "shops": "Shop | Shops",
    "details": "Details",
    "priceType": "Prijstype",
    "priceSelect": "Selecteer een prijs",
    "customPrice": "Custom prijs",
    "globalPrice": "Globale prijs",
    "totalPrice": "Totaalprijs",
    "example": "(voorbeeld)",
    "users": "Gebruikers",
    "currency": "Valuta",
    "paidEur": "Betaald met {currency}",
    "paidCredits": "Betaald met credits",
    "refundedEur": "Teruggestort in {currency}",
    "refundedCredits": "Teruggestort in credits",
    "grossRevenue": "Bruto omzet",
    "refunded": "Teruggestort",
    "inCurrency": "In {currency}",
    "toCredits": "Naar credits",
    "market": "Markt",
    "info": "Info",
    "paidWithCreditsPartially": "Gedeeltelijk betaald met credits",
    "mandatoryField": "verplicht veld",
    "collectibles": "Collectibles"
  },
  "validation": {
    "error": {
      "minFileSize": "Je bestand moet groter zijn dan {max}Kb.",
      "minValue": "Waarde moet hoger zijn dan {min}",
      "maxValue": "Waarde moet lager zijn dan {max}",
      "maxDecimals": "Je kunt tot {precision} decimalen gebruiken",
      "validateImageDeminsons": "De resolutie moet hoger zijn dan {width}x{height}",
      "minDate": "De {field} moet na de {minVal} zijn",
      "maxDate": "De {field} tijd moet voor de {maxVal} zijn",
      "minOffset": "De {field} moet voor de {maxVal} zijn",
      "maxOffset": "De {field} moet na {minVal} zijn",
      "required": "Dit veld moet ingevuld zijn",
      "richTextMaxLength": "Mag niet langer dan {maxLength} karakters zijn",
      "cover": "De foto heeft niet de juiste dimensies",
      "maxSize": "De grootte mag niet groter zijn dan {maxSize} kb",
      "fileType": "Het is niet de juiste type bestand",
      "maxLength": "Veld kan niet langer zijn dan {max}",
      "maxCharLength": "Veld kan niet langer zijn dan {max} karakters",
      "combinedMaxLength": "Elk veld mag niet langer zijn dan {max} karakters",
      "decimal": "Het moet een geldig nummer zijn",
      "alphaNum": "Het moet een geldige alfanumerieke waarde hebben",
      "alphaNumDashUnderscore": "Het mag alleen alfanumeriek, onderstrepingsteken en streepje bevatten",
      "numeric": "Het moet een geldige numerieke waarde hebben",
      "phoneNumber": "Dit is is geen geldig mobiel nummer. (Landcode is verplicht, b.v. \"+31\")",
      "sameAsNumber": "De ingevoerde telefoonnummers komen niet overeen, voer nogmaals in.",
      "sameAs": "Het ingevoerde {field} komt niet overeen, voer deze opnieuw in.",
      "url": "Voer een geldige url in (https://example.com).",
      "email": "Voer een geldig e-mailadres in.",
      "integer": "Voer een geheel getal in.",
      "minLength": "Het veld {field} moet minimaal {length} karakters lang zijn.",
      "nonNumeric": "Het veld {field} mag alleen numerieke waarden bevatten.",
      "wrongPass": "Voer het correcte wachtwoord in.",
      "phoneNumberValidation": "Altijd plusteken en landcode (bijv. +31) invoegen!",
      "isNotUnicode": "Zorg ervoor dat je geen unicode-tekens gebruikt"
    }
  },
  "views": {
    "reports": {
      "title": "Jouw exports",
      "nav": {
        "exports": "Exports",
        "sales": "Verkoop"
      },
      "exports": {
        "tabs": {
          "revenue": "Omzet",
          "summary": "Samenvatting",
          "orders": "Bestellingen",
          "attendees": "Bezoekers",
          "blocks": "Blokkades",
          "advanced": "Geavanceerd",
          "groups": "Groepen"
        },
        "type": {
          "group_sizes_report": "Groepsgrootte",
          "revenue_report": "Omzet",
          "summary_report": "Samenvatting",
          "orders_report": "Bestellingen",
          "attendees_report": "Bezoekers",
          "blocks_report": "Blokkades"
        },
        "exportHistory": {
          "title": "Jouw recente exports",
          "created": "Gecreëerd {date}",
          "generating": "Bezig met genereren...",
          "exportGenerationSuccess": "Export succesvol gegenereerd"
        },
        "selectEventsAction": "Selecteer evenementen",
        "action": "Genereer export",
        "reportsGenerationFail": "Genereren van export is mislukt, probeer het nogmaals...",
        "configurationError": "Geen evenementen van jouw organisatie in geselecteerde gate gevonden...",
        "limitError": "\"Je kunt een rapportage genereren voor max. {max} events! Als je grotere exports wilt maken, neem dan contact op met onze support.\".",
        "selectedEvents": "{selectedEvents} evenement geselecteerd | {selectedEvents} evenementen geselecteerd",
        "gateInfo": "Let op: alleen evenementen die behoren tot jouw organisatie in de geselecteerde gate worden meegenomen in de export.",
        "selectOrg": "Selecteer de organisatie waarvoor je een export wilt maken",
        "options": {
          "tickets": "Opties: tickets & upsells",
          "events": "Opties: evenementen & shops"
        },
        "advanced": {
          "eventSales": "Alle Ledger Transacties",
          "secondaryMarket": "Ticket Markt Transacties",
          "billingSummary": "Facturering Samenvatting"
        },
        "attendees": {
          "privacy-policy": "Bezoekers en acceptatie privacybeleid (indien van toepassing)",
          "user-privacy": "Alleen bezoekers die jouw privacybeleid hebben geaccepteerd",
          "brand-privacy": "Alleen bezoekers die het privacybeleid van {brand} hebben geaccepteerd",
          "all": "Alle bezoekers",
          "optional": "Optionele bezoekers",
          "non-owners": "Bezoekers die geen tickets meer hebben (doorverkocht, geïnvalideerd)",
          "invited": "Bezoekers uitgenodigd door ticket-houders in een groep",
          "warning": "Let op: volgens Europese wetgeving mag je de gegevens van de bezoekers die het privacybeleid niet hebben geaccepteerd alleen voor noodzakelijke diensten gebruiken. Dit sluit marketing uit."
        },
        "merge": {
          "pricetype": "Voeg dezelfde prijstypes samen",
          "hideItems": "Verberg items met lege waarden"
        },
        "groupBy": {
          "events": "Groepeer per evenement",
          "shop": "Groepeer per shop"
        },
        "desiredFormat": "Gewenste formaat",
        "format": {
          "xlsx": "Excel (xlsx)",
          "csv": "Komma-gescheiden (csv)"
        },
        "includeEvents": "Voeg de volgende evenementen toe",
        "eventOptions": {
          "all-events": "Alle evenementen",
          "specific-gate": "Evenementen in gate",
          "specific-events": "Selecteer specifieke evenementen"
        },
        "periodInclude": "Binnen periode",
        "periodOptions": {
          "specific-period": "Specifieke periode",
          "all-time": "Alle tijden"
        },
        "selectEventsModal": {
          "title": "Selecteer evenementen die je wilt exporteren",
          "yourEvents": "Evenementen",
          "added": "Toegevoegde evenementen",
          "placeholder": "Zoek evenementen",
          "addEvent": "{count} evenement toevoegen | {count} evenementen toevoegen",
          "removeEvent": "{count} evenement verwijderen | {count} evenementen verwijderen",
          "info": "Om meerdere evenementen te selecteren kun je shift of control (commando op Mac) ingedrukt houden en selecteren of klikken en slepen."
        }
      }
    },
    "organizations": {
      "organization": {
        "title": "Organisatie",
        "name": "Organisation Identity",
        "legalName": "Bedrijfsnaam (optioneel)",
        "legalNameDescription": "De officiële naam van jouw organisatie",
        "privacyPolicy": "Privacyverklaring (optioneel voor de bezoekers)",
        "privacyPolicyFileDescription": "Overschrijf de standaardtekst voor de opt-in op jouw privacybeleid",
        "privacyPolicyPlaceholder": "Ik wil op de hoogte gehouden worden van evenementen en acties en ga akkoord met de privacyverklaring van {orgName}.",
        "termsConditions": "Algemene voorwaarden (verplicht voor de bezoeker)",
        "termsConditionsFileDescription": "Overschrijf de standaardtekst voor de opt-in op jouw algemene voorwaarden",
        "termsConditionsPlaceHolder": "Ik ga akkoord met de algemene voorwaarden van {orgName}.",
        "localeSelectPlaceholder": "Selecteer de standaard taal voor e-mails etc.",
        "pspSelectPlaceholder": "Selecteer een payment service provider.",
        "demo": {
          "label": "Demo modus",
          "description": "Creëer organisatie voor uitsluitend demo-doeleinden",
          "info": "Een demo organisatie is gelimiteerd tot dummy betalingen en kan niet gebruikt worden voor echte ticketverkoop.",
          "badge": "Demo",
          "alert": "Deze organisatie gebruikt de demo modus. Echte betalingen zijn niet mogelijk. Gecreëerde evenementen onder deze organisatie zijn alleen voor demo of test doeleinden."
        },
        "miscellaneous": {
          "title": "Overige",
          "refund": "Gebruik kunnen maken van terugstorten met credits",
          "expiration": "Vervalperiode",
          "expirationInfo": "Deze periode kan worden overschreven op basis van de rol."
        },
        "paymentInfo": {
          "title": "Betalingen",
          "psp": {
            "label": "Payment Service Provider",
            "dummyInfo": "Let op: alleen gebruiken voor demo's of het tijdelijk testen van ongepubliceerde evenementen.",
            "selectInfo": "Let op: De geselecteerde valuta bepaald welke payment service provider beschikbaar is.",
            "lockedInfo": "Let op: je kunt niet meer van PSP wisselen na verkoop van je eerste ticket.",
            "options": {
              "mollie": "Mollie",
              "stripe": "Stripe",
              "dummy": "Dummy PSP",
              "ticketeer_payments": "PSP van {brand}"
            }
          },
          "apiKey": {
            "test_key": "Je hebt geen permissie om TEST API credentials in te voeren",
            "label_apiKey_mollie": "Live API key",
            "label_apiKey_stripe": "Secret Key",
            "label_publicKey_stripe": "Publishable key",
            "label": "API key",
            "publicKey": "Public API key",
            "addInfo": "Voeg de API-key toe die door jouw payment service provider is verstrekt. Wanneer je de API key correct instelt, zal deze direct actief zijn.",
            "existsInfo": "Let op: Je kunt een API-key niet meer wijzigen na verkoop van je eerste ticket.",
            "error": "Deze API-key lijkt niet geldig te zijn",
            "apiErrors": {
              "invalid_api_key": "Fout: ongeldige API-key"
            }
          },
          "methods": {
            "title": "Betalingsmethodes",
            "overrideLabel": "Overschrijf betalingsmethodes",
            "overrideInfo": "The following payment methods are set according to the Mollie account you provided. If you chose to override the payment methods, changes in your Mollie account won’t reflect in these settings.",
            "overrideInfoStripe": "Belangrijk: De geaccepteerde betaalmethoden moeten exact overeenkomen met de in Stripe ingeschakelde betaalmethoden, anders werken betalingen niet! Standaard is alles ingeschakeld."
          }
        },
        "fileDescription": "Alleen PDF's met maximale bestandagroote van 20mb",
        "previewFileLink": "Bekijk bestand",
        "usersAndRoles": {
          "navTitle": "Gebruikers & Rollen",
          "create": "Gebruiker uitnodigen",
          "active": "Actief",
          "deactivated": "Gedeactiveerd",
          "search": "Zoek gebruikers",
          "activateUser": "Activeer",
          "pendingInvitation": "{role} uitnodiging verzonden",
          "expiredInvitation": "Uitnodiging verlopen",
          "inviteUserModal": {
            "edit": "Gebruiker aanpassen",
            "pending": "Uitnodiging verzonden",
            "expired": "Uitnodiging verlopen",
            "invite": "Gebruiker uitnodigen",
            "info": "Er wordt een e-mail naar dit e-mailadres gestuurd om een account aan te maken of in te loggen en de organisatie aan deze gebruiker te koppelen.",
            "emailAlreadyUsed": "Dit e-mailadres is al uitgenodigd voor het dashboard. Gebruik een ander e-mailadres.",
            "send": "Verstuur uitnodiging",
            "resend": "Uitnodiging nogmaals versturen",
            "removeInvitation": "Verwijder uitnodiging",
            "deactivate": "Deactiveer"
          },
          "switchUserStateModal": {
            "activateTitle": "Activeer gebruiker",
            "deactivateTitle": "Deactiveer gebruiker",
            "description": "Weet je zeker dat je {email} wilt {action}?"
          }
        },
        "attendeeInfo": {
          "title": "Bezoekersinformatie",
          "description": "De informatie die bezoekers moeten invullen bij het kopen van tickets voor jouw evenementen. Je kunt kiezen of onderstaande informatie verplicht, optioneel of verborgen is. De velden gemarkeerd als \"altijd verplicht\" kunnen niet worden gewijzigd.",
          "states": {
            "always": "Altijd verplicht",
            "mandatory": "Verplicht",
            "optional": "Optioneel",
            "hide": "Verborgen"
          },
          "anonymize": {
            "button": "Verwijder account",
            "modal": {
              "title": "Weet je het zeker?",
              "description": "Het verwijderen van het account resulteert in het verwijderen van alle gegevens en tickets. Dit kan NIET worden teruggedraaid."
            }
          }
        },
        "floorplans": "Plattegronden"
      },
      "placeholder": "Zoek organisaties",
      "create": "Creëer organisatie",
      "modal": {
        "title": "Creëer organisatie",
        "selectCurrency": "Selecteer valuta"
      },
      "table": {
        "psp": "PSP",
        "edit": "Aanpassen"
      }
    },
    "account": {
      "editTitle": "Profiel aanpassen",
      "passwords": {
        "password": "Wachtwoord | Wachtwoorden",
        "changePass": "Wachtwoord aanpassen",
        "resetPass": "Wachtwoord resetten",
        "forgotPass": "Wachtwoord vergeten?",
        "currentPass": "Huidig wachtwoord",
        "newPass": "Nieuw wachtwoord",
        "confirmPass": "Bevestig wachtwoord",
        "apiErrors": {
          "weak_password": "Je wachtwoord mag niet lijken op je e-mailadres, naam of een zwak wachtwoord als \"admin1234\"."
        },
        "passwordInfo": {
          "title": "We verwachten een sterk wachtwoord:",
          "minLength": "Minimale lengte van 9 tekens en niet alleen cijfers",
          "similar": "Mag niet lijken op je e-mailadres, of een zwak wachtwoord als \"admin123\""
        }
      },
      "login": {
        "title": "Inloggen",
        "invalidCredentials": "Ongeldige inloggegevens",
        "noAccount": "Heb je nog geen account?",
        "username": "Gebruikersnaam"
      },
      "signup": {
        "title": "Aanmelden",
        "hasAccount": "Heb je al een account?",
        "apiErrors": {
          "user_already_exists": "Er bestaat al een account met dit e-mailadres"
        }
      },
      "reset": {
        "description": "Voer je e-mailadres in en we sturen je een link om je wachtwoord opnieuw in te stellen.",
        "requestedDescription": "Als er een account aan dit e-mailadres is gekoppeld, ontvang je een link om je wachtwoord opnieuw in te stellen.",
        "expiredAlert": "Deze link is verlopen. Je kunt eventueel opnnieuw een wachtwoord reset uitvoeren.",
        "toLogin": "Terug naar inloggen"
      }
    },
    "invite": {
      "title": "Accepteer uitnodiging",
      "expired": {
        "alert": "Deze uitnodiging is verlopen.",
        "info": "For meer informatie of hulp, contacteer {0}."
      },
      "noInvite": "Deze is al geaccepteerd of bestaat niet. Je kunt doorgaan naar dashboard.",
      "accept": {
        "title": "Je bent uitgenodigd om toegang te krijgen tot: {org}",
        "inviteAccepted": "Je account heeft nu toegang tot: {org}",
        "info": "De uitnodiging zal worden geaccepteerd onder je huidige account: {email}. Als je een ander account wilt gebruiken, log dan eerst in op dit account en klik vervolgens opnieuw op de uitnodigingslink."
      }
    },
    "attendees": {
      "attendee": {
        "edit": {
          "changeNumber": {
            "title": "Nummer aanpassen",
            "description": "Je staat op het punt het volgende mobiele nummer te wijzigen: {number}",
            "inputLabel": "Nieuwe mobiele nummer"
          },
          "confirmNumber": {
            "attendees": "bezoekers",
            "migrateConfirm": "Als je wilt migreren, bevestig dan het nieuwe mobiele nummer",
            "existsDescription": "Er is al een account gekoppeld aan dit mobiele nummer. Weet je zeker dat dit klopt?",
            "existsWithTickets": "Dit account heeft opkomende evenementen. Je kunt deze daarom niet migreren. Ga terug als je een ander nummer wilt gebruiken.",
            "inputLabel": "Bevestig nieuwe mobiele nummer"
          },
          "success": "Mobiele nummer succesvol aangepast",
          "error": "Fout: telefoonnummer kan niet worden aangepast"
        }
      }
    },
    "customers": {
      "title": "Jouw klanten",
      "startSearch": "Zoek naar klanten binnen al uw evenementen door een (deel) naam, e-mailadres of telefoonnummer in te voeren!",
      "loading": "Klantgegegevens laden...",
      "groupsLoading": "Groepen laden...",
      "tooltips": {
        "privilegesDescription": "Het totaal aantal entree privileges dat aan een klant is toegewezen."
      },
      "tickets": {
        "ticketsTable": {
          "emptyText": "Geen tickets gevonden, check orders voor meer informatie",
          "emptyTextShort": "Geen tickets gevonden",
          "resoldBy": "door {customer}"
        },
        "checkin": {
          "title": "Check-in",
          "description": "Are you sure that you want to check-in this ticket? | Are you sure that you want to check-in these {count} tickets?"
        },
        "undoCheckin": {
          "title": "Undo Check-in",
          "description": "Are you sure that you want to undo check-in this ticket? | Are you sure that you want to undo check-in these {count} tickets?"
        },
        "invalidate": {
          "steps": {
            "options": "Refund options",
            "details": "Refund details"
          },
          "refundOptions": {
            "blockTickets": "Block the ticket that will be available again | Block the tickets that will be available again",
            "tooltipText": "If you don't select this option, this ticket will be up for sale again. | If you don't select this option, the tickets will be up for sale again.",
            "title": "Refund ticket | Refund tickets",
            "description": "Select if and how you want refund the ticket you are about to invalidate. | Select if and how you want refund the tickets you are about to invalidate.",
            "options": {
              "automatic": {
                "title": "Automatic refund",
                "description": "Automatically refund the amount paid for the tickets to the attendee. Select in the next step if the attendee or the organizer will be charged the service fee."
              },
              "manual": {
                "title": "Manual refund",
                "description": "Refund manually using a external payment method and adjust the reports accordingly. Select in the next step if the attendee or the organizer will be charged the service fee."
              },
              "credits": {
                "title": "Credits",
                "description": "Refund the amount and add these funds to account of the user to be redeemed later."
              },
              "noRefund": {
                "title": "No refund",
                "description": "Only invalidate the tickets, keep the original paid amount in the reports."
              }
            }
          },
          "refundDetails": {
            "warning": "This action cannot be undone. If you wish to continue, press confirm.",
            "title": {
              "auto": "Automatic refund",
              "manual": "Manual refund",
              "credits": "Credits",
              "noRefund": "No refund"
            },
            "howToRefund": "How would you like to refund the following tickets?",
            "fullRefund": "Full refund",
            "partialRefund": "Partial refund",
            "amountToDeduct": "Amount you wish to deduct per ticket",
            "creditsExpiration": "Credits expiration date",
            "summary": {
              "title": "Summary",
              "description": "The following ticket will be invalidated. | The following tickets will be invalidated.",
              "totalRefund": "Total refund: {formatedAmount}",
              "ticketBlockedOnCategory": "The ticket will be block on category: | The tickets will be block on category:"
            }
          }
        },
        "editNotes": {
          "title": "Edit note | Edit notes",
          "multiNotesDescription": "You're editing multiple notes, this change will reflect on all the items you selected.",
          "placeholder": "Note"
        }
      },
      "block_categories": {
        "uncategorized": "Uncategorized",
        "uncategorized_short": "UNC",
        "search": "Search a category..."
      },
      "privileges": {
        "privilegesTable": {
          "emptyText": "Geen privileges gevonden"
        }
      },
      "orders": {
        "ordersOverviewTitle": "Jouw bestellingen"
      },
      "refunds": {
        "refundsTable": {
          "emptyText": "Geen terugbetalingen gevonden"
        }
      },
      "customer": {
        "noName": "Naamloze klant",
        "noAddress": "Onbekend adres",
        "userQr": {
          "action": "User Qr",
          "title": "{name} user QR",
          "description": "Scan the following QR code to check-in the customer.",
          "error": "Could not load the QR code for this customer"
        },
        "edit": {
          "changeNumber": {
            "title": "Nummer wijzigen",
            "description": "Je staat op het punt het volgende mobiele nummer te wijzigen: {number}",
            "inputLabel": "Nieuwe mobiele nummer"
          },
          "confirmNumber": {
            "migrateInfo": "Dit mobiele nummer kan worden gewijzigd. Ga eventueel naar {customers} om te controleren of het account al bestaat.",
            "customers": "klanten",
            "migrateConfirm": "Als je wilt migreren, bevestig dan het nieuwe mobiele nummer.",
            "existsDescription": "Er is al een account gekoppeld aan dit mobiele nummer. Weet je zeker dat dit klopt?",
            "existsWithTickets": "Dit account heeft geplande events. Je kunt deze daarom niet migreren. Ga terug als je een ander nummer wilt gebruiken.",
            "inputLabel": "Bevestig nieuwe mobiele nummer"
          },
          "success": "Mobiele nummer succesvol aangepast",
          "error": "Fout: telefoonnummer kan niet worden aangepast"
        }
      },
      "filters": {
        "pool": "Pool",
        "shop": "Shop",
        "checkin": {
          "name": "Check-in status",
          "fully": "Complete",
          "partially": "Deels",
          "not": "Niet"
        }
      }
    },
    "events": {
      "create": {
        "title": "Creëer evenement",
        "new": "Nieuw evenement",
        "fromTemplate": "Kies template"
      },
      "title": "Jouw evenementen",
      "titleWithOrganization": "Jouw evenementen voor {org}",
      "reports": {
        "title": "Jouw verkoop",
        "titleWithOrganization": "Jouw verkoop voor {org}",
        "revenue": "Omzet",
        "revenueBoxTitle": "NETTO omzet deze periode",
        "ticketSoldBoxTitle": "Tickets verkocht deze periode",
        "grossRevenue": "BRUTO omzet",
        "invalidated": "Geïnvalideerd",
        "refunded": "Teruggestort",
        "upsellSoldBoxTitle": "Upsells verkocht deze periode",
        "credits": {
          "title": "Credits",
          "circulating": "Circulerend",
          "info": "Het totaal aantal terugstortingen in credits, welke nog niet gebruikt of verlopen zijn"
        }
      },
      "eventCreate": {
        "title": "{type} aanmaken",
        "eventDescription": "Tijd om een evenement aan te maken! De eerste stap: om wat voor type evenement gaat het ? Deze keuze is de basis. Alle andere instellingen kun je achteraf aanpassen, maar deze niet. Je kunt natuurlijk wel altijd weer opnieuw beginnen - alles wordt opgeslagen als concept totdat jij besluit dat het event er klaar voor is.\n\n",
        "templateDescription": "Maak een geheel nieuwe template aan! Deze stappen zullen je begeleiden om de minimale configuratie toe te voegen die nodig is om een template op te zetten. Je kunt achteraf alle instellingen bewerken, behalve het initiële evenement-type.",
        "templateInfo": "Nadat een template is aangemaakt kun je (automatisch) meerdere evenementen genereren op basis van deze template. Alle instellingen in deze template worden gebruikt en bijgewerkt voor alle gegenereerde evenementen.",
        "setEventType": "Type evenement",
        "selectType": {
          "label": "Selecteer het type evenement dat je wilt aanmaken"
        },
        "selectVenue": "Selecteer een locatie",
        "eventDetails": "Evenement details",
        "ticketManagement": "Ticketbeheer",
        "modal": {
          "title": "Preview nieuw evenement",
          "content": "Het nieuwe evenement is met succes gemaakt en opgeslagen onder conceptevenementen.<br><br>Je kunt het evenement nu publiceren of teruggaan naar het dashboard om nog meer wijzigingen aan te brengen.",
          "dashboardButton": "Ga terug naar het dashboard",
          "publishButton": "Evenement publiceren"
        }
      },
      "eventCopy": {
        "action": "Kopieer Evenement",
        "description": "Alle configuraties van het gekozen evenement zullen gekopieerd worden, inclusief de blokkades.",
        "startDate": "Start Datum"
      },
      "templateCopy": {
        "action": "Kopieer template",
        "description": "Alle instellingen van deze template worden gekopieerd, inclusief huidige blokkades."
      },
      "event": {
        "savedAsDraft": {
          "title": "Concept evenement is opgeslagen.",
          "draftInfo": "Je evenement is aangemaakt en opgeslagen als concept. Je kunt het evenement openen en aanpassen in het dashboard. Het evenement staat onder 'Concepten'",
          "previewInfo": "Je kunt een preview van het evenement bekijken en het evenement later publiceren. Of je kunt het evenement meteen publiceren. Klik op OK om terug te gaan naar het dashboard."
        },
        "demo": {
          "description": "Demo event: Dit evenement is aangemaakt door een demo organisatie. Echte betalingen zijn niet mogelijk. Gebruikt dit evenement alleen voor demo of testing doeleinden."
        },
        "unlink": {
          "title": "Dit evenement wordt beheerd door een template.",
          "description": "Aanpassingen aan shops, tickets, pools en andere instelling kunnen worden gedaan vanuit de template en zullen worden doorgevoerd naar alle evenementen (ga naar de {0}).",
          "eventTemplate": "template",
          "shop": {
            "title": "Deze shop wordt beheerd door een template.",
            "description": "Wijzigingen in deze shop, tickets en groepen kunnen alleen worden gemaakt voor alle evenementen via {0}.",
            "link": "de template van dit evenement"
          },
          "action": {
            "description": "Wil je alleen dit evenement aanpassen? {0}",
            "unlink": "Ontkoppel het van de template."
          },
          "modal": {
            "title": "Weet je zeker dat je dit evenement wilt ontkoppelen?",
            "description": "Wanneer je dit evenement loskoppelt van de template dan wordt deze niet meer bijgewerkt wanneer er wijzigingen worden aangebracht in de template en deze synchroniseert. Je kan het evenement dan alleen losstaand beheren. Deze actie kan niet ongedaan gemaakt worden!",
            "action": {
              "unlink": "ontkoppelen",
              "description": "Typ {0} in het onderstaande veld om te bevestigen"
            },
            "unlinkEvent": "Ontkoppel evenement"
          }
        },
        "manage": {
          "placeholders": {
            "title": "bijv. Maximum Music Festival",
            "subtitle": "bijv. 20e jubileum",
            "shortName": "bijv. MaxMusic"
          },
          "shortNameExplanation": "Wordt gebruikt voor SMS-berichten bij het uitgeven van tickets via het dashboard",
          "generalTitle": "Beheer basis details",
          "additionalTitle": "Beheer additionele details",
          "advancedTitle": "Beheer geavanceerde instellingen",
          "saveValidateAlert": "Sommige verplichte velden ontbreken of bevatten ongeldige gegevens.<br>Controleer bovenstaande fouten.",
          "marketing": "Marketing & upsell",
          "scannerPassword": "Scanner wachtwoord",
          "scannerQr": "QR code",
          "about": {
            "label": "Over je evenement",
            "description": "Beschrijf waar je evenement over gaat, communiceer extra richtlijnen aan je bezoekers en verstrek algemene informatie en externe links.",
            "help": "Je kunt de tekst opmaken en links toevoegen door woorden te selecteren."
          },
          "endDateSwitch": "Dit evenement eindigt de volgende dag na middernacht",
          "endDateDescription": "Als een evenement na middernacht eindigt (bijvoorbeeld 01:00) dan eindigt het technisch gezien een dag later - vroeg in de ochtend. Schakel dit in om aan te geven dat het evenement eigenlijk een dag eerder eindigt.",
          "templateStartDateInfo": "Gebruikt de opgegeven datum van een evenement als deze gegenereerd wordt.",
          "dateErrors": {
            "required": "Alle datum- en tijdvelden zijn vereist. Zorg ervoor dat alle informatie is ingevuld.",
            "minOpen": "De openingstijd kan niet na de starttijd van het evenement zijn.",
            "minOpenLivestream": "De openingstijd van de livestream moet minimaal 15 minuten eerder zijn dan de starttijd van het evenement."
          },
          "coverImage": {
            "label": "Omslagfoto",
            "description": "Voeg een beeld van hoge kwaliteit toe. Deze wordt in verschillende uitsnedes gebruikt, afhankelijk van de plek in de app. Probeer dus tekst of logo's in de afbeelding te vermijden. Zorg ervoor dat je er goed uitziet!",
            "help": "PNG of JPG bestand. Minimale resolutie 640x480. Maximale bestandsgrootte 10Mb."
          },
          "floorplanImage": {
            "label": "Plattegrond of zaalindeling",
            "description": "Deze zal door de ticketeer worden aangeleverd in eps formaat",
            "help": "SVG of PNG file type. Aan te raden resolutie is 375 pixels breed."
          },
          "orderSuccessLink": {
            "label": "Bericht na het kopen van tickets",
            "description": "Stuur een kort bericht of een link naar de koper. Laat zien dat je aan ze denkt! "
          },
          "orderSuccessEmail": {
            "label": "E-mail: Bericht in bevestigingsmail van ticket",
            "description": "Voeg een korte, extra tekst toe aan de bevestigingsmail, als je dat wilt. Vind je het leuk dat ze komen?"
          },
          "saveAlert": {
            "title": "Wijzigen opslaan",
            "description": "Weet je zeker dat je de wijzigingen die je hebt gemaakt wilt opslaan voor dit gepubliceerde evenement? Bezoekers kunnen de wijzigingen direct zien."
          },
          "publishAlert": {
            "title": "Publiceer evenement",
            "description": "Weet je zeker dat je dit evenement wilt publiceren en openbaar wilt maken? Nadat een evenement is gepubliceerd, kun je deze <b> niet </b> meer terugveranderen in concept. Tickets zijn echter pas beschikbaar op de opgegeven startdatum van de verkoop.",
            "shopsTitle": "Jouw event en de volgende shops zullen gepubliceerd worden",
            "cancel": "Niet nu",
            "confirm": "Publiceer nu",
            "warning": "Publiceren kan niet ongedaan gemaakt worden",
            "shop": "Shop",
            "defaultGate": "Standaard Gate",
            "opens": "Geopend op",
            "immediately": "Per direct",
            "shopsFailed": "Laden van de shop mislukt, probeer het opnieuw",
            "tryAgain": "Opnieuw proberen",
            "publishError": "Dit evenement kan niet worden gepubliceerd. Zorg ervoor dat de payment service provider van de organisatie volledig is ingesteld.",
            "orgSettings": "Ga naar de instellingen voor: {name}"
          },
          "livestream": {
            "typeSelect": {
              "label": "Selecteer hoe je jouw livestream wilt configureren (optioneel)",
              "internal": "Intern: gebruik jouw eigen embed-code",
              "external": "Extern: gebruik een externe link om naartoe te sturen"
            },
            "videoEmbed": {
              "label": "Livestream video embed-code (optioneel)",
              "placeholder": "Voeg hier de embed-code van de livestream hier toe, bijvoorbeeld een <iframe>"
            },
            "chatEmbed": {
              "label": "Livestream chat embed-code (optioneel)",
              "placeholder": "Voeg hier extra embed-codes toe (zoals een chatroom)"
            },
            "externalUrl": {
              "label": "Livestream externe URL (optioneel)",
              "placeholder": "https://livestream.com"
            }
          },
          "advanced": {
            "sharing": {
              "title": "Delen",
              "label": "Ticket delen tussen bezoekers (optioneel)",
              "strict": "Persoonlijk delen via QR-code op locatie",
              "strictInfo": "Bezoekers moeten bij elkaar zijn en een QR-code scannen om tickets met elkaar te delen",
              "easy": "Delen via link",
              "easyInfo": "Bezoekers kunnen een link sturen om anderen uit te nodigen",
              "disabled": "Niet toegestaan",
              "disabledInfo": "Bezoekers kunnen hun tickets voor dit evenement niet delen"
            },
            "private": {
              "title": "Blockchain zichtbaarheid",
              "label": "Het is mogelijk om de event details op de blockchain te verbergen. Hieronder vallen de naam van het event, locatie, afbeelding en de shop URL",
              "checkbox": "Verberg de details van dit evenement op de blockchain"
            },
            "orderConfirmEmail": {
              "title": "Bevestigingsmail voor bestelling",
              "label": "Emailadres (optioneel)",
              "placeholder": "voorbeeld{'@'}organisator.nl",
              "info": "Wij sturen een e-mail naar dit adres met details over elke gemaakte bestelling."
            },
            "scanLeeway": {
              "title": "Offline scannen",
              "label": "Stel de hoeveelheid tijd in minuten in",
              "info": "Bezoekers kunnen binnenkomen met een QR-code waarvoor geen internetverbinding nodig is. Deze instelling bepaalt hoe lang deze offline QR-codes geldig zijn."
            }
          },
          "blockchain": {
            "publish": {
              "title": "Zichtbaarheid",
              "label": "Wanneer je publiceert, zal je event & shop informatie ook zichtbaar zijn op de blockchain (GET protocol). Je kunt (optioneel) opgeven wanneer dat moet gebeuren:",
              "never": "Verberg de details van dit evenement op de blockchain",
              "public": "De informatie van het event is reeds publiekelijk gemaakt op de blockchain. Dit is niet omkeerbaar.",
              "defaultInfo": "Dit is standaard ingesteld op de openingstijd van de standaard shop."
            },
            "contractAddress": {
              "title": "Event contract address",
              "placeholderDraft": "Zodra het event is gepubliceerd zal je event contract address gegenereerd worden.",
              "label": "Hieronder staat het event contract address waar je alle transacties kan inzien en verifiëren.",
              "placeholder": "Genereren (ververs de pagina om te updaten)"
            },
            "nft": {
              "title": "Standaard Collectible Artwork",
              "label": "Upload jouw standaard Collectible afbeelding hier. Dit design zal gebruikt worden als standaard voor alle ticket collectibles (tenzij specifiek overschreden in de individuele ticket settings).",
              "guidelines_title": "Algemene richtlijnen:",
              "line_1": "PNG, GIF of JPG file type",
              "line_2": "Portret modus (belangrijk!)",
              "line_3": "Aspect ratio van 3:4",
              "line_4": "Max. 10 MB",
              "note": "Let op:: Tenzij je een afbeelding upload, wordt standaard een generieke NFT artwork gebruikt."
            }
          },
          "resale": {
            "title": "Ticketmarkt servicekosten"
          },
          "previewModal": {
            "title": "Voorbeeld e-mail versturen",
            "success": "Email verzonden",
            "error": "Fout opgetreden op bij het verzenden van de e-mail"
          }
        },
        "reports": {
          "sales": {
            "turnover": "Omzet",
            "capacity": "Capaciteit gevuld",
            "ticketsSold": "Tickets verkocht",
            "capacityFilled": "Capaciteit gevuld",
            "capacitySold": "Capaciteit verkocht",
            "tooltipTicketsSold": "Verkocht",
            "availabilityTooltip": "Tickets kunnen pools delen",
            "percentageSold": "Percentage verkocht",
            "revenue": "Opbrengst",
            "grossRevenue": "Bruto omzet",
            "graph": {
              "range": {
                "placeholder": "Selecteer een periode",
                "last_day": "Laatste 24 uur",
                "last_month": "Laatste 31 dagen",
                "all_time": "Alle tijden"
              },
              "title": {
                "last_day": "Tickets verkocht laatste 24 uur",
                "last_month": "Tickets verkocht laatste 31 dagen",
                "all_time": "Tickets verkocht alle tijden per week"
              }
            }
          },
          "demographics": {
            "avgOrderSize": "Gemiddeld aantal tickets per bestelling",
            "totalBuyers": "Totaal aantal kopers",
            "locations": "Locaties"
          },
          "execution": {
            "checkedInPercentage": "Percentage ingecheckt"
          }
        },
        "attendees": {
          "filters": {
            "checkin": {
              "label": "Filter op check-in",
              "partialExecuted": "Deels ingecheckt",
              "allExecuted": "Volledig ingecheckt",
              "notExecuted": "Niet ingecheckt",
              "all": "Toon alle"
            },
            "pools": "Filter op pools"
          },
          "guestlist": {
            "action": "Gast toevoegen",
            "addRow": "Rij toevoegen",
            "guestListName": "Naam gastenlijst",
            "createVariant": "Maak nieuwe...",
            "selectTicket": "Selecteer ticket",
            "selectVariant": "Selecteer gastenlijst",
            "optionsTitle": "Voeg toe aan gratis gastenlijst",
            "seating": "Selecteer blokkades om te gebruiken",
            "selectPool": "Selecteer welke beschikbaarheid je wilt gebruiken",
            "confirmTitle": "Bevestig gastenlijst"
          },
          "cashier": {
            "selectTickets": "Selecteer tickets",
            "blockCategory": "Blokkade categorieën",
            "ticketsLeft": "Tickets over",
            "specifyAmount": "Specificeer hoeveel {ticketName} tickets je toe wilt voegen",
            "cart": "Winkelwagen ({count})",
            "emailField": "Stuur bevestiging aan (optioneel)",
            "notifyBySms": "Stuur notificatie via SMS",
            "notifyByEmail": "Stuur notificatie via e-mail",
            "addTicket": "Voeg {ticketName} Ticket toe",
            "addTicketDescription": "Uit welke <strong>beschikbaarheid</strong> wil je jouw tickets selecteren?",
            "addTicketAssignment": "How would you like to assign the seats?",
            "addTicketHowMany": "Hoeveel <strong>{ticketName}</strong> tickets van <strong>{shopName}</strong> wil je toevoegen?",
            "addToCart": "Toevoegen aan winkelwagen",
            "emailTooltip": "Als er geen e-mailadres is opgegeven, zal er geen bevestigingsmail worden verzonden.",
            "ticketsLoading": "Beschikbare tickets worden opgehaald, een moment geduld alstublieft...",
            "tickets": "Tickets",
            "issueDigitallyCheck": "Wilt je de kaarten digitaal versturen?",
            "yourOrder": "Jouw bestelling {orderId} voor {customerName}",
            "orderCreated": "Bestelling {orderId} gecreëerd",
            "issueMore": "Meer tickets uitgeven",
            "reserveMore": "Meer tickets reserveren",
            "bookPayment": "Book payment",
            "distributeTickets": "Distribute tickets",
            "checkinAll": "Alle tickets direct inchecken",
            "noTicketsSelected": "Geen tickets geselecteerd",
            "confirmGuestInfo": {
              "confirmTitle": "Tickets bevestigen",
              "confirmMainMessage": "Tickets worden gekoppeld aan het opgegeven telefoonnummer en kunnen worden geopend door de bezoeker via de ticket wallet.<br/>Bezoekers ontvangen een e-mailbevestiging met de event- en ticketinformatie.",
              "total": "Totaal",
              "hasEmail": "Bevestiging naar: {email}",
              "noEmail": "Geen bevestiging (geen e-mailadres opgegeven)"
            },
            "actionTooltip": "Tickets kunnen alleen verkocht worden als het evenement is gepubliceerd",
            "action": "Verkoop tickets",
            "optionsTitle": "Verkoop tickets",
            "categories": {
              "title": "Selecteer blokkade categorie",
              "description": "Hieronder worden alle ongeplaceerde tickets weergegeven. Selecteer de categorie waaruit je wilt dat het ticket wordt verkocht. Als een categorie niet genoeg blokkades beschikbaar heeft dan kun je deze {0}",
              "categoryLink": "hier wijzigen."
            },
            "payment": {
              "label": "Geld ontvangen door",
              "placeholder": "Betaalmiddel"
            },
            "seatingMode": {
              "auto": "Automatisch plaatsen toewijzen\n\n\n\n\n",
              "manual": "Kies plaats(en) nu",
              "autoseatInfo": "Nadat de placering gefinaliseerd is"
            },
            "issue_now_modal": {
              "title": "Wat is de volgende stap?",
              "choices": {
                "issue": "Verkoop tickets",
                "reservation": "Reservering aanmaken"
              }
            },
            "add_to_reservation_modal": {
              "title": "Add selection to reservation",
              "description": "You are about to add your selection to the reservation. Are you sure?"
            },
            "reservationForm": {
              "expiryDate": "Vervaldatum",
              "details": "Details",
              "reservationName": "Naam reservering",
              "note": "Notitie",
              "customMessageContent": "Custom message content",
              "contactDetails": "Contactgegevens",
              "firstName": "Voornaam",
              "lastName": "Achternaam",
              "mobileNumber": "Mobiel nummer",
              "email": "E-mailadres",
              "notePlaceholder": "E.g. Team Building Event",
              "customMessageContentPlaceholder": "E.g. Please bring your ID"
            }
          },
          "blockCategories": {
            "notfound": "Geen categorie gevonden...",
            "createCategory": "Creëer categorie",
            "createCategoryTitle": "Categorie aanmaken",
            "deleteTooltip": "Alleen lege categorieën kunnen worden verwijderd.",
            "editCategory": "Categorie aanpassen",
            "categoryName": "Naam categorie",
            "categoryNamePlaceholder": "bijv. Technici",
            "categoryShortNamePlaceholder": "ABC (max. 3)",
            "categoryShortName": "Shortcode",
            "uniqueShortNameError": "Shortcodes moeten uniek zijn",
            "uniqueNameError": "Er bestaat al een categorie met deze naam.",
            "selected": "{selected} van {total} geselecteerd",
            "unblocked": "Blokkades succesvol verwijderd",
            "moved": "Blokkades succesvol verplaatst naar andere categorie",
            "printTickets": "Print / PDF",
            "movePrivs": "Verplaats naar categorie",
            "noCategory": "Geen categorie",
            "editNote": "Notitie aanpassen | Notities aanpassen",
            "addNote": "Notitie toevoegen | Notities toevoegen",
            "floorplanLabel": "Blokkade categorieën",
            "addPrivs": "Voeg blokkades toe",
            "addPrivsModal": {
              "title": "Voeg nieuwe blokkades toe aan categorie \"{category}\"",
              "selectPool": "Selecteer pool",
              "addSeated": "Wil je specifieke zitplaatsen blokkeren? {0}",
              "addSeatedLink": "Ga naar plattegrond"
            },
            "categorySearch": "Selecteer een categorie...",
            "details": {
              "ticket": "Rang / ticket",
              "filterClear": "Filter wissen",
              "filterTicket": {
                "empty": "Filter op ticket",
                "selected": "Gefilterd op '{name}'"
              },
              "filterSection": {
                "empty": "Filter op sectie",
                "selected": "Gefilterd op '{name}'"
              },
              "filterClaimed": {
                "empty": "Filter op status",
                "selected": "Gefilterd op 'Gebruikt'"
              }
            },
            "list": {
              "fields": {
                "name": "Categorie"
              }
            },
            "editTicketNote": {
              "titleEdit": "Notitie(s) aanpassen",
              "titleAdd": "Notitie(s) toevoegen",
              "multipleNotes": "Je bewerkt meerdere notities. Deze wijziging zal gedaan worden voor alles wat je hebt geselecteerd.",
              "placeholder": "Notitie"
            },
            "unblockModal": {
              "title": "Blokkade(s) vrijgeven",
              "description": "De geselecteerde blokkades worden vrijgegeven en te koop aangeboden.",
              "descriptionCheckedIn": "Let op: ingecheckte blokkades kunnen niet worden vrijgegeven, deze worden enkel ongecategoriseerd."
            }
          },
          "externalCodes": {
            "title": "Externe tickets",
            "ticketInfo": {
              "ticketsImported": "Totaal aantal tickets geïmporteerd",
              "lastImport": "Laatste import",
              "ticketsCheckedIn": "Totaal aantal ingecheckte tickets"
            },
            "importCSV": {
              "title": "Externe tickets importeren met csv",
              "description": "Accepteer en check-in externe tickets in de scanner door barcodes te importeren via een <strong>csv</strong>. De structuur van het csv moet een enkele kolom zijn die de barcodes bevat, of de kolom met barcodes moet de naam 'barcode' hebben (zonder aanhalingstekens).",
              "placeholder": "Selecteer csv bestand of sleep deze hierheen...",
              "deleteAndReplace": "Vervang bestaande tickets. Niet ingecheckte tickets zullen worden verwijderd.",
              "parsingError": "Geen barcodes gevonden. Gebruik een enkele kolom of noem de kolom 'barcode' (zonder aanhalingstekens)",
              "uploadFailInvalidCSV": "Het verstrekte bestand is geen geldige csv",
              "uploadFailNoCodes": "Het verstrekte bestand bevat geen codes",
              "uploadFail": "Het csv-bestand kan niet worden geïmporteerd",
              "uploadSuccess": "{nbTickets} tickets geïmporteerd. Voorbeeld van één geïmporteerde barcode: {barcodeExample}",
              "fileTooBig": "Het csv-bestand dat je probeert te importeren is te groot. De maximale grootte is 6MB.",
              "emptyFile": "Het csv-bestand dat je probeert te importeren is leeg"
            }
          },
          "attendee": {
            "invalidateSuccess": "De tickets zijn succesvol ongeldig gemaakt",
            "invalidationFail": "Ongeldig maken is mislukt. Probeer het opnieuw",
            "invalidateTicketConfirm": {
              "manual": "Handmatige terugbetaling",
              "auto": "Automatische terugbetaling",
              "credits": "Terugbetalen met credits",
              "noRefund": "Invalideren zonder terugbetaling",
              "creditsExpiration": "Vervaldatum",
              "creditsConfirm": "Deze credits vervallen op {0} waarna ze niet meer gebruikt kunnen worden",
              "titleWithoutRefund": "Bevestig invalidatie",
              "refundType": "Hoe wil je de onderstaande tickets vergoeden?",
              "partialRefundInfos": "Vul het bedrag in dat je per ticket wilt inhouden",
              "deduct": "In te houden",
              "fullRefund": "Volledige vergoeding",
              "partialRefund": "Gedeeltelijke vergoeding",
              "deducted": "Ingehouden bedrag",
              "subtitle": "Je staat op het punt het volgende ticket te annuleren en te vergoeden | Je staat op het punt om de volgende tickets ongeldig te maken en te vergoeden",
              "subtitleWithoutRefund": "Je staat op het punt het volgende ticket ongeldig te maken | Je staat op het punt de volgende tickets ongeldig te maken",
              "totalAmount": "Totale vergoeding",
              "refundAmount": "Terugbetaling",
              "partialRefundErrorMessage": "Het bedrag is te hoog",
              "toolTipText": "Als je deze optie niet selecteert, worden tickets direct opnieuw te koop aangeboden",
              "selectBlock": "Selecteer blokkade-categorie",
              "blockCheckbox": "Blokkeer de tickets die weer beschikbaar zijn",
              "feeMessage": "De servicekosten worden in rekening gebracht bij:",
              "summaryMessage": "De volgende ticket(s) worden geïnvalideerd.",
              "category": "De tickets zijn geblokkeerd in categorie {0}",
              "actionUndoneInfo": "Deze actie kan niet ongedaan gemaakt worden.",
              "extraConfirmModal": {
                "extraConfirmMessage": "Het restitutiebedrag is {0}. Deze actie kan niet ongedaan gemaakt worden. Weet je zeker dat je de geselecteerde tickets wilt invalideren en terugbetalen?",
                "extraConfirmTitle": "Voltooi de invalidatie en terugbetaling",
                "extraFinalizeMessage": {
                  "text": "Typ {0} in het onderstaande veld om te bevestigen",
                  "finalize": "FINALISEER"
                }
              }
            },
            "invalidateTicketOptions": {
              "title": "Tickets invalideren en terugbetalen",
              "missingPaymentMethod": "Selecteer een betaalmethode, alstublieft",
              "subtitle": "Deze actie kan niet ongedaan gemaakt worden",
              "tooltip": "Het terug te betalen bedrag is lager dan het aankoopbedrag. Dit kan het gevolg zijn van gedeeltelijke terugbetalingen in het verleden of meerdere gebruikte betaalmethoden.",
              "automatic": {
                "title": "Invalideren met automatische terugbetaling",
                "description": "Geef het betaalde bedrag voor de tickets automatisch terug aan de deelnemer. Selecteer in de volgende stap of aan de deelnemer of de organisator servicekosten in rekening worden gebracht."
              },
              "credits": {
                "title": "Invalideren met terugbetaling in credits",
                "description": "Stort het bedrag terug als credits en voeg deze toe aan het account van de bezoeker, zodat zij het later kunnen verzilveren.\n"
              },
              "manual": {
                "title": "Invalideren met handmatige terugbetaling",
                "description": "Terugbetaling handmatig via een externe betaalmethode en pas de rapporten dienovereenkomstig aan. Selecteer in de volgende stap of aan de deelnemer of de organisator servicekosten in rekening worden gebracht."
              },
              "noRefund": {
                "title": "Invalideren zonder restitutie",
                "description": "Maak alleen de tickets ongeldig, bewaar het originele betaalde bedrag in de rapporten."
              }
            },
            "ticketBuyer": "koper",
            "noGender": "Geen geslacht",
            "noAge": "Geen leeftijd",
            "noZipcode": "Geen postcode",
            "noCountry": "Geen woonplaats",
            "noPhoneNumber": "Geen telefoonnummer",
            "noEmail": "Geen E-mail",
            "noWalletLink": "Geen wallet link",
            "ticketOwner": "Bezit tickets",
            "activeGroupMember": "Groepslid",
            "inactiveGroupMember": "Verliet groep",
            "customerTickets": "Tickets",
            "customerOrders": "Bestellingen",
            "customerPayments": "Betalingen",
            "customerRefunds": "Terugbetalingen",
            "groupTickets": "Groep tickets",
            "share": {
              "action": "Ticketgroep: Tickets delen",
              "label": "Deel alle tickets met:",
              "description": "Bestaand of nieuw account",
              "newUser": "Nieuw account",
              "anonymousUser": "Anoniem account",
              "alert": {
                "title": "Ticketgroep: Tickets delen",
                "description": "Weet je zeker dat je alle tickets wilt delen met <strong>{from}</strong> en <strong>{to}</strong>?",
                "newUserNotice": "Er wordt een nieuwe account aangemaakt",
                "userNotice": "Tickets worden gedeelt met account: <strong>{name}</strong>."
              }
            },
            "remove": {
              "title": "Verwijder Ticket | Verwijder Tickets",
              "description": "Weet je zeker dat je het ticket wilt verwijderen? | Weet je zeker dat je deze tickets wilt verwijderen?"
            },
            "checkin": {
              "title": "Check-in",
              "description": "Weet je zeker dat je deze privileges wilt inchecken?",
              "combiTickets": "Bevestig welke privilege(s) je wilt inchecken:"
            },
            "undoCheckin": {
              "title": "Inchecken ongedaan maken",
              "description": "Weet je zeker dat je het inchecken van deze privileges ongedaan wilt maken?",
              "combiTickets": "Bevestig voor welke privilege(s) je inchecken ongedaan wilt maken:"
            },
            "email": {
              "title": "E-mail klant",
              "error": "Deze e-mail is niet geldig!",
              "sendEmails": "Verstuur E-mails",
              "orderEmail": "Betaalbevestings",
              "seatingEmail": "Placering informatie"
            },
            "convert": {
              "selectOption": "Selecteer conversie opties",
              "convertTo": "Omzetten naar",
              "selectPool": "Selecteer welke beschikbaarheid je wilt gebruiken",
              "selectKind": "Selecteer een ticket",
              "selectShop": "Selecteer shop",
              "left": "over: {amount}",
              "paymentMethod": "Geld ontvangen door",
              "paymentOptions": "{method} of {free}",
              "sendEmail": "Verstuur een bevestigingsmail met deze wijzigingen naar de klant",
              "selectPayment": "Betaalmiddel",
              "selectPaymentError": "Select payment method",
              "total": "Totaal prijsverschil:",
              "new": "Maak nieuwe",
              "confirmConversion": "Bevestig de conversie"
            },
            "ticketDetails": {
              "id": "ID",
              "price": "Prijs",
              "order": "Bestelling",
              "orderDate": "Besteldatum",
              "shop": "Shop",
              "ownedBy": "Eigendom van",
              "status": "Status",
              "resoldBy": "Verkocht door",
              "on": "Op",
              "onDate": "op {date}",
              "sellerPrice": "Verkoopprijs",
              "buyerPrice": "Aankoopprijs",
              "buyerFees": "Aankoop fee's",
              "sellerFee": "Verkoop fee's",
              "invalidatedBy": "Geïnvalideerd door",
              "refundMethod": {
                "cash": "Cash",
                "pin": "Pin",
                "digital": "Digitaal",
                "other": "Anders"
              },
              "fees": "Fees",
              "payOut": "Uitbetaling",
              "resoldTo": "Verkocht aan",
              "ticketId": "Ticket ID",
              "putForSaleBy": "Te koop gezet door",
              "privileges": "Privileges",
              "boughtBy": "Gekocht door",
              "boughtFrom": "Gekocht van",
              "note": "Note"
            }
          }
        },
        "seating": {
          "legend": "Legenda",
          "sectionList": "Secties",
          "editNote": "Notities aanpassen",
          "standingSection": "Staplaats sectie",
          "capacity": "Capaciteit",
          "overview": "Overzicht",
          "notePlaceholder": "Notitie toevoegen...",
          "notEnoughFree": "Niet genoeg vrij",
          "changes": "Wijzigingen",
          "seatClaimed": "Ingecheckte blokkades kunnen niet meer gewijzigd worden",
          "categoryInfo": "Selecteer eerst een categorie uit onderstaande lijst als je stoelen aan een specifieke categorie wil toewijzen. Als je dit niet doet zullen de stoelen aan de categorie Ongecategoriseerd toegewezen worden.",
          "assign": {
            "title": "Selecteer een bezoeker",
            "showAssigned": "Toon toegewezen",
            "allTicketTypes": "Alle pools",
            "onlyMultipleTicketTypes": "Combinatie meerdere pools"
          },
          "solve": {
            "title": "Autoplaceer alle bezoekers",
            "description": "Autoplaceren zal alle bezoekers automatisch de beste stoelen toewijzen aan de hand van de groep per rang. Wil je losse groepen samen placeren? Vul dan optioneel een groep met maximaal 3 telefoonnummers in van de accounts welke je samen wilt placeren.",
            "groupPlaceholder": "Telefoonnummer",
            "addGroup": "Groep toevoegen",
            "success": "Succesvol geplaceerd!"
          },
          "finalize": {
            "title": "Finaliseer huidige zitplaatsen",
            "description": "Weet je het zeker? Na het finaliseren kunnen er geen wijzingen meer gemaakt worden. Bezoekers worden per e-mail en sms op de hoogte gesteld van hun zitplaatsen.",
            "instructions": "Type {0} in onderstaand veld om te bevestigen:",
            "success": "Succesvol gefinaliseerd!"
          },
          "clearSuccess": "Succesvol gereset!"
        },
        "scanning": {
          "actions": {
            "create": "Creëer profiel",
            "edit": "Profiel aanpassen",
            "cannotEdit": "Dit profiel kan niet worden bewerkt"
          },
          "creation": {
            "description": "Maak een profiel aan dat, wanneer geladen in de scanner-app, zal filteren en alleen tickets zal tonen die de gespecificeerde pools gebruiken."
          },
          "description": "{poolAmount} pool kan worden gescand via dit scanprofiel. Scan de volgende QR-code om in te loggen. | {poolAmount} pools kunnen worden gescand via dit scanprofiel. Scan de volgende QR-code om in te loggen.",
          "mainProfile": {
            "title": "Algemeen",
            "description": "Dit is het algemene profiel waarmee alle pools kunnen worden gescand. Scan de volgende QR-code om in te loggen.",
            "warning": "Let op! Met deze QR-code kan alleen dit specifieke evenement worden gescand. Als je alle (toekomstige) evenementen wilt kunnen scannen {0}."
          }
        },
        "shop": {
          "deleteShopModalTitle": "Verwijder shop",
          "deleteShopMessage": "Weet je zeker dat je deze shop wilt verwijderen?",
          "deleteShopTooltip": "Als je de shop wilt verwijderen, verwijder je eerst alle tickets",
          "copyInfo": "Alle tickets en winkellay-out worden naar een nieuwe shop gekopieerd. Klik op Bevestig als je door wilt gaan.",
          "notfound": "Geen shop gevonden...",
          "title": "Tickets beheren",
          "description": "Voeg tickets toe aan je nieuwe evenement",
          "ticketModalTitle": "Ticket aanmaken",
          "vat": "BTW",
          "baseTicket": "Basis ticket",
          "deleteHelper": "Ticket kan alleen verwijderd worden als deze nog niet is verkocht.<br>Je kunt er altijd voor kiezen een ticket te verbergen.",
          "newTicket": "Nieuw ticket",
          "price": "Totaalprijs",
          "tax": "BTW (incl.)",
          "breakdown": "Prijsverdeling",
          "artwork": {
            "toggle": "Collectible Artwork",
            "error": "Uw bestand kan niet worden geüpload. Probeer het opnieuw.",
            "title": "Upload jouw Collectible afbeelding hier. Deze zal de standaard Collectible afbeelding ingesteld bij de blockchain event settings vervangen.<br><br>Je kan de afbeelding updaten op elk moment in de lifecycle van een event.",
            "template": "download template",
            "update": "Update art voor reeds geminte NFT's",
            "guidelines": {
              "title": "Algemene richtlijnen",
              "line_1": "PNG, GIF of JPG filetype",
              "line_2": "Portret modus met beeldverhouding van 4:3 ",
              "line_3": "10MB maximale bestandsgrootte",
              "line_4": "Je kunt <a href='{href}' download>onze template</a> gebruiken als basis"
            },
            "note": "Let op: Tenzij je een afbeelding upload, wordt standaard je event NFT artwork gebruikt."
          },
          "availabilitySettings": "Availability settings",
          "advanced": "Geavanceerde instellingen",
          "maxTicketsLabel": "Ticketlimiet",
          "putForSale": "Ticket te koop aanbieden",
          "from": "Beschikbaar vanaf",
          "till": "Beschikbaar tot",
          "defaultShopTooltip": "Use this shop by default when a user shares a link to this event",
          "nameInfo": "Alleen gebruikt in dashboard",
          "ticketLimitInfo": "Maximaal aantal tickets dat een bezoeker in deze shop kan kopen",
          "advancedEditPoolsLabel": "Stel het aantal privileges in die de klant ontvangt met dit ticket (max 20)",
          "maxAmount": "Maximaal aantal",
          "pages": {
            "deletePageModalTitle": "Verwijder pagina",
            "deletePageMessage": "Alle tickets op deze pagina worden naar de eerste pagina verplaatst. Weet je zeker dat je deze pagina wilt verwijderen?",
            "actions": {
              "moveToPage": "Ga naar pagina",
              "create": "Pagina aanmaken",
              "edit": "Pagina aanpassen"
            }
          },
          "access": {
            "title": "Links & embed codes voor shop - {name}",
            "linksTitle": "Deze shop is toegankelijk via de volgende gates & links:",
            "description": "Gates kunnen meerdere shops van verschillende evenementen toegankelijk maken, waaruit de bezoeker kan kiezen. Standaard heeft elke shop zijn eigen gate met link.",
            "draftAlert": "Dit evenement is nog niet gepubliceerd. De gate(s) zullen dit evenement niet tonen totdat deze is gepubliceerd.",
            "singleGate": "Enkele shop gate"
          },
          "availability": {
            "title": "Beschikbaarheid",
            "description": "Standaard zijn tickets beschikbaar <strong>{from}</strong> tot <strong>{ends}</strong>. Er kunnen in totaal maximaal <strong>{amount}</strong> tickets per account besteld worden.",
            "immediately": "per direct",
            "eventEnds": "het evenement eindigt",
            "relative": "{days} dagen voordat evenement eindigt, om {time}",
            "absoluteStart": " vanaf {date}",
            "startLabel": "Ticket-verkoop begint om",
            "endLabel": "Ticket-verkoop eindigt om"
          },
          "accessControl": {
            "title": "Toegang tot shop",
            "description": "Toegang tot de shop wordt verleend zodra een collectie is gevonden in de wallet van de klant. Selecteer collectie(s) die gebruikt zullen worden om toegang te verlenen tot de shop.",
            "linkLabel": "Link naar OpenSea",
            "urlPlaceholder": "opensea.io/...",
            "collectionsListLabel": "Geselecteerde collecties",
            "errors": {
              "collectionNotFound": "Deze collectie is niet gevonden.",
              "invalidUrl": "Dit is geen geldige OpenSea collectie URL.",
              "duplicateUrl": "Deze collectie is al toegevoegd.",
              "wrongChain": "Deze collectie bevindt zich op een niet-ondersteunde chain. Momenteel ondersteunen we alleen Ethereum Mainnet, Polygon Mainnet en Polygon Mumbai Testnet.",
              "openseaError": "Een fout is opgetreden bij het ophalen van de collectie. Probeer het later opnieuw.",
              "invalidCollection": "Deze collectie heeft geen NFT contract.",
              "noCollections": "No collections selected, please add at least one collection."
            }
          },
          "empty": {
            "action": "Maak je eerste ticket aan",
            "title": "Tickets aanmaken"
          },
          "actions": {
            "createShop": "Creëer shop",
            "copyShop": "Kopieer shop",
            "newShop": "Nieuwe shop",
            "editShop": "Shop aanpassen",
            "createGroup": "Creëer groep",
            "editGroup": "Groep aanpassen",
            "createNewPool": "Creëer pool",
            "addTicket": "Creëer ticket",
            "addRegular": "Ticket",
            "addCombi": "Combi-ticket",
            "addUpsell": "Upsell",
            "addGuestVariant": "Gastenlijst",
            "addGroup": "Creëer groep",
            "saveLayout": "Layout opslaan",
            "saveDraft": "Opslaan als concept",
            "addField": "Voeg regel toe",
            "saveTemplate": "Template opslaan"
          },
          "group": {
            "ticketPlaceholder": "Sleep een ticket hier om de groep te creëren",
            "collapseCheckbox": "Standaard ingeklapt",
            "messagePlaceholder": "Groepsbericht dat op de bestelpagina zal worden getoond...",
            "defaultTitle": "Nieuwe groep"
          },
          "blocksHelper": {
            "title": "Wil je het aantal blokkades aanpassen? {0}",
            "link": "Ga naar blokkade categorieën"
          },
          "gates": {
            "title": "Deze shop is toegankelijk via de volgende gates (links)",
            "description": "Elke gate kan meerdere shops voor verschillende evenementen bevatten waaruit de gebruiker kan kiezen. Elk shop heeft standaard zijn eigen gate.",
            "localGate": "Deze shop",
            "defaultGateError": "Elke shop moet een standaard gate hebben. Stel een standaard gate in door op een van de sterren te klikken.",
            "defaultTooltip": "Gebruik deze gate standaard wanneer er naar deze shop gelinkt wordt"
          }
        },
        "checkin": {
          "title": "Zelf inchecken",
          "description": "Scan de QR-code om de aanwezigen in te checken",
          "waiting": "Wachten op QR-code...",
          "AllClaimed": "Alle geclaimd",
          "checkin": "{amount} ticket(s) inchecken",
          "success": "{amount} ticket(s) succesvol ingecheckt!",
          "errors": {
            "noPrivileges": "Deze QR-code bevat geen tickets",
            "wrongEvent": "Deze QR-code hoort bij een ander event"
          }
        },
        "pools": {
          "title": "Pools",
          "addNew": "Creëer pool",
          "notFound": "Pool niet gevonden...",
          "poolSelection": "Selecteer een pool of meerdere pools om een ​​ticket aan te maken. Gebruik voor upsell-producten de upsell-pools. Als je de pool niet kan vinden, dan kun je",
          "createNewPool": "een nieuwe aanmaken.",
          "guestTicket": "Dit is een gastenticket",
          "toolTipEdititon": "Deze pool kan niet worden aangepast",
          "poolModal": {
            "ticketPrivilege": "ticket-privileges",
            "privilege": "privilege",
            "entrance": "Entree",
            "upsell-text": "Upsell",
            "create": "Privilege pool aanmaken",
            "description": "Een privilege pool wordt gebruikt om het totaal aantal {ticketPrivileges} (ofwel {privilege} in het kort) te bepalen dat kan worden uitgegeven. Een privilege kan het type {Entrance} of {Upsell} zijn. 1x capaciteit staat gelijk aan 1 privilege.",
            "update": "Pool aanpassen",
            "name": "Naam",
            "validFrom": "Geldig vanaf",
            "validTo": "Geldig tot",
            "blocks": "Blokkades (van totaal ingehouden)",
            "totalAmoutOfTickets": "Totaal aantal",
            "blockHelperText": "Je kunt later altijd nog het aantal en de categoriën van blokkades aanpassen.",
            "poolNameHelperText": "Bijvoorbeeld 'VIP\" of \"T-shirt XL\"",
            "totalCapacityHelperText": "Maximum capaciteit die uitgegeven kan worden",
            "upsell": " Gebruik deze pool als upsell. Upsell producten tellen nooit mee in de maximum ticket-limiet van een koper.",
            "deleteMessage": "Weet je zeker dat je deze pool wilt verwijderen?",
            "deleteTitle": "Pool verwijderen",
            "privilegeType": "Privilege type",
            "tellMeMore": "Meer info",
            "listItem1": "Te gebruiken om toegang te geven tot een evenement (of livestream)",
            "listItem2": "Het totaal aantal tickets dat een persoon kan krijgen van dit type kan worden gelimiteerd per shop ",
            "listItem3": "Te gebruiken voor additionele services/producten (zoals drankmunten/merchandise)",
            "listItem4": "Telt niet mee voor het maximum aantal gelimiteerde tickets per klant van een shop"
          },
          "details": {
            "sold": "Verkocht",
            "tickets": "Tickets",
            "shop": "Shop",
            "price": "Prijs"
          }
        }
      }
    },
    "reservations": {
      "addTickets": "Add tickets",
      "ticketsCount": "Ticket ({count}) | Tickets ({count})",
      "unclaimedTicketCount": "Unclaimed ({count})",
      "claimedTicketCount": "Claimed ({count})",
      "reservationsOverviewTitle": "Your reservations",
      "draftConfirmInfo": "Publish the event to enable confirming",
      "details": {
        "editTitle": "Edit reservation details"
      },
      "cancel": {
        "cancelTitle": "Cancel reservation",
        "cancelDescription": "Are you sure you want to cancel the reservation? This cannot be undone.",
        "yesCancelReservation": "Yes, cancel reservation",
        "blockTickets": "Block tickets that are available again",
        "blockTicketsDescription": "If you don't select this option, tickets will be up for sale again"
      },
      "confirm": {
        "title": "Confirm tickets",
        "descriptions": {
          "paidConfirmAll": "Are you sure you want to confirm the sale of all tickets in this reservation? Once confirmed, you'll be able to distribute the tickets. Please specify the payment method:",
          "freeConfirmAll": "Are you sure you want to confirm the sale of all tickets in this reservation? Once confirmed, you'll be able to distribute the tickets.",
          "paidConfirmPartial": "Are you sure you want to confirm the sale of the selected tickets in this reservation? Once confirmed, you'll be able to distribute the tickets. Please specify the payment method:",
          "freeConfirmPartial": "Are you sure you want to confirm the sale of the selected in this reservation? Once confirmed, you'll be able to distribute the tickets."
        }
      },
      "release": {
        "action": "Release",
        "title": "Release tickets",
        "description": "Are you sure you want to release the selected tickets from the reservation?"
      },
      "distributionModal": {
        "title": "Distribute tickets ({count})",
        "description": "Fill the email addresses of people to whom you want to distribute the selected tickets.",
        "allToOne": "All tickets to one person",
        "invitationEmail": "Invitation email address",
        "confirmText": "Send invitations"
      },
      "distribution": {
        "title": "Distribute Tickets",
        "emails": {
          "title": "Distribute tickets to the following email addresses",
          "info": "You can also enter multiple entries separated by a space, comma, or semi-colon.",
          "duplicates": {
            "title": "Duplicates",
            "message": "The following duplicate emails were not added to the list: <strong>{list}</strong>"
          },
          "errors": {
            "exceedsMax": "{count} out of {max} people added. Remove {remove_count} entries or reduce the number of tickets to distribute per person.",
            "validation": "One or more added email addresses are not valid, please correct them!"
          },
          "entriesLeft": "{count} entries left"
        },
        "tickets": {
          "title": "Each entry will receive the following tickets",
          "ticketLeft": "ticket left | tickets left"
        },
        "send": {
          "title": "Send invitations",
          "summary": {
            "title": "Summary",
            "description": "Each invitation contains the following ticket(s):"
          },
          "extraMessage": {
            "title": "Personal message",
            "description": "If you want you can add a short extra message to the ticket invitation email."
          },
          "sendAction": "Send invitations",
          "success": "Successfully created and sent out invitations!",
          "errors": {
            "create": "Failed to create invitations!",
            "send": "Failed to send invitations"
          }
        },
        "resend": {
          "title": "Resend invitation",
          "areYouSure": "Are you sure want to resend this invite?",
          "description": "We'll send another invitation to \"{email}\"",
          "error": "Failed to send invitation!",
          "success": "Succesfully sent out invitation!"
        },
        "revoke": {
          "title": "Revoke invitation",
          "areYouSure": "Are you sure want to revoke this invite?",
          "description": "After revoking, the tickets will be available again in the reservation to distribute.",
          "error": "Failed to revoke invitation!",
          "success": "Succesfully revoked invitation!"
        }
      }
    },
    "scanning": {
      "selectOrganisation": "Selecteer een organisatie om de aanmeldingsgegevens voor scannen te kunnen zien",
      "selectOrganisationMessage": "Selecteer een organisatie",
      "organisations": "Organisaties",
      "scannerInfo": "Gebruik de onderstaande informatie om in te loggen op de scanner. Houd er rekening mee dat deze inloggegevens worden gedeeld door de hele organisatie en niet specifiek zijn voor een evenement.",
      "downloadInfo": "Download de GET In app op je smartphone:",
      "passwordInfo": "Gebruik het wachtwoord of scan de QR-code om in te loggen",
      "noOrganisationSelected": "Je heeft nog geen organisatie geselecteerd."
    },
    "navigation": {
      "eventPreview": {
        "label": "Preview concept evenement",
        "title": "Preview van je evenement",
        "content": "Maak een testritje! Bekijk je evenement en test je ticketshop om ervoor te zorgen dat alles correct is ingesteld.<br><br>In deze preview krijg je precies dezelfde ervaring als de bezoekers van je event. Met uitzondering van de betaalstap, deze stap is geen onderdeel van de preview mode, je kunt het aankoopproces afronden zonder daadwerkelijk iets te kopen.<br><br>Het aankopen van tickets in preview modus heeft geen invloed op de werkelijke verkoop en aantallen."
      },
      "eventUrlInfo": "Je evenement zal na publiceren op deze url beschikbaar zijn",
      "back": "Naar alle evenementen"
    },
    "prices": {
      "title": "Jouw prijzen",
      "addPrice": "Creëer Prijstype",
      "editPrice": "Prijstype aanpassen",
      "editOverviewDescription": "De volgende evenementen en tickets hebben hetzelfde prijstype. Het wijzigen van dit prijstype heeft gevolgen voor al deze tickets. Klik op bevestigen als je door wilt gaan.",
      "table": {
        "name": "Naam prijstype",
        "amount": "Aantal",
        "usedBy": "Gebruikt door"
      },
      "priceSelect": {
        "noAvailable": "Er zijn geen globale prijstypes beschikbaar voor deze organisatie."
      }
    },
    "gates": {
      "title": "Jouw gates",
      "open": "Datum open",
      "close": "Datum dicht",
      "slug": "Slug",
      "create": "Creëer gate",
      "gate": {
        "description": "Deze gate is toegankelijk als de eerste shop open gaat <strong>{start}</strong> en wanneer het laatste event in deze gate eindigt <strong>{ends}</strong>.",
        "descriptionPrequeue": "Pre-registratie is <strong>ingeschakeld</strong>.",
        "descriptionAccess": "Toeganglinks zijn <strong>ingeschakeld</strong>.",
        "gateLinks": {
          "title": "Link & embed codes van gate",
          "linkInfo": "Gebruik altijd deze link bij het delen van de URL - Kopieer deze niet vanuit de browserbalk.",
          "link": "Directe link naar deze gate",
          "embed": "Embed code van deze gate"
        },
        "accessLinks": {
          "nav": "Toeganglinks",
          "title": "Genereer toeganglinks",
          "description": "Vul het gewenste aantal toegangslinks in en klik op Exporteer als csv. De onderstaande links zijn <strong> voorbeeldlinks </strong>.",
          "action": "Exporteer als csv"
        }
      },
      "gateSelect": {
        "noAvailable": "Er zijn geen gates beschikbaar voor deze organisatie. Je kunt een nieuwe aanmaken."
      },
      "shops": {
        "pastLabel": "Toon verlopen",
        "searchPlaceholder": "Zoek naar evenementen of shops...",
        "nonGateShopsTitle": "Alle evenementen & shops",
        "gateShopsTitle": "Shops in deze gate ({count})",
        "addToGate": "({count}) shop toevoegen | ({count}) shops toevoegen",
        "removeFromGate": "({count}) shop verwijderen | ({count}) shops verwijderen",
        "selectInfo": "Om meerdere shops te selecteren kun je shift of control (commando op Mac) ingedrukt houden en selecteren of klikken en slepen."
      },
      "modal": {
        "create": "Creëer gate",
        "edit": "Gate aanpassen",
        "optionalSlug": "Slug (optioneel)",
        "nameDescription": "Deze naam wordt alleen in het dashboard gebruikt",
        "delete": {
          "title": "Gate verwijderen",
          "description": "Weet je zeker dat je deze gate wilt verwijderen? Zo ja, klik dan op bevestigen."
        },
        "display": {
          "title": "Weergave",
          "description": "Selecteer hoe je de evenementen wilt weergeven:",
          "eventList": "Toon evenementen in lijst",
          "calendar": "Toon evenementen in kalender",
          "override": "Overschrijf evenementen informatie",
          "overrideDescription": "Standaard wordt deze informatie automatisch ingesteld op basis van de evenementen die aan deze gate zijn toegevoegd."
        },
        "access": {
          "title": "Toegang",
          "accessLinks": "Verreist toeganglinks",
          "accessLinksDescription": "Hiermee is deze gate alleen toegankelijk voor mensen met een toegangslink",
          "prequeue": "Schakel pre-registratie in",
          "prequeueDescription": "Hiermee kunnen gebruikers zich registreren in de wachtrij voordat de verkoop daadwerkelijk begint"
        }
      }
    },
    "templates": {
      "title": "Jouw templates",
      "createTemplate": "Creëer template",
      "selectTemplate": "Selecteer template",
      "noAvailable": "Geen template beschikbaar",
      "templateNotFound": "Template niet gevonden...",
      "status": {
        "syncing": "Synchroniseren...",
        "unsynced": "Niet gesynchroniseerd",
        "synced": "Alles gesynchroniseerd"
      },
      "sync": {
        "title": "Synchroniseer template met aankomende evenementen",
        "description": "Je hebt wijzigingen aangebracht in deze template. Om alle evenementen van deze template bij te werken kun je je wijzigingen hier synchroniseren. Houd er rekening mee dat <b> alle aangebrachte wijzigingen </b> worden toegepast op <b> alle aankomende evenementen </b> die met deze template zijn gemaakt.",
        "action": "Synchroniseer wijzigingen",
        "syncNow": "Synchroniseer nu",
        "changesApplied": "Jouw wijzigingen worden toegepast op {0} aankomende evenementen",
        "changesApplying": "Alle {0} aankomende evenementen worden gesynchroniseerd!",
        "eventsSyncing": "Synchronisatie van evenementen...",
        "SyncStarted": "Synchronisatie gestart op {date}",
        "lastSync": "Laatst gesynchroniseerd op {date}",
        "errors": {
          "nothingToSync": "Deze template is al gesynchroniseerd",
          "beingSynced": "Deze template wordt al gesynchroniseerd"
        },
        "warningModal": {
          "description": "Alle wijzigingen in deze template worden gesynchroniseerd met alle aankomende evenementen die door deze template worden beheerd. Er zijn een paar regels die kunnen worden toegepast om eventuele fouten te voorkomen:",
          "lowerCapacity": "Als je de capaciteit van een pool hebt verlaagd maar er zijn evenementen die meer tickets hebben verkocht dan de verlaagde capaciteit, dan wordt de capaciteit voor deze evenementen ingesteld op wat er momenteel is verkocht. Voorbeeld: capaciteit is gewijzigd van 100 naar 50, een evenement heeft al 60 tickets verkocht, dan wordt de capaciteit voor dat evenement ingesteld op 60.",
          "removeTickets": "Als je tickets hebt verwijderd die al zijn verkocht voor specifieke evenementen dan worden die tickets niet verwijderd maar in plaats daarvan verborgen (in shops).",
          "removeShop": "Als je een shop hebt verwijderd maar voor bepaalde evenementen hebben nog tickets in die specifieke shop, dan wordt die shop niet verwijderd maar in plaats daarvan gesloten."
        }
      },
      "templateAlert": "Let op: Als je wijzigingen maakt, worden ook alle evenementen die gebruik maken van deze template bijgewerkt.",
      "createFromTemplate": {
        "title": "Maak evenement van template",
        "createInfo": "Alle template instellingen worden naar dit evenement gekopieerd.",
        "templateInfo": {
          "text": "Je kunt ook {link} een nieuwe template aanmaken",
          "link": "hier"
        },
        "timeInfo": "Lokale tijd: {timezone}, {time}. De tijd van het evenement is ingesteld in de template.",
        "errors": {
          "templateBeingSynced": "Je kunt geen evenement maken op basis van deze template omdat deze momenteel wordt gesynchroniseerd.",
          "templateNotSynced": "Je kunt geen evenement maken op basis van deze template omdat deze niet is gesynchroniseerd."
        }
      }
    },
    "schedules": {
      "title": "Jouw schedules",
      "create": {
        "title": "Creëer schedule"
      },
      "schedulePatterns": {
        "description": "De volgende templates worden gebruikt om meerdere evenementen te genereren voor de periode die je opgeeft.",
        "action": "Voeg template toe",
        "days": "Dagen",
        "exceptions": "Uitzonderingen",
        "modal": {
          "addTemplate": "Selecteer de template om aan de planner toe te voegen",
          "edit": "Schedule aanpassen",
          "pattern": "Op welke dagen van de week vinden de evenementen plaats?",
          "exception": "Uitzonderingsdata (optioneel)",
          "addException": "Uitzondering toevoegen"
        }
      },
      "entries": {
        "startDate": "Startdatum",
        "endDate": "Einddatum",
        "description": "Genereer nieuwe evenementen op basis van de templates door de eerste en de laatste dag op te geven. Uitzonderingsdata worden overgeslagen.",
        "generate": "Genereer evenementen",
        "syncWarning": "Evenementen kunnen niet gegenereerd worden omdat één of meerdere templates niet gesynchroniseerd zijn! Synchroniseer eerst alle templates voordat je nieuwe evenementen genereert.",
        "newItemStatus": "<strong>{patternName}</strong> evenementen zullen worden gegenereerd",
        "confirmDescription": "De volgende evenementen zullen worden gegenereerd en gepubliceerd:",
        "confirmContinue": "Klik op bevestigen als je verder wilt gaan.",
        "rangeAlert": "Je kunt maximaal 1 jaar vooruit evenementen genereren. De einddatum moet vóór {maxDate} zijn..",
        "countAlert": "Je staat op het punt om {count} nieuwe evenementen tegelijk te maken. Weet je het zeker?",
        "tooltip": {
          "existing": "Bestaande",
          "generate": "Te genereren"
        }
      }
    },
    "cashier": {
      "inDraft": "This event is not published yet",
      "inDraftInfo": "You can only create reservations in draft events. Issuing tickets directly through the cashier or confirming/distributing reserved tickets can only be done once the event is published. If you want to get full access to the features, {0}.",
      "inDraftInfoCallToAction": "publish this event",
      "filters": {
        "available": "Beschikbaar",
        "blocks": "Blokkades",
        "fromBlocks": "Van blokkade",
        "price": "Prijs",
        "type": "Type",
        "ticket": "Ticket",
        "upsell": "Upsell",
        "shop": "Shop",
        "pool": "Pool",
        "lockedShop": "Je kunt alleen tickets kiezen uit de {shopName} winkel. Om een andere shop te selecteren moet je eerst de winkelwagen legen.",
        "lockedShopReservation": "You can only select tickets from {shopName} shop. to select a different shop you will have to create additional reservation."
      },
      "abortAction": {
        "title": "Afbreken",
        "description": "Weet je zeker dat je deze actie wilt afbreken.",
        "confirm": "Ja, breek actie af"
      },
      "removeTickets": {
        "title": "Verwijder {ticketName} tickets",
        "description": "Hoeveel {ticketName} tickets wil je verwijderen?",
        "max": "max"
      },
      "removeSeatedTickets": "Which tickets do you want to remove?",
      "removeAll": {
        "title": "Verwijder alles",
        "description": "Wil je alle tickets uit de winkelmand verwijderen?",
        "confirm": "Verwijder alles"
      },
      "removeOne": {
        "title": "Verwijder ticket",
        "description": "Weet je zeker dat je dit ticket wilt verwijderen?",
        "confirm": "Verwijder ticket"
      },
      "confirmationModal": {
        "titleOrder": "Creëer bestelling",
        "titleReservation": "Reservering aanmaken",
        "description": {
          "detailsOnly": "De tickets worden gekoppeld aan het opgegeven telefoonnummer en zijn voor de klant toegankelijk via zijn ticket wallet. De klant ontvangt geen bevestiging.",
          "detailsAndSMS": "Tickets worden gekoppeld aan het opgegeven telefoonnummer en zijn toegankelijk voor de klant via zijn ticket wallet. De klant ontvangt een bevestiging van de aankoop via SMS.",
          "detailsAndEmail": "Tickets worden gekoppeld aan het opgegeven telefoonnummer en zijn toegankelijk voor de klant via zijn ticket wallet. De klant ontvangt een e-mailbevestiging met informatie over het evenement en de tickets.",
          "detailsSMSAndEmail": "De tickets worden gekoppeld aan het opgegeven telefoonnummer en zijn voor de klant toegankelijk via zijn ticket wallet. De klant ontvangt een bevestiging van de aankoop via SMS en een e-mailbevestiging met de evenement- en ticketinformatie.",
          "onlyEmail": "De tickets zijn niet gekoppeld aan het account van de klant omdat er geen telefoonnummer is opgegeven. Als u de klant de tickets wilt geven, moet u de bestelling afdrukken. De klant zal geen bevestiging ontvangen.",
          "onlySMS": "De tickets worden gekoppeld aan het opgegeven telefoonnummer en zijn voor de klant toegankelijk via zijn ticket wallet. De klant ontvangt een bevestiging van de aankoop via SMS.",
          "onlyNumber": "De tickets worden gekoppeld aan het opgegeven telefoonnummer en zijn voor de klant toegankelijk via zijn ticket wallet. De klant ontvangt geen bevestiging."
        },
        "notifySMS": "Gemeld via SMS",
        "notifyEmail": "Gemeld via e-mail",
        "reservation": {
          "name": "Naam reservering",
          "note": "Notitie",
          "expiry": "Vervaldatum"
        }
      },
      "complete": "Voltooi bestelling"
    }
  },
  "errors": {
    "not-found": {
      "title": "Geen toegang tot deze pagina",
      "description": "Je hebt geen toestemming om deze pagina te bekijken of deze bestaat simpelweg niet.",
      "contactSupport": "Als je verwacht dat dit een fout is, neem dan contact op met de organisatie waartoe je toegang probeert te krijgen, of neem {0} voor hulp.",
      "link": "contact op met support"
    }
  },
  "filter": {
    "filterBy": "Filter op '{name}'",
    "operators": {
      "e": "Gelijk aan",
      "lte": "Minder dan of gelijk aan",
      "gte": "Groter dan of gelijk aan",
      "lt": "Minder dan",
      "gt": "Meer dan"
    },
    "timeOperators": {
      "last": "Is in de laatste",
      "equal": "Is gelijk aan",
      "between": "Is tussen",
      "after": "Is na",
      "onafter": "Is op of na",
      "before": "Is voor",
      "onbefore": "Is voor of op"
    },
    "times": {
      "days": "Dagen",
      "weeks": "Weken",
      "months": "Maanden",
      "years": "Jaren"
    },
    "aria": {
      "open": "Filter menu openen",
      "close": "Filter menu sluiten",
      "remove": "Verwijder filter",
      "add": "Voeg {name} filter toe",
      "openAdd": "Open filter toevoegen menu",
      "clearAll": "Verwijder alle filters"
    },
    "addFilter": "Filter toevoegen",
    "clearAll": "Alles verwijderen",
    "select": "Selecteren",
    "selectAll": "Selecteer alle",
    "between": "En"
  },
  "resaleFeeConfig": {
    "sellerTitle": "Kosten voor de verkoper",
    "buyerTitle": "Kosten voor de koper",
    "fixedTitle": "Vaste kosten",
    "fixedLabel": "Vaste kosten per ticket",
    "percTitle": "Procentuele kosten",
    "percLabel": "Procentuele kosten per ticket",
    "totalLabel": "Totale kosten",
    "getFeeLabel": "Basis - GET International",
    "partnerFeeLabel": "Ticketeer kosten",
    "override": {
      "label": "Ticketeer kosten overschrijven",
      "confirm": {
        "title": "Overschrijf basis Ticketeer kosten",
        "description": "Weet u zeker dat u de tarieven voor deze organisatie wilt wijzigen? Eventuele wijzigingen in de tariefplannen voor de ticket markt gelden alleen voor nieuw gepubliceerde evenementen. Evenementen die momenteel in de verkoop zijn, behouden de huidige tariefconfiguratie."
      }
    }
  }
}
