const Cashier = () => import('@/views/cashier/index.vue');
const CashierTicketSelect = () => import('@/views/cashier/CashierTicketSelect.vue');
const CashierIssueTickets = () => import('@/views/cashier/CashierIssueTickets.vue');
const ReservationCreate = () => import('@/views/cashier/ReservationCreate.vue');

export default {
  path: 'cashier',
  name: 'cashier',
  redirect: 'cashier/select',
  component: Cashier,
  children: [
    {
      path: 'select',
      name: 'cashier-select',
      component: CashierTicketSelect,
      meta: {
        layout: 'Full',
        auth: true,
        org: true,
        permissions: {
          access: ({ state, getters }, _) => {
            const { current_event } = state.Events;
            return (
              current_event &&
              !current_event.is_template &&
              getters['Events/getPermission']('attendee.read') &&
              getters['Events/getPermission']('ticket.cashier.create')
            );
          },
          redirect: (_, to) => ({ name: 'notFound', params: { 0: to.fullPath } }),
        },
      },
    },
    {
      path: 'issue',
      name: 'cashier-issue',
      component: CashierIssueTickets,
      meta: {
        auth: true,
        org: true,
        layout: 'Full',
        permissions: {
          access: ({ state, getters }, _) => {
            const { current_event } = state.Events;
            return (
              current_event &&
              !current_event.is_template &&
              getters['Events/getPermission']('attendee.read') &&
              getters['Events/getPermission']('ticket.cashier.create')
            );
          },
          redirect: (_, to) => ({ name: 'notFound', params: { 0: to.fullPath } }),
        },
      },
    },
    {
      path: 'reservation',
      name: 'reservation-create',
      component: ReservationCreate,
      meta: {
        auth: true,
        org: true,
        layout: 'Full',
        permissions: {
          access: ({ state, getters }, _) => {
            const { current_event } = state.Events;
            return (
              current_event &&
              !current_event.is_template &&
              getters['Events/getPermission']('reservation.write') &&
              getters['Events/getPermission']('attendee.read') &&
              getters['Events/getPermission']('ticket.cashier.create') &&
              getters['Events/getPermission']('feature.flag')
            );
          },
          redirect: (_, to) => ({ name: 'notFound', params: { 0: to.fullPath } }),
        },
      },
    },
  ],
};
