import actions from './actions';
import mutations from './mutations';
import getters from './getters';

const state = {
  seating: null,
  isLoading: false,
  mode: 'default',
  unblockMode: false,
  draftSeating: {
    pTypes: {},
    blocked: [],
    unblocked: [],
    categorised: [],
    pendingAssignments: [],
    category: {},
  },
};

export default (app) => ({
  namespaced: true,
  state,
  actions: actions(app),
  getters,
  mutations,
});
